#footer{
  width: 100%;
  padding: 4rem 2.4rem;
  border-top: 0.05rem solid rgba(0, 0, 0, 0.2);
  background: #fff;
}

.footer-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}

.footer-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
}

.footer-menu .open-btn {
  font-size: 1.6rem;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.6);
}

.footer-menu .open-btn {
  text-decoration: underline;
}

.footer-menu .open-btn:hover {
  color: var(--wh-main-color);
}

.footer-menu > li > a {
  font-size: 1.6rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
}

.footer-menu > li > button:hover,
.footer-menu > li > a:hover {
  color: var(--wh-main-color);
}

.footer-menu .popup {
  z-index: 3;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.footer-menu .popup.open {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-menu .popup-inner {
  width: 90%;
  height: 80%;
  margin: 4rem auto;
  border-radius: 0.8rem;
  background: #fff;
}

.footer-menu .popup h3 {
  padding: 0 2rem;
  line-height: 6rem;
  box-sizing: border-box;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
  font-size: 2rem;
  font-weight: 600;
}

.footer-menu .popup .info {
  overflow-y: scroll;
  height: calc(100% - 16rem);
  padding: 2rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.footer-menu .popup .info > p {
  margin-bottom: 1rem;
  text-align: justify;
  line-height: 150%;
  font-size: 1.2rem;
}

.footer-menu .popup .info div {
  overflow-y: scroll;
  height: 20rem;
  padding: 2rem;
  border-radius: 0.8rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.footer-menu .popup li {
  margin-bottom: 1.2rem;
  line-height: 150%;
  font-size: 1.2rem;
}

.footer-menu .popup .tit {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.footer-menu .popup p {
  margin-bottom: 1rem;
  line-height: 150%;
  font-size: 1.2rem;
}

.footer-menu .close-btn {
  display: flex;
  align-items: center;
  width: 100%;
  height: 10rem;
}

.footer-menu .close-btn button {
  width: 16rem;
  height: 5rem;
  margin: 0 auto;
  border-radius: 5rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
  background: var(--wh-main-color);
}

.footer-menu > p {
  font-size: 1.2rem;
  font-family: var(--en);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.footer-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-info > p {
  margin-bottom: 2rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
}

.footer-info ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 0.6rem;
  row-gap: 0.6rem;
}

.footer-info li {
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.4rem;
  font-family: var(--kr);
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.footer-info li label,
.footer-info li span {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 600;
  word-break: keep-all;
  color:  rgba(0, 0, 0, 0.5);
}

.footer-info > p:last-child {
  font-size: 1.4rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.footer-logo {
  width: fit-content;
}

.footer-logo img {
  display: block;
  height: 4rem;
  filter: brightness(0);
  opacity: 0.2;
}

@media all and (min-width:48em) {
  #footer {
    padding: 6rem 2.4rem;
  }

  .footer-info ul {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0;
  }

  .footer-menu .popup h3 {
    font-size: 2.4rem;
  }

  .footer-menu .popup .info > p {
    word-break: keep-all;
    font-size: 1.8rem;
  }

  .footer-menu .popup .info div {
    height: 60%;
  }

  .footer-menu .popup .tit {
    font-size: 1.8rem;
  }

  .footer-menu .popup p {
    font-size: 1.6rem;
  }

  .footer-menu .popup li {
    font-size: 1.6rem;
  }
}

@media all and (min-width:64em) {
  .footer-wrap {
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
  }

  .footer-wrap > * {
    flex: 1;
  }

  .footer-info {
    justify-content: space-between;
    align-items: flex-start;
  }

  .footer-info ul {
    justify-content: flex-start;
    margin: 0;
  }

  .footer-info li:nth-child(1),
  .footer-info li:nth-child(3) {
    width: 45%;
  }

  .footer-info li:nth-child(4)~li {
    width: 100%;
  }

  .footer-info li:not(:nth-child(2),:nth-child(4)) label {
    width: 7rem;
  }
/*   
  .footer-info li:nth-child(2) label,
  .footer-info li:nth-child(4) label {
    width: 10rem;
  } */
  
  /* .footer-info li:nth-child(5) label {
    letter-spacing: 0.3rem;
  }
  
  .footer-info li:nth-child(4) label,
  .footer-info li:nth-child(5)~li label {
    letter-spacing: 0.8rem;
  } */

  .footer-menu {
    justify-content: space-between;
    align-items: flex-end;
  }

  .footer-menu .privacy {
    margin-top: 8rem;
  }

  .footer-menu .popup .info div {
    height: 72%;
    margin-top: 4rem;
  }

  .footer-menu .popup h3 {
    padding: 0 4rem;
    line-height: 10rem;
  }

  .footer-menu .popup .info {
    height: calc(100% - 20rem);
    padding: 4rem;
  }

  .footer-menu > p {
    text-align: right;
  }
}

@media all and (min-width:80em) {
  .footer-wrap {
    max-width: 76.8%;
  }

  .footer-menu .popup-inner {
    max-width: 80rem;
    height: 60rem;
  }
}

@media all and (min-width:96em) {
  #footer {
    padding: 10rem 0;
  }
}

@media all and (min-width:120em) {
  .footer-info > p {
    font-size: 2rem;
  }

  .footer-info li,
  .footer-info li label {
    font-size: 1.6rem;
  }

  .footer-info li:not(:nth-child(2),:nth-child(4)) label {
    width: 8rem;
  }
  
  /* .footer-info li:nth-child(2) label,
  .footer-info li:nth-child(4) label {
    width: 12rem;
  } */

  /* .footer-info li:nth-child(5) label {
    letter-spacing: 0.35rem;
  }
  
  .footer-info li:nth-child(4) label,
  .footer-info li:nth-child(5)~li label {
    letter-spacing: 0.95rem;
  } */

  .footer-menu .open-btn {
    font-size: 1.8rem;
  }

  .footer-menu > p {
    font-size: 1.6rem;
  }
}

@media all and (min-width:160em) {
}