* {
  color: #212121;
}

button, a {
  cursor: pointer;
}
body > div {
  width: 100vw;
  overflow: hidden;
}

section:not(:first-child) {
  margin: 16rem 0;
}

.main-inner {
	padding: 0 1.6rem;
}

/*------------ font-size ------------*/
.mc-tit {
	line-height: 1.2;
	font-size: 4rem;
	font-family: var(--kr);
	font-weight: 700;
	color: #222;
}

.ms-tit {
	line-height: 1.4;
	font-size: 4rem;
	font-family: var(--kr);
	font-weight: 500;
	color: rgba(0, 0, 0, 0.8);
}

.mc-txt {
  word-break: keep-all;
	line-height: 1.45;
	font-size: 2.2rem;
	font-family: var(--kr);
	font-weight: 400;
	color: rgba(0, 0, 0, 0.8);
}


/*------------ 버튼 공통 스타일 ------------*/
a[class^="btn-"],
button[class^="btn-"] {
  position : relative;
  display : inline-block;
  width : 100%;
  padding : 2.1rem 2rem;
  text-align : center;
  font-size : 1.8rem;
  font-weight : 500;
  background : transparent;
}

a[class^="btn-"]::after,
button[class^="btn-"]::after {
  position : absolute;
  right : 2rem;
  top : 50%;
  content : "";
  width : 0.8rem;
  height : 1.2rem;
  transform : translateY(-50%);
  background : url(../../public/images/main/arrow_right_bk.svg)no-repeat left top / 100% 100%;
}


/* ==============================1-1.메인 비주얼============================ */
.main-visual {
  width: 100%;
  height: 100vh;
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.2);
}

.main-visual .swiper {
  width: 100vw;
  height: 100vh;
}

.main-visual .swiper-wrapper, .main-visual .swiper-slide {
  height: 100vh;
  padding: 0;
}

.main-visual video {
  display: none;
  width: 100%;
  height: 100%;
}

.mv_slide {
  height: 100%;
}

.mv_slide.bg1 {
  background: url(../../public/images/main/mo_mv_s1.jpg)no-repeat right -25rem top / cover;

}

.mv_slide.bg2 {
  background: url(../../public/images/main/mv_s2.jpg)no-repeat center / cover;
}

.mv_slide.bg3 {
  background: url(../../public/images/main/mv_s3.jpg)no-repeat center / cover;
}

.main-visual .grey-sheet {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.main-visual .slide-txt {
  position: absolute;
  top: 32%;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 1.6rem;
  box-sizing: border-box;
}

.main-visual .slide-txt h2 span {
  display: inline-block;
  overflow: hidden;
  font-size: 4.8rem;
  font-family: var(--en);
  font-weight: 700;
  color: #eeeff1;
  white-space: pre-wrap;
}

.main-visual .slide-txt h2 span span {
  display: inline-block;
  transform: translateY(10rem);
}

.main-visual .slide-txt.on h2 span span {
  transform: translateY(0);
  transition: all 1s ease;
}

.main-visual .slide-txt p {
  margin-top: 2rem;
  line-height: 150%;
  word-break: keep-all;
  font-size: 1.4rem;
  font-weight: 300;
  color: #eeeff1;
}

.main-visual .slide-txt p br {
  display: none;
}

.main-visual div[class^="swiper-button"] {
  z-index: 20;
  top: initial;
  bottom: 3.6rem;
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  border: 0.1rem solid rgba(255, 255, 255, 0.8);
}

.main-visual div[class^="swiper-button"]:hover,
.main-visual div[class^="swiper-button"]:active {
  background: #fff;
}

.main-visual div[class^="swiper-button"]::after {
  content: "";
  width: 100%;
  height: 100%;
}

.main-visual div[class^="swiper-button"]:hover::after,
.main-visual div[class^="swiper-button"]:active::after {
  filter: brightness(0);
}

.main-visual div.swiper-button-prev {
  top: var(--swiper-navigation-top-offset, 75%);
  margin-top: calc(var(--swiper-navigation-size) / 2);
}

.main-visual div.swiper-button-prev,
.main-visual div.swiper-button-next {
  left: initial;
  right: 2.4rem;
}

.main-visual div.swiper-button-next::after {
  background: url(../../public/images/main/prev.svg)no-repeat center;
}

.main-visual div.swiper-button-prev::after {
  background: url(../../public/images/main/next.svg)no-repeat center;
}

.main-visual div.swiper-pagination {
  top: var(--swiper-navigation-top-offset, 64%);
  
  bottom: initial;
  padding: 0 1.6rem;
  box-sizing: border-box;
  text-align: left;
}

.main-visual .swiper-pagination-bullet {
  width: 1rem;
  height: 1rem;
  background: #fff;
}

.main-visual span.swiper-pagination-bullet:hover {
  border: 0.1rem solid #fff; 
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0;
}

.main-visual .swiper-pagination-bullet:not(:last-child) {
  margin-right: 1.6rem;
}

.main-visual .swiper-pagination-bullet-active {
  background: #fff;
}


.play-control {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 62.8%);
  left: 8.8rem;
}

.play-control .play-stop-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 2.5rem;
  border: 0.1rem solid rgba(255, 255, 255, 0.8);
}

.row {
  padding: 0 1.6rem;
  box-sizing: border-box;
}

.scroll-down {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: fit-content;
}

.scroll-down .label {
  position: relative;
  left: 0.8rem;
  display: block;
  padding-bottom: calc(60px + 1rem);
  color: #eeeff1;
}

.scroll-down .label span:first-child {
  display: inline-block;
  letter-spacing: 0.1rem;
  font-size: 1rem;
  font-family: var(--en);
  color: #eeeff1;
  transform: rotate(-90deg) translate(3rem, -3.4rem);
}

.scroll-down .label .line {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 0.2rem;
  height: 6rem;
}

.scroll-down .label .line::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: '';
  width: 0.1rem;
  height: 100%;
  background: #fff;
  opacity: 0.3;
}

.scroll-down .label .line::after {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  width: 0.2rem;
  background: #fff;
  animation: scroll-down-line 3s infinite;
}

@keyframes scroll-down-line {
  0%, 20% {
      height: 45%;
      top: -45%;
  }
  80%, 100% {
      height: 45%;
      top: 145%;
  }
}



/* ==============================1-2.회사소개============================ */
section.intro {
  padding-bottom: 8rem;
  background: url(../../public/images/main/mc_bg1.png)no-repeat center bottom / 100%;
}
.intro .box-list {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  width: 100%;
}

.intro .box-list > div {
  position: relative;
  flex: 1;
}

.intro .box-list {
  height: auto;
}

.intro .box .bg {
  overflow: hidden;
}

.intro .box .bg img {
  width: 100%;
}

.intro .box-list .cover {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 1rem;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.6);
}

.intro .box:nth-of-type(1) {
  margin-bottom: 4rem;
}

.intro .box:nth-of-type(1) .title {
  margin-bottom: 4rem;
}

.intro .cover p {
  line-height: 1.2;
  font-size: 2rem;
  font-family: var(--en);
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}

.intro .box .mc-tit {
  margin-bottom: 2rem;
}

.intro .box:nth-of-type(1) .mc-tit{
  font-size: 4rem;
}

.intro .box:nth-of-type(2) .title {
  margin-top: 2rem;
}

.intro .box:nth-of-type(2) .mc-tit span {
  color: var(--wh-main-color);
}

.intro .box:nth-of-type(2) .mc-tit,
.intro .box:nth-of-type(2) .mc-tit span {
  line-height: 1.45;
  font-size: 3.2rem;
  font-weight: 700;
  letter-spacing: -0.3rem;
}

.intro .box .mc-txt {
  margin-bottom: 4rem;
  font-size: 1.5rem;
  font-weight: 300;
  word-break: keep-all;
}

.intro .box:nth-of-type(2) .mc-txt br {
  display: none;
}

div[class^="btn-fl"] a {
  display: block;
  background: url(../../public/images/main/arrow_right_wh.svg)no-repeat right top 0.2rem / 20%;
}

.intro .box div[class^="btn-fl"] a {
  width: 8rem;
  padding-bottom: 1.6rem;
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.4);
  font-size: 1.2rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #fff;
} 

div[class^="btn-bl"] {
  width: fit-content;
}

div[class^="btn-bl"] a {
  display: block;
  width: 16rem;
  padding-bottom: 1.6rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
  font-size: 1.8rem;
  font-family: var(--kr);
  font-weight: 500;
  color: #212121;
  background: url(../../public/images/main/arrow_right_bk.svg)no-repeat right top 0.5rem;
  opacity: 0.6;
}

div[class^="btn-bl"] a:hover,
div[class^="btn-bl"] a:active {
  opacity: 1;
  transition: all 0.3s;
}

div[class^="btn-bl"]::after {
  content: "";
  position: relative;
  display: block;
  width: 0;
  height: 0.2rem;
  margin-top: -0.2rem;
}

div[class^="btn-bl"]:hover:after,
div[class^="btn-bl"]:active:after {
  width: 100%;
  height: 0.2rem;
  background: var(--wh-main-color);
  transition: all 0.3s;
}


/* ==============================1-3.비즈니스============================ */

.business .main-inner {
  position: relative;
  display: flex;
  flex-direction: column;
}

.business .mc-txt {
  margin: 2rem 0 4rem;
  font-size: 1.6rem;
}

.business-list {
  overflow: hidden;
  margin-top: 6rem;
}

.business .slider-container {
  width: 500%;
}

.business-list .slide-wrapper {
  display: flex;
}

.business-list .slide-item {
  width: calc(100% - 3.2rem);
  scale: 0.9;
}

.business-list .slide-item.active {
  scale: 1;
}

.business-list .slide-item a {
  display: block;
  height: 100%;
  cursor: pointer;
}

.business-list .slide-item a div {
  transform: translateY(2rem);
  transition: all 0.3s ease;
  opacity: 0;
}

.business-list .slide-item.active a div {
  transform: translateY(0);
  transition: all 0.3s ease;
  opacity: 1;
}

.business .slide {
  height: 26rem;
  padding: 2rem;
  box-sizing: border-box;
}

.business .slide-item:nth-child(1) .slide {
  background:
  linear-gradient(to right, rgba(0, 0, 0, 0.8),  rgba(0, 0, 0, 0.8)),
  url(../../public/images/main/business1.jpg)no-repeat center / cover;
}

.business .slide-item:nth-child(2) .slide {
  background:
  linear-gradient(to right, rgba(0, 0, 0, 0.8),  rgba(0, 0, 0, 0.8)),
  url(../../public/images/main/business2.jpg)no-repeat center / cover;
}

.business .slide-item:nth-child(3) .slide {
  background:
  linear-gradient(to right, rgba(0, 0, 0, 0.8),  rgba(0, 0, 0, 0.8)),
  url(../../public/images/main/business3.jpg)no-repeat center / cover;
}

.business .slide-item:nth-child(4) .slide {
  background:
  linear-gradient(to right, rgba(0, 0, 0, 0.8),  rgba(0, 0, 0, 0.8)),
  url(../../public/images/main/business4.jpg)no-repeat center / cover;
}

.business .slide-item:nth-child(5) .slide {
  background:
  linear-gradient(to right, rgba(0, 0, 0, 0.8),  rgba(0, 0, 0, 0.8)),
  url(../../public/images/main/business5.jpg)no-repeat center / cover;
}

.business .slide-item:nth-child(1).active .slide {
  background:
  linear-gradient(to right, rgba(0, 0, 0, 0.4),  rgba(0, 0, 0, 0.4)),
  url(../../public/images/main/business1.jpg)no-repeat center / cover;
}

.business .slide-item:nth-child(2).active .slide {
  background:
  linear-gradient(to right, rgba(0, 0, 0, 0.4),  rgba(0, 0, 0, 0.4)),
  url(../../public/images/main/business2.jpg)no-repeat center / cover;
}

.business .slide-item:nth-child(3).active .slide {
  background:
  linear-gradient(to right, rgba(0, 0, 0, 0.4),  rgba(0, 0, 0, 0.4)),
  url(../../public/images/main/business3.jpg)no-repeat center / cover;
}

.business .slide-item:nth-child(4).active .slide {
  background:
  linear-gradient(to right, rgba(0, 0, 0, 0.4),  rgba(0, 0, 0, 0.4)),
  url(../../public/images/main/business4.jpg)no-repeat center / cover;
}

.business .slide-item:nth-child(5).active .slide {
  background:
  linear-gradient(to right, rgba(0, 0, 0, 0.4),  rgba(0, 0, 0, 0.4)),
  url(../../public/images/main/business5.jpg)no-repeat center / cover;
}

.business .slide p {
  margin-bottom: 1rem;
  text-align: right;
  font-size: 1.6rem;
  font-family: var(--en);
  font-weight: 300;
  color: rgba(255, 255, 255, 0.6);
}

.business .slide strong {
  font-size: 2.4rem;
  font-family: var(--kr);
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
}

.business .control {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.business .pagination {
  width:  80%;
  padding: 0 1rem;
}

.business .slide-count {
  display: flex;
  justify-content: center;
  align-items: center;
}

.business .slide-count span {
  font-size: 2rem;
  font-family: var(--en);
  font-weight: 500;
  color: #212121;
}

.business .progressbar {
  width: 15rem;
  height: 0.2rem;
  margin: 0 1rem;
  background: rgba(0, 0, 0, 0.2);
}

.business .progressbar-fill {
  height: 100%;
  background: #222;
  transition: all 0.3s ease;
}


/* ==============================1-4.제품============================ */

.product .title {
  margin-bottom: 4rem;
}

.product .swiper-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.product .swiper-wrapper::-webkit-scrollbar {
  display: none;
}

.product .swiper-slide {
  width: auto !important;
  position: relative;
}

.product .grid {
  display: flex;
  width: fit-content;
}

.product .col {
  position: relative;
  display: block;
  isolation: isolate;
  width: 24rem;
  height: 40rem;
  max-height: 68rem;
  box-sizing: border-box;
  padding: 0.2rem;
}

.product .product-item {
  position: relative;
  height: 100%;
}

.product .item-inner {
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  padding: 0.4rem;
}

.product .product-bg {
  position: relative;
  height: 100%;
  border-radius: 1rem;
  background: #dfdfdf;
}

.product .product-bg img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.8rem;
  filter: brightness(0.6);
}

.product .product-title {
  position: absolute;
  left: 0; 
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
  box-sizing: border-box;
  transform: translateY(5.6rem);
}

.product .product-item:hover .product-title {
  transform: translateY(0);
  transition: 1s ease;
}

.product .product-title h3 {
  margin-bottom: 1rem;
}

.product .product-title strong {
  font-size: 2.4rem;
  font-family: var(--en);
  font-weight: 600;
  color: #eeeff1;
}

.product .product-title p {
  display: none;
  width: 80%;
  margin-bottom: 2rem;
  line-height: 150%;
  font-size: 1.2rem;
  font-weight: 400;
  color: #eeeff1;
  opacity: 0;
}

.product .product-item:hover  .product-title p {
  display: block;
  opacity: 1;
  transition: 1s ease;
}

.product .product-title .btn-more {
  width: 50%;
  height: 4rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #eeeff1;
  opacity: 0;
}

.product .product-item:hover .btn-more {
  opacity: 1;
  transition: 1s ease;
}

.product .product-item .btn-more:hover {
  background: #fff;
}

.product .product-item .btn-more:hover a {
  color: #222;
}

.product .product-title .btn-more a {
  display: block;
  width: 100%;
  line-height: 4rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #eeeff1;
}

/* ====== ========================1-6.견적문의============================ */
section.inquiry {
  margin-bottom: 0 !important;
}

.inquiry .main-inner {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

.inquiry .inquiry_bg {
  padding: 8rem calc((100% - 76.8%) / 2);
  background:
  linear-gradient(to right, rgba(0, 0, 0, 0.6),  rgba(0, 0, 0, 0.6)),
  url(../../public/images/main/inquiry.jpg)no-repeat center / cover;
}

.inquiry .title .mc-tit {
  margin-bottom: 6rem;
  font-size: 3.2rem;
  color: #fff;
}

.inquiry .btn-more {
  width: 50%;
  height: 4rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #fff;
}

.inquiry:hover .btn-more,
.inquiry .btn-more:hover {
  border: none;
  background: #fff;
}

.inquiry .btn-more a {
  display: block;
  width: 100%;
  line-height: 4rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
}

.inquiry:hover .btn-more a,
.inquiry .btn-more:hover a {
  color: #222;
  font-weight: 600;
}

/* ==============================top 버튼============================= */
.scroll-container {
  z-index: 999;
  position: fixed;
  right: 5%;
  bottom: 2%;
}

#top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  padding: 2.4rem 1.2rem;
  border-radius: 4rem;
  font-size: 1.6rem;
  font-family: var(--en);
  font-weight: 600;
  color: #fff;
  background: #222;
  cursor: pointer;
  scale: 0.9;
  transition: all 0.5s ease;
}

#top:hover,
#top:active {
  background: var(--wh-main-color);
  transition: all 0.5s ease;
  scale: 1;
}

#top img {
  transform: translateY(0);
  transition: all 0.5s ease;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(75deg) brightness(100%) contrast(104%);
}

#top:hover img,
#top:active img {
  transform: translateY(-0.5rem);
}



@media all and (min-width:48em) {

  .mc-tit {
    font-size: 5rem;
  }

  .main-visual .slide-txt .mv-tit span {
		font-size : 6.4rem;
		font-family: var(--en);
		font-weight: 700;
		color: #fff;
	}
  .main-visual .slide-txt .mv-txt {
		line-height: 1.45;
		font-size: 1.8rem;
		font-family: var(--kr);
		font-weight: 300;
		color: #fff;
	}

  .main-visual .slide-txt p br {
    display: block;
  }

  .main-visual div.swiper-pagination {
    top: var(--swiper-navigation-top-offset, 56%);
  }

  .main-visual div.swiper-button-next,
  .main-visual div.swiper-button-prev {
    width: 6rem;
    height: 6rem;
  }

  .main-visual div.swiper-button-next {
    top: var(--swiper-navigation-top-offset,49%);
  }

  .main-visual div.swiper-button-prev {
    top: var(--swiper-navigation-top-offset,52%);
  }

  .scroll-down .label {
    left: 0;
  }

  .scroll-down .label span:first-child {
    font-size: 1.4rem;
    transform: rotate(-90deg) translate(4.8rem, -4rem);
  }

  .scroll-down .label .line {
    left: 0.6rem;
  }

  .intro .box:nth-of-type(1) {
    margin-bottom: 10rem;
  }

  .intro .box-list > div {
    overflow: hidden;
  }

  .intro .box-list .cover {
    padding: 2rem;
    margin-top: -100%;
    opacity: 0;
    transition: all 0.3s ease;
  }

  .intro .box-list > div:hover .cover {
    margin-top: 0;
    opacity: 1;
    transition: all 0.3s ease;
  }

  .intro .box:nth-of-type(1) .mc-tit,
  .intro .box:nth-of-type(2) .mc-tit,
  .intro .box:nth-of-type(2) .mc-tit span {
    font-size: 5rem;
  }

  .intro .box .mc-txt {
    margin-bottom: 4rem;
    font-size: 1.8rem;
  }

  .intro .box:nth-of-type(2) .mc-txt {
    margin-bottom: 12rem;
  }

  .intro .box:nth-of-type(2) .mc-txt br {
    display: block;
  }

  .intro .box-list .cover p {
    font-size: 3.2rem;
  }

  .intro .box div[class^="btn-fl"] a {
    width: 16rem;
    font-size: 2rem;
    border-bottom: 0.2rem solid rgba(255, 255, 255, 0.4);
  }

  .intro .box:nth-of-type(2) .btn-bl-bk {
    margin-bottom: 8rem;
  }

  .intro .box-list .bg {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .intro .box-list .bg p {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 1rem 0;
    margin: 0 2rem 2rem 0;
    border-bottom: 0.2rem solid #fff;
    text-align: left;
    line-height: 1.2;
    font-size: 2.4rem;
    font-family: var(--en);
    font-weight: 400;
    color: #fff;
  }

  .intro .box-list > div:hover .bg p {
    opacity: 0;
  }

  .intro .box-list .bg span {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    margin-left: 2rem;
    background: url(../../public/images/main/arrow_right_wh.svg)no-repeat center;
  }

  .business {
    overflow: auto;
  }

  .business .main-inner {
    overflow: hidden;
    display: block;
  }

  .business .title,
  .business .control {
    width: 30%;
  }

  .business .control {
    position: absolute;
    top: 30rem;
  }

  .business .title {
    float: left;
  }

  .business .business-list {
    float: right;
    width: 60%;
    margin-top: 0;
  }

  .business-list .slide {
    height: 32rem;
  }

  .product {
    clear: both;
  }

  .product .col {
    width: 25rem;
  }

  .product .contents-title {
    display: flex;
    justify-content: space-between;
  }

  .product .contents-title a {
    display: flex;
    justify-content: space-between;
    width: 20rem;
    height: 5.6rem;
    padding: 0 4rem;
    border-radius: 6rem;
    border: 0.1rem solid #929292;
    line-height: 5.6rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: #686868;
  }

  .product .contents-title a span {
    display: block;
    width: 2.4rem;
    height: 5.6rem;
    filter: brightness(0.2);
    background: url(../../public/images/main/arrow_right.svg)no-repeat center / 100%;
  }

  .product .contents-title a:hover {
    border: 0.1rem solid var(--wh-main-color);
    color: #eeeff1;
    background: var(--wh-main-color);
  }

  .product .product-title strong {
    font-size: 2rem;
  }

  .product .product-title .btn-more {
    width: 70%;
    height: 5rem;
  }

  .product .product-title .btn-more a {
    line-height: 5rem;
    font-size: 1.6rem;
  }

  .product .contents-title a:hover span {
    filter: brightness(1);
  }

  .inquiry .contents-box {
    width: 100%;
    height: 32rem;
  }

  .inquiry.main-contents .main-inner {
    max-width: 124rem;
    padding-bottom: 0;
  }

  .inquiry .contents-box .contents-inner {
    height: 100%;
    padding: 4rem;
    transform: translateY(0);
  }

  .inquiry .inquiry_bg {
    padding: 8rem calc((100% - 76.8%) / 2);
  }

  .inquiry .title .mc-tit {
    margin-bottom: 10rem;
    font-size: 5rem;
  }

  .inquiry .contents-box *,
  .inquiry .contents-box a {
    color: #fff;
  }
  
  .inquiry .btn-more {
    width: 40%;
    max-width: 30rem;
    height: 6rem;
    border: 0.1rem solid #fff;
  }

  .inquiry .btn-more a {
    line-height: 6rem;
    font-size: 2rem;
  }
}

@media all and (min-width:64em) {
  .main-visual video {
    display: block;
    width: auto;
    height: 100vh;
  }

  .mv_slide.bg1 {
    background: none;
  }

  .main-visual .slide-txt {
    max-width: 76.8%;
    padding: 0 2.4rem;
  }

  .main-visual div.swiper-pagination {
    top: var(--swiper-navigation-top-offset, 64%);
    padding: 0 2.4rem;
  }

  .row {
    padding: 0 2.4rem;
  }

  .main-inner {
    width: 100%;
    max-width: 76.8%;
    margin: 0 auto;
    padding: 16rem 0;
  }

  .intro .box-list {
    column-gap: 2rem;
  }

  .business .main-inner {
    padding: 0;
  }

  .business .control {
    top: 30rem;
  }

  .product .swiper-slide {
    width: 100% !important;
  }

  .product .grid {
    width: 100%;
  }

  .product .col {
    flex: 1 1 0%;
    width: auto;
    transition: 0.5s ease;
  }

  .product .col:hover {
    flex: 3 1 0%;
    transition: 0.5s ease;
  }

  .inquiry.main-contents .main-inner {
    width: 77.5%;
  }
}

@media all and (min-width:80em) {
  .scroll-container {
    bottom: 5%;
  }

  section:not(:first-child) {
    margin: 32rem 0;
  }

  .main-visual .slide-txt {
    top: 30%;
    padding: 0 calc((100% - 76.8%) / 2);
  }

  .main-visual .slide-txt .mv-tit span {
    font-size: 6.4rem;
  }

  .main-visual div.swiper-pagination {
    padding: 0 calc((100% - 76.8%) / 2);
  }

  .main-visual div.swiper-button-prev,
  .main-visual div.swiper-button-next {
    right: 15rem;
  }

  .scroll-down .row {
    padding: 0 0 0 15rem;
  }

  .scroll-down .label {
    left: 0;
  }

  section.intro {
    height: 100vh;
    margin: 0;
  }

  .intro .main-inner {
    display: flex;
    justify-content: space-between;
    column-gap: 10rem;
    padding: 0;
  }

  .intro .box:nth-of-type(1) {
    transform: translateY(6rem);
  }

  .intro .box:nth-of-type(2) .mc-tit,
  .intro .box:nth-of-type(2) .mc-tit span {
    font-size: 3.2rem;
  }

  .intro .box:nth-of-type(2) .mc-txt {
    margin-bottom: 8rem;
    word-break: keep-all;
  }

  .intro .box:nth-of-type(2) .mc-txt br {
    display: none;
  }


  .intro .box-list .bg p {
    font-size: 1.6rem;
  }

  .business {
    overflow: hidden;
    height: 100vh;
  }

  .business .main-inner {
    max-width: 100%;
    height: 100%;
    margin-left: calc((100% - 76.8%) / 2);
    padding: 16rem 0;
  }

  .business::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 20rem;
    margin-top: 44rem;
    background: url(../../public/images/main/business_bg.jpg)no-repeat bottom 0 center / 100%;
  }

  .business .title,
  .business .control {
    width: 26%;
  }

  .business .control {
    top: 40rem;
  }

  .business-list .slide-item {
    width: 34rem;
    padding-right: 2rem;
  }

  .business-list .slide {
    height: 40rem;
  }

  .product .col {
    height: 46rem;
  }

  .inquiry .contents-box {
    height: 40rem;
  }
}

@media all and (min-width:96em) {
  .main-visual video {
    width: 100%;
    height: auto;
  }
  
  .main-visual .slide-txt {
    padding: 0 16rem;
  }

  .main-visual .slide-txt .mv-tit span {
    font-size: 8rem;
  }

  .main-visual div.swiper-pagination,
  .row {
    padding: 0 16rem;
  }

  .main-visual div.swiper-button-prev,
  .main-visual div.swiper-button-next {
    right: 16rem;
    width: 8rem;
    height: 8rem;
  }

  .main-visual div.swiper-button-next {
    top: var(--swiper-navigation-top-offset,46%);
  }

  .scroll-down .row {
    padding: 0 0 0 17rem;
  }

  .scroll-down .label span:first-child {
    font-size: 1.6rem;
    transform: rotate(-90deg) translate(4.4rem, -4.6rem);
  }

  .business::before {
    height: 30rem;
  }

  .business .title {
    width: 17%;
  }

  .business .control {
    width: 16%;
  }

  .business .business-list {
    width: 72%;
  }

  .business-list .slide-item {
    width: 40rem;
  }

  .business-list .slide {
    height: 50rem;
  }

  .product .contents-title {
    margin-bottom: 4rem;
  }

  .product .col {
    height: 54rem;
  }

  .inquiry .contents-box {
    height: 48rem;
  }

  .inquiry .ctg {
    font-size: 2rem;
  }

  .inquiry h4 {
    font-size: 4rem;
  }

  .inquiry .btn-more {
    height: 6rem;
    border-radius: 1rem;
  }

  .inquiry .btn-more a {
    line-height: 6rem;
    font-size: 1.6rem;
  }
}

@media all and (min-width:120em) {
  div[class^="btn-bl"] a {
    width: 20rem;
    padding-bottom: 2rem;
    font-size: 2rem;
  }

  .main-visual .slide-txt {
    width: 100%;
    max-width: 76.8%;
    padding: 0 calc((100% - 76.8%) / 2);
  }

  .main-visual .slide-txt .mv-txt {
    font-size: 2rem;
  }

  .main-visual div.swiper-pagination,
  .scroll-down {
    padding: 0 calc((100% - 76.8%) / 2);
  }

  .scroll-down .row {
    padding: 0;
  }

  .mc-tit {
    font-size: 5.4rem;
  }

  .intro .box:nth-of-type(1) {
    transform: translateY(17rem);
  }

  .intro .box:nth-of-type(1) .title {
    margin-bottom: 8rem;
  }

  .intro .box:nth-of-type(2) .title {
    margin-top: 6rem;
  }

  .intro .box-list .bg p {
    font-size: 2rem;
  }

  .business::before {
    height: 32rem;
    margin-top: 53rem;
  }

  .business .title {
    width: 18%;
    max-width: 34.5rem;
  }

  .business .control {
    width: 17%;
    max-width: 33rem;
  }

  .business .business-list {
    width: 75%;
  }

  .business .slide-item {
    width: 48rem;
  }

  .business .slide {
    height: 60rem;
  }

  .business .slide strong {
    font-size: 3.2rem;
  }

  .business .slide p {
    font-size: 2rem;
  }

  .product .col {
    height: 60rem;
  }

  .product .swiper-slide {
    height: 75rem;
  }

  .product .product-item {
    height: 124%;
  }

  .product .product-title {
    transform: translateY(8.6rem);
  }

  .product .product-title strong {
    font-size: 3.2rem;
  }

  .product .product-title .btn-more {
    width: 64%;
    height: 8rem;
  }

  .product .product-title .btn-more a {
    line-height: 8rem;
    font-size: 2rem;
  }
}

@media all and (min-width:160em) {

  div[class^="btn-bl"] a {
    width: 24rem;
    padding-bottom: 2.4rem;
    font-size: 2.4rem;
  }

  .main-visual .slide-txt .mv-tit span {
    font-size: 12rem;
  }

  .main-visual .slide-txt .mv-txt {
    font-size: 2.4rem;
  }

  .main-visual div.swiper-pagination {
    top: var(--swiper-navigation-top-offset, 60%);
  }

  .main-visual .swiper-pagination-bullet {
    width: 1.6rem;
    height: 1.6rem;
  }

  .main-visual div.swiper-button-next::after {
    background: url(../../public/images/main/prev.svg) no-repeat center / 12%;
  }

  .main-visual div.swiper-button-prev::after {
    background: url(../../public/images/main/next.svg) no-repeat center / 12%;
  }

  .main-visual div.swiper-button-prev,
  .main-visual div.swiper-button-next {
    right: calc((100% - 76.8%) / 2);
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
  }

  .scroll-down .label span:first-child {
    font-size: 2rem;
    transform: rotate(-90deg) translate(6rem, -5.8rem);
  }

  .intro .box-list {
    column-gap: 4rem;
  }

  .intro .box:nth-of-type(1) {
    transform: translateY(20rem);
  }

  .intro .box:nth-of-type(1) .title {
    margin-bottom: 16rem;
  }

  .intro .box:nth-of-type(1) .mc-tit {
    font-size: 6.4rem;
  }

  .intro .box-list > div {
    height: fit-content;
  }

  .intro .box:nth-of-type(2) .mc-tit,
  .intro .box:nth-of-type(2) .mc-tit span {
    font-size: 5.6rem;
  }

  .intro .box .mc-txt {
    font-size: 2.4rem;
  }

  .intro .box-list .cover {
    height: 100%;
  }

  .intro .box-list .cover p {
    font-size: 4rem;
  }

  .intro .box-list .bg p {
    padding: 2rem 0;
    font-size: 3.2rem;
  }

  .intro .box-list .bg span {
    width: 4rem;
    height: 4rem;
    background-size: 100% 100%;
  }

  .business::before {
    height: 50rem;
  }

  .business .business-list {
    width: 70%;
    scale: 1.2;
  }

  .business .mc-txt {
    font-size: 2rem;
  }

  .business .control {
    top: 44rem;
  }

  .business .slide-count {
    justify-content: space-between;
  }

  .business .slide-count span {
    font-size: 2.4rem;
  }

  .product .product-title {
    padding: 4rem;
    transform: translateY(11rem);
  }

  .product .product-title h3 {
    margin-bottom: 4rem;
  }

  .product .product-title strong {
    font-size: 4rem;
  }

  .inquiry .title .mc-tit {
    font-size: 6.4rem;
  }

  .inquiry .btn-more {
    height: auto;
  }

  .inquiry .btn-more a {
    line-height: 8rem;
    font-size: 2.4rem;
  }

  #top {
    scale: 1.2;
  }

  #top:hover,
  #top:active {
    scale: 1.3;
  }
}