/* ================= 서브 공통 ================== */
section {
  overflow: hidden;
  width: 100vw;
}
.sub-inner {
	padding: 0 1.6rem;
}

.sub .visual .sv-title {
  width: 100%;
  padding: 0 2.4rem; 
}

.sub .visual .sv-title > div {
  width: 100%;
  padding: 12rem 0 6rem;
}

.sv-tit {
	line-height : 1.45;
  text-align: center;
  font-size: 4rem;
	font-family: var(--kr);
	font-weight: 700;
	color: #fff;
}

.sv-txt {
	line-height : 1.5;
  word-break: keep-all;
  text-align: center;
	font-size : 1.6rem;
	font-family: var(--kr);
	font-weight: 400;
	color: #fff;
}

.sv-txt span {
	line-height : 1;
	font-size : 5rem;
	font-family: var(--en);
	font-weight: 600;
	color: #fff;
}

.sc-tit {
  word-break: keep-all;
	line-height : 1.35;
	font-size : 3.2rem;
	font-family: var(--kr);
	font-weight: 600;
	color: #222;
}

.sc-txt {
	line-height : 1.35;
	font-size : 2rem;
	font-family: var(--kr);
	font-weight: 400;
	color: #222;
}

.txt {
	line-height: 1.35;
	font-size: 1.6rem;
	font-weight: 300;
	font-family: var(--kr);
	color: #222;
}

.CompanyIntro .sv-title,
.Recruit .sv-title {
  background: url(../../public/images/company/sub_visual1.jpg)no-repeat center top 0 / 100% 180%;
}

.IptConsulting .sv-title,
.NetworkBuilding .sv-title,
.WlanBuilding .sv-title,
.Maintenance .sv-title,
.Distributor .sv-title {
  background: url(../../public/images/business/sub_visual2.jpg)no-repeat center / cover;
}

.Ipt .sv-title,
.Network .sv-title, 
.Wlan .sv-title,
.Security .sv-title,
.Software .sv-title {
  background: url(../../public/images/product/sub_visual3.jpg)no-repeat center / cover;
}

.QuoteInquiry .visual .sv-title,
.Directions .visual .sv-title {
  background: url(../../public/images/support/sub_visual4.jpg)no-repeat center / cover;
}

.sub .visual .sv-title span {
  display: block;
  width: 100%;
  padding-top: 7.2rem;
  line-height: calc(24rem - 7.2rem);
  text-align: center;
  font-size: 3.2rem;
  font-weight: 600;
  color: #eeeff1;
}

/* ================= 서브 lnb & dropdown ================== */

.sub .lnb {
  display: none;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
  background: #fff;
  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.sub .lnb.top-fixed {
  z-index: 20;
  position: fixed;
  top: 0;
  box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}

.dropdown {
  position: relative;
  transition: all 0.3s ease;
}

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 6rem;
  padding: 1rem 2rem;
  border: none;
  box-sizing: border-box;
  background-color: #eee;
  cursor: pointer;
}

.dropdown-button span {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
  color: #303030;
}

.dropdown-button span:nth-child(2) {
  display: block;
  width: 2rem;
  height: 2rem;
  background: url(../../public/images/main/sub_more_btn.svg)no-repeat center / 1.2rem;
  opacity: 0.6;
}

.open .dropdown-button span:nth-child(2) {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.dropdown-menu {
  z-index: 10;
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  list-style-type: none;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.open .dropdown-menu {
  opacity: 1;
  transform: scaleY(1);
}

.dropdown-menu li {
  border-top: 0.1rem solid #eee;
  cursor: pointer;
}

.dropdown-menu .on span,
.dropdown-menu li:hover span {
  color: var(--wh-main-color);
}

.dropdown-menu li a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.4rem;
  padding: 0 3.6rem;
  line-height: 1.5;
  word-break: keep-all;
  box-sizing: border-box;
}

.dropdown-menu li span {
  font-size: 1.4rem;
  font-family: var(--kr);
  font-weight: 500;
}

.sub-tab::-webkit-scrollbar,
.benefits .content::-webkit-scrollbar,
.spec .table-wrap::-webkit-scrollbar,
.etc .graph::-webkit-scrollbar,
.coreEdge .line-up-sw .content::-webkit-scrollbar  {
  display: none;
}

.sub-tab,
.benefits .content,
.spec .table-wrap,
.etc .graph,
.coreEdge .line-up-sw .content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* ================= 제품 페이지 탭 메뉴 ================== */
.sub .lnb.header-up {
  z-index: 888;
  position: fixed;
  top: 0;
  background: #fff;
}

.sub .sub-tab.up {
  z-index: 888;
  position: fixed;
  top: 7rem;
  background: #ffffff;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15);
}

.header.default.hide + .sub .sub-tab.up {
  top: 0;
}

.sub-tab {
  overflow-X: scroll;
  margin: 2rem 0;
}

.sub-tab .sub-inner {
  display: flex;
  column-gap: 1rem;
  width: fit-content;
}

.sub-tab button {
  width: fit-content;
  min-width: 12rem;
  padding: 1rem 2rem;
  border-radius: 5rem;
  word-break: keep-all;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: var(--kr);
  color: #4c4c4c;
  background: #ededed;
}

.sub-tab button.on,
.sub-tab button:hover {
  font-weight: 500;
  color: #fff;
  background: var(--wh-main-color);
}

.loader-box {
  position: relative;
  display: inline-block;
  width: 20rem;
  height: 20rem;
  transition: all .2s ease;
}

.loader-box div:before{
  content: "";
  position: absolute;
  top: 0px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  -webkit-animation: loader9g 3s ease-in-out infinite;
  animation: loader9g 3s ease-in-out infinite;
}

.loader-box div {
  position: relative;
  top: 46%;
  left: 46%;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #fff;
}


.loader-box div:after {
  content: "";
  position: absolute;
  top: 0px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  -webkit-animation: loader9d 3s ease-in-out infinite;
  animation: loader9d 3s ease-in-out infinite;
}

@-webkit-keyframes loader9g{
  0%{ left: -25px; background-color: rgba(255, 255, 255, .8); }
  50%{ left: 0px; background-color: rgba(255, 255, 255, .1);}
  100%{ left:-25px; background-color: rgba(255, 255, 255, .8); }
}
@keyframes loader9g{
  0%{ left: -25px; background-color: rgba(255, 255, 255, .8); }
  50%{ left: 0px; background-color: rgba(255, 255, 255, .1);}
  100%{ left:-25px; background-color: rgba(255, 255, 255, .8); }
}

@-webkit-keyframes loader9d{
  0%{ left: 25px; background-color: rgba(255, 255, 255, .8); }
  50%{ left: 0px; background-color: rgba(255, 255, 255, .1);}
  100%{ left:25px; background-color: rgba(255, 255, 255, .8); }
}
@keyframes loader9d{
  0%{ left: 25px; background-color: rgba(255, 255, 255, .8); }
  50%{ left: 0px; background-color: rgba(255, 255, 255, .1);}
  100%{ left:25px; background-color: rgba(255, 255, 255, .8); }
}

/* ================= 1-1. 기업정보 ================== */

.CompanyIntro .sc-tit {
  margin-bottom: 4rem;
  text-align: center;
  word-break: keep-all;
  font-size: 3rem;
  color: #212121;
}

.about .sc-txt {
  margin-bottom: 2rem;
  line-height: 1.5;
  word-break: keep-all;
  text-align: center;
  font-size: 1.6rem;
  color: rgba(0, 0, 0, 0.6);
}

.value .sc-tit {
  margin-bottom: 8rem;
}

.value .content {
  padding-bottom: 6rem;
}

.value .content > div:nth-child(1) {
  margin-bottom: 4rem;
}

.value .content > div:nth-child(1)::after {
  content: "";
  position: relative;
  left: 50%;
  display: block;
  width: 0.1rem;
  height: 10rem;
  margin-top: 2rem;
  background: transparent;
}

.value .content > div:nth-child(1) p::after {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  width: 0.1rem;
  height: 0;
  margin-top: 2rem;
  background: #2766B0;
  animation: vl 4s infinite;
  animation-delay: 1s;
}

@keyframes vl {
  0% {
    height: 0;
    transition: all 0.5s ease;
  }
  40% {
    height: 10rem;
    transition: all 0.5s ease;
  }
  100% {
    height: 10rem;
    transition: all 0.5s ease;
  }
}

.value .content > div:first-child p,
.value .content > div:first-child span  {
  word-break: keep-all;
  text-align: center;
  line-height: 1.3;
  font-size: 2.4rem;
  font-weight: 500;
  font-family: var(--kr);
  color: #212121;
}

.highlight{
  display: inline-block; 
  position: relative;
}

.highlight:after{
  content:"";
  z-index:-1;
  position: absolute;
  bottom:0;
  left:0;
  display: inline-block;
  width: 100%;
  height: 1.6rem;
  background: rgb(61, 176, 240, 0.5);
  animation: underline 8s infinite;
}

@keyframes underline {
  0% {
    width: 0;
  }
  30% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

.value .content > div:first-child:hover .highlight:after,
.value .content > div:first-child:active .highlight:after {
  width:100%;
  transition: all 0.5s ease;
}

.value .diagram {
  width: 30rem;
  height: 30rem;
  margin: 0 auto;
}

.value .diagram .value-core,
.value .diagram .ani-roof {
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
}

.value .top {
  position: absolute;
}

.value .value-list li {
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12rem;
  height: 12rem;
  padding: 1rem;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.15);
}

.value .value-list li:nth-child(1) {
  transform: translate(-2rem, -1rem);
}

.value .value-list li:nth-child(2) {
  transform: translate(20rem, -1rem);
}

.value .value-list li:nth-child(3) {
  transform: translate(10rem, 24rem);
}

.value .value-list div:nth-child(1) {
  width: 100%;
  margin-bottom: 0.6rem;
  padding-bottom: 0.6rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.value .value-list p,
.value .value-list span {
  display: block;
  text-align: center;
}

.value .value-list div:nth-child(1) p {
  line-height: 1.3;
  font-size: 1.4rem;
  font-family: var(--en);
  font-weight: 600;
  color: var(--wh-main-color);
}

.value .value-list span {
  line-height: 1.3;
  line-height: 1.4;
  font-size: 1.4rem;
  font-family: var(--kr);
  font-weight: 500;
  color: #212121;
}

.value .value-list div:nth-child(2) p {
  word-break: keep-all;
  line-height: 1.3;
  font-size: 1.2rem;
  font-family: var(--kr);
  font-weight: 300;
  color: rgba(0, 0, 0, 0.6);
}

.value .value-core > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 95%;
  background: url(../../public/images/company/value_list_bg.png)no-repeat center / 100%;
}

.value .value-core img {
  display: none;
}

.value .value-core p {
  line-height: 1.4;
  font-size: 1.8rem;
  font-family: var(--en);
  font-weight: 600;
  color: var(--wh-main-color);
}

.value .value-core span {
  line-height: 1.4;
  font-size: 1.4rem;
  font-family: var(--kr);
  font-weight: 500;
  color: #212121;
}

.value .triangle {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: var(--wh-main-color);
}

.value .ani-roof {
  width: 30rem;
  height: 30rem;
}

.value .cir_1 {
  animation: cir_1 3s infinite linear;
}

@keyframes cir_1 {
  0% {
    transform: rotate(120deg) translateY(15rem);
  }

  100% {
    transform: rotate(240deg) translateY(15rem);
  }
}

.value .cir_2 {
  animation: cir_2 3s infinite linear;
}

@keyframes cir_2 {
  0% {
    transform: rotate(0deg) translateY(15rem);
  }

  100% {
    transform: rotate(120deg) translateY(15rem);
  }
}

.value .cir_3 {
  animation: cir_3 3s infinite linear;
}

@keyframes cir_3 {
  0% {
    transform: rotate(240deg) translateY(15rem);
  }

  100% {
    transform: rotate(360deg) translateY(15rem);
  }
}

.value .ani-roof svg {
  width: 100%;
  height: 100%;
}

.history {
  padding: 16rem 0;
  background: linear-gradient(360deg, #d7eaff54, transparent);
}

.history .sc-txt {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2.4rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #222;
}

.history .h-list {
  height: fit-content;
  transition: all 0.3s ease;
}

.history .h-list.collapsed > div:nth-child(8)~div {
  display: none;
  opacity: 0;
  transition: all 0.3s ease;
}

.history .h-list.outspread > div:nth-child(8)~div {
  display: block;
  opacity: 1;
  transition: all 0.3s ease;
}

.history .h-list::after {
  content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  height: calc(100% - 24rem);
  margin-top: 6rem;
  border-right: 0.1rem dashed var(--wh-main-color);
}

.history .h-list .item {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin: 3rem 0 4rem 0;
  text-align: center;
  transition: all 0.5s ease;
}

.history .h-list .item::after {
  z-index: 5;
  content: "";
  position: absolute;
  top: calc(100% + 1.5rem);
  left: 50%;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  margin-left: -0.5rem;
  border: 0.3rem solid var(--wh-main-color);
  border-radius: 50%;
  background: #fff;
  box-sizing: content-box;
}

.history .h-list.outspread::after {
  height: calc(100% - 16rem);
}

.history .h-list .item > div {
  z-index: 4;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 1.6rem 2.4rem;
  text-align: center;
  background: #fff;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease;
}

.history .h-list strong {
  display: inline-block;
  margin-bottom: 0.4rem;
  line-height: 3rem;
  text-align: center;
  font-size: 1.8rem;
  font-family: var(--en);
  font-weight: 500;
  color: #212121;
}

.history .h-list p {
  text-align: center;
  line-height: 3rem;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.history .folding-btn {
  padding: 4rem 0;
}

.history .folding-btn.show button {
  display: flex;
}

.history .folding-btn.hide button {
  display: none;
}

.history .folding-btn button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 24rem;
  height: 6rem;
  padding: 0 2rem;
  margin: 2rem auto 0 auto;
  border: 0.1rem solid var(--wh-main-color);
  border-radius: 8rem;
  text-align: left;
  font-size: 1.8rem;
  font-family: var(--kr);
  font-weight: 600;
  color: var(--wh-main-color);
}

.history .folding-btn button:hover {
  background: var(--wh-main-color);
  color: #fff;
}

.history .folding-btn button span {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  background: url(../../public/images/product/network/toggle_btn.svg)no-repeat center / 50% 50%;
  transform: rotate(180deg);
  filter: invert(31%) sepia(94%) saturate(521%) hue-rotate(172deg) brightness(96%) contrast(91%);
}

.history .folding-btn button:hover span {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(108deg) brightness(104%) contrast(104%);
}


.org {
  overflow: initial;
}

.org .line-group {
  display: none;
}

.ceo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32rem;
  height: 32rem;
  margin: 4rem auto;
  background: url(../../public/images/company/ceo_bg.png)no-repeat center / cover;
}

.ceo p {
  font-size: 3.2rem;
  font-family: var(--en);
  font-weight: 700;
  color: var(--wh-main-color);
}

.org-list {
  position: relative;
  margin-top: -6rem;
}

.org-list > li {
  margin-bottom: 2rem;
}

.org-list > li > div,
.org-list > li li {
  width: 100%;
  padding: 2rem;
  border-radius: 1rem;
}

.org-list > li > div {
  box-shadow: -0.4rem 0.4rem 0.8rem rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #008BAE, #1868C7);
}

.org-list span {
  display: block;
  text-align: right;
  font-family: var(--en);
}

.org-list div span,
.org-list div p {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
}

.org-list div i {
  display: block;
  margin-top: 0.8rem;
  font-size: 1rem;
  font-family: var(--en);
  font-weight: 400;
  font-style: normal;
  color: rgba(255, 255, 255, 0.6);
}

.org-list ul li {
  padding: 2rem;
  margin-top: 1rem;
  border: 0.1rem solid var(--wh-main-color);
}

.org-list ul li p {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--wh-main-color);
}

.org-list ul li i {
  display: block;
  margin-top: 0.8rem;
  text-align: center;
  font-size: 1.2rem;
  font-family: var(--en);
  font-style: normal;
  color: rgba(0, 0, 0, 0.6);
}

/* ================= 1-2. 채용정보 ================== */
.talent .title {
  margin-bottom: 8rem;
}

.talent-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 4rem;
}

.talent-list li {
  overflow: hidden;
  width: 100%;
  border-radius: 0.8rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.talent-list div {
  width: 100%;
  height: 24rem;
  padding: 2rem;
}

.talent-list li:nth-child(1) div {
  background: url(../../public/images/company/talent1.png)no-repeat right 0 bottom -4rem / 50%;
}

.talent-list li:nth-child(2) div {
  background: url(../../public/images/company/talent2.png)no-repeat right 0 bottom -1rem / 60%;
}

.talent-list li:nth-child(3) div {
  background: url(../../public/images/company/talent3.png)no-repeat right -4rem bottom -10rem / 80%;
}

.talent-list li:nth-child(4) div {
  background: url(../../public/images/company/talent4.png)no-repeat right 0 bottom 0 / 80%;
}

.talent-list li:nth-child(5) div {
  background: url(../../public/images/company/talent5.png)no-repeat right 0 bottom 0 / 80%;
}

.talent-list li:nth-child(6) div {
  background: url(../../public/images/company/talent6.jpg)no-repeat center / cover;
}

.talent-list li:nth-child(1):hover div {
  background: url(../../public/images/company/talent1_hover.png)no-repeat right 0 bottom -4rem / 50%;
}

.talent-list li:nth-child(2):hover div {
  background: url(../../public/images/company/talent2_hover.png)no-repeat right 0 bottom -1rem / 60%;
}

.talent-list li:nth-child(3):hover div {
  background: url(../../public/images/company/talent3_hover.png)no-repeat right -4rem bottom -10rem / 80%;
}

.talent-list li:nth-child(4):hover div {
  background: url(../../public/images/company/talent4_hover.png)no-repeat right 0 bottom 0 / 80%;
}

.talent-list li:nth-child(5):hover div {
  background: url(../../public/images/company/talent5_hover.png)no-repeat right 0 bottom 0 / 80%;
}

.talent-list li:hover {
  background: #000;
  transition: all 0.4s;
}

.talent-list li strong {
  font-size: 2rem;
  font-weight: 600;
  color: var(--wh-main-color);
}

.talent-list li p:first-child {
  margin-bottom: 1.6rem;
}

.talent-list li p:last-child {
  line-height: 150%;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.6);
}

.talent-list li:hover strong {
  color: #fff;
}

.talent-list li:hover p:last-child {
  color: rgba(255, 255, 255, 0.6);
}

.step .title {
  margin-bottom: 8rem;
}

.step .sc-tit {
  margin-bottom: 2rem;
}

.swiper-slide:first-child .step-item {
  background-image: none;
  background-color: #7599C3;
}

.step .swiper {
  margin-bottom: 8rem;
}

.step-item {
  position: relative;
  max-width: 100%;
  height: auto;
  height: 12rem;
  padding: 6rem 4rem;
  background-color: #7599C3;
}

.step-item .after {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  width: 5rem;
  height: 100%;
  background: url(../../public/images/company/step_after.png)no-repeat center / cover;
  background-color: #fff;
  background-position: right 0.1rem center;
}

.swiper-slide:last-child .step-item {
  background: url(../../public/images/company/step_bg_gd1.png)no-repeat left 0 center / calc(100% - 4.8rem) 100%;
}

.swiper-slide:last-child .step-item .after {
  background: url(../../public/images/company/step_after_gd.png)no-repeat center / cover;
}

.step-item .line {
  z-index: 5;
  position: absolute;
  left: auto;
  right: 2rem;
  top: 50%;
  bottom: auto;
  width: 6rem;
  height: 0.1rem;
  background-color: #fff;
  opacity: 0.2;
}

.step-item .top {
  font-size: 1.6rem;
  font-family: var(--en);
  font-weight: 400;
  color: #fff;
}

.step-item p {
  margin: 2rem 0 6rem;
  font-size: 1.8rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #fff;
}

.step-item em {
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  font-style: normal;
  color: #fff;
}

.step .swiper-pagination {
  position: initial;
  margin-top: 3rem;
}

.step .swiper-pagination-bullet {
  width: 1rem;
  height: 1rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.8);
  background: none;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 1rem;
}

.step .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 0;
  background: var(--wh-main-color);
}

.step .btn-bl-bk {
  margin: 0 auto;
}

.step .btn-bl-bk a {
  width: 20rem;
}

.benefits .title {
  margin-bottom: 8rem;
}

.benefits ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1rem;
  column-gap: 2%;
  width: 100%;
}

.benefits li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48%;
  padding: 2rem;
  border-radius: 1rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.benefits li:hover {
  border: 0;
  box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.2);
}

.benefits img {
  height: 8rem;
}

.benefits p {
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 500;
  color: #212121;
}

/* ================= 2. 사업분야 공통 ================== */
.governing .title {
  text-align: center;
}

.governing .tit {
  line-height: 1.2;
  word-break: keep-all;
  margin-bottom: 2rem;
  font-size: 4rem;
  font-family: var(--kr);
  font-weight: 700;
  color: #212121;
}

.governing .txt {
  line-height: 1.4;
  word-break: keep-all;
  font-size: 1.8rem;
  font-family: var(--kr);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.governing .txt br {
  display: none;
}

.business-info .info-list > li {
  overflow: hidden;
  max-width: 88rem;
}

.business-info .info-list > li:not(:first-child) {
  margin-top: 8rem;
}

.business-info .info-list > li .bg {
  width: 100%;
  height: 18rem;
  overflow: hidden;
  background: #ededed;
}

.business-info .info-list > li .bg span {
  display: block;
  width: 100%;
  height: 100%;
}

.business-info .info-list > li .con {
  position: relative;
  width: 95%;
  margin-left: 5%;
  margin-top: -3rem;
  padding: 3rem;
  background: #fff;
}

.business-info .info-list > li .con .num {
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-bottom: -.5rem;
  line-height: 0.8;
  font-size: 9rem;
  font-family: var(--en);
  font-weight: 700;
  color: #fff;
}

.business-info .info-list > li:nth-child(even) .con .num {
  left: auto;
  right: 1rem;
}

.business-info .info-list > li .con .tit {
  display: flex;
  flex-wrap: wrap;
  font-size: 2.4rem;
  font-weight: 600;
}

.business-info .info-list > li .con .tit span {
  width: 100%;
  order: -1;
  margin-bottom: .5rem;
  font-size: 1.2rem;
  font-family: var(--en);
  font-weight: 400;
}

.business-info .info-list > li .con .txt {
  margin-top: 1.5rem;
  line-height: 1.4;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: -0.1rem;
  word-break: keep-all;
  color: #666;
}

.business-info .info-list > li .con ul {
  margin-top: 2rem;
}

.business-info .info-list > li .con li {
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  color: #999;
}

.process .proc-list {
  height: 48rem;
}

.process .grid {
  display: flex;
  width: 100%;
}

.process .col {
  width: 0;
  overflow: hidden;
}

.process .col.active {
  width: 100%;
}

.process .title {
  margin-bottom: 8rem;
}

.process .col .tit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 30rem;
  height: 30rem;
  margin: 0 auto 4rem auto;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 50%;
  border: 0.1rem solid rgba(0, 0, 0, 0.6);
}

.process .col span {
  display: block;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  line-height: 5rem;
  text-align: center;
  font-size: 2rem;
  font-family: var(--en);
  font-weight: 600;
  color: #212121;
  background: #fff;
}

.process .col .tit p {
  display: flex;
  align-items: center;
  height: calc(100% - 5rem);
  line-height: 1.4;
  word-break: keep-all;
  text-align: center;
  font-size: 2.4rem;
  font-family: var(--kr);
  font-weight: 500;
  color: #212121;
}

.process .col.active .tit {
  border: 0;
  background: linear-gradient(315deg, #008BAE, #1868C7);
  transition: all 0.5s ease;
}

.process .col.active .tit p {
  color: #fff;
}

.process .col.active .tit span {
  color: var(--wh-main-color);
}

.process .area ul {
  width: fit-content;
  margin: 0 auto;
}

.process li {
  display: flex;
  width: fit-content;
  margin-bottom: 0.8rem;
  word-break: keep-all;
  line-height: 1.4;
}

.process li::before {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  margin: 0.8rem 1rem 0.8rem 0;
  border-radius: 50%;
  background: var(--wh-main-color);
  opacity: 0.6;
}

.process li p {
  width: calc(100% - 1.5rem);
  line-height: 1.4;
  word-break: keep-all;
  font-size: 1.6rem;
  font-family: var(--kr);
  color: #212121;
}

.process .pagi {
  display: flex;
  justify-content: center;
}

.process .pagi span {
  display: block;
  width: 1rem;
  height: 1rem;
  margin: 0 0.8rem;
  border-radius: 50%;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.process .pagi span.active {
  border: 0;
  background: var(--wh-main-color);
}

.service .title {
  margin-bottom: 8rem;
}

.service .svc-list .item {
  display: flex;
  column-gap: 4rem;
  width: 100%;
  padding: 2rem;
  margin-bottom: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5rem);
}

.service .svc-list .item:hover {
  border: 0;
  box-shadow: 0 0 0.4rem rgba(54, 126, 178, 0.25);
}

.service .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  min-width: 5rem;
  height: 5rem;
  min-height: 5rem;
  border-radius: 50%;
  background: linear-gradient(360deg, #58C0DA, #1779EC);
}

.service .icon img {
  width: 60%;
}

.service .icon + div {
  width: calc(100% - 10rem);
}

.service .content p {
  margin: 1rem 0 2rem;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #212121;
}

.service .content li {
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.inquiry_banner {
  margin-bottom: 0 !important;
  background: url(../../public/images/business/inquiry_banner.jpg)no-repeat left 0 top 40% / cover;
}

.inquiry_banner div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem;
  border-radius: 1rem;
  text-align: center;
}

.inquiry_banner .sc-tit {
  margin-bottom: 2rem;
  word-break: keep-all;
  font-size: 3.2rem;
  font-weight: 600;
  color: #fff;
}

.inquiry_banner .sc-txt {
  margin-bottom: 4rem;
  word-break: keep-all;
  line-height: 150%;
  font-size: 1.4rem;
  font-weight: 400;
  color: #fff;
}

.inquiry_banner a {
  display: block;
  width: 100%;
  max-width: 24rem;
  padding: 2rem 0;
  border-radius: 0.8rem;
  font-size: 1.8rem;
  font-weight: 500;
  background: #fff;
}

.inquiry_banner a:hover {
  color: #fff;
  background: var(--wh-main-color);
}

/* ================= 2-1. IPT 구축 ================== */
.IptConsulting .info-list li:nth-child(1) .bg {
  background: url(../../public/images/business/ipt_info1.jpg)no-repeat center / cover;
}

.IptConsulting .info-list li:nth-child(2) .bg {
  background: url(../../public/images/business/ipt_info2.jpg)no-repeat center / cover;
}

.IptConsulting .info-list li:nth-child(3) .bg {
  background: url(../../public/images/business/ipt_info3.jpg)no-repeat center / cover;
}

/* ================= 2-2. network 구축 ================== */
.NetworkBuilding .info-list li:nth-child(1) .bg {
  background: url(../../public/images/business/network_info1.jpg)no-repeat center / cover;
}

.NetworkBuilding .info-list li:nth-child(2) .bg {
  background: url(../../public/images/business/network_info2.jpg)no-repeat center / cover;
}

/* ================= 2-3. wlan 구축 ================== */
.WlanBuilding .governing .title {
  margin-bottom: 16rem;
}

.trend .sc-tit,
.trend .sc-tit span {
  text-align: left;
  font-size: 4rem;
  font-family: var(--en);
  font-weight: 600;
  color: #212121;
}

.trend .sc-txt {
  margin-top: 4rem;
  word-break: keep-all;
  line-height: 1.4;
  letter-spacing: -0.02rem;
  text-align: justify;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 300;
  color: rgba(0, 0, 0, 0.6);
}

.trend .area > div {
  margin-bottom: 8rem;
}

.trend .bg {
  display: block;
  width: 100%;
  height: 32rem;
}

.trend .area > div:first-child .bg {
  background: url(../../public/images/business/trend_bg1.jpg)no-repeat center / cover;
}

.trend .area > div:last-child .bg {
  background: url(../../public/images/business/trend_bg2.jpg)no-repeat center / cover;
}

.trend .bg + div {
  position: relative;
  width: 90%;
  margin-top: -4rem;
  padding: 2rem;
  background: #fff;
  box-shadow: 0.4rem 0.4rem 1.2rem rgba(0, 0, 0, 0.2) ;
}

.trend .area > div:last-child > div {
  margin-left: 10%;
}

.trend p b {
  font-size: 2.4rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #212121;
}

.trend .area div > p:nth-child(2) {
  margin: 1rem 0 4rem;
  font-size: 1.6rem;
  font-family: var(--en);
  font-weight: 300;
  color: rgba(0, 0, 0, 0.6);
}

.trend ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.trend .area img {
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
}

.trend li {
  text-align: center;
}

.trend li p {
  line-height: 1.4;
  word-break: keep-all;
  font-size: 1.4rem;
  font-family: var(--en);
  color: rgba(0, 0, 0, 0.6);
}

.WlanBuilding .info-list li:nth-child(1) .bg {
  background: url(../../public/images/business/wlan_info1.jpg)no-repeat center / cover;
}

.WlanBuilding .info-list li:nth-child(2) .bg {
  background: url(../../public/images/business/wlan_info2.jpg)no-repeat center / cover;
}

.WlanBuilding .service .list-wrap {
  margin-bottom: 4rem;
  padding: 3rem 2rem;
  border-radius: 1rem;
}

.WlanBuilding .service .list-wrap:first-child {
  box-shadow: 0 0 1.2rem rgba(54, 126, 178, 0.4);
  background: linear-gradient(226deg, #2288ff33, #ffffff33, #008bae33);
}

.WlanBuilding .service .list-wrap:last-child {
  box-shadow: 0 0 1.2rem rgba(54, 171, 178, 0.4);
  background: linear-gradient(226deg, #50d7ea33, #ffffff33, #2288ff33);
}

.WlanBuilding .service .tit {
  font-size: 2.4rem;
  font-family: var(--en);
  font-weight: 700;
}

.WlanBuilding .service .list-wrap:first-child .tit {
  color: var(--wh-main-color);
}

.WlanBuilding .service .list-wrap:last-child .tit {
  color: var(--wh-sub-color);
}

.WlanBuilding .service .txt {
  margin-bottom: 4rem;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 500;
  color: #212121;
}

.WlanBuilding .service .item {
  border: 0;
  box-shadow: 0 0 0.4rem rgba(54, 126, 178, 0.4);
  background: rgba(255, 255, 255, 0.4);
}

/* ================= 2-4. 유지보수 ================== */

.Maintenance .governing .sub-inner {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.Maintenance .governing .gif {
  max-width: 100rem;
  margin: 10rem auto 0 auto;
}

.Maintenance .governing .gif img {
  width: 100%;
  height: auto;
  opacity: 0.8;
}

.Maintenance .governing .title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.Maintenance .governing p,
.Maintenance .governing span {
  line-height: 1.4;
  word-break: keep-all;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 700;
  color: #212121;
}

.w-diagram .sub-inner::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  width: 0.1rem;
  height: 110rem;
  margin: -5rem auto 0 auto;
  background: #2766b099;
  animation: ver_line 8s infinite;
  transition: all 0.5s ease;
}

@keyframes ver_line {
  0% {
    height: 0;
  }
  40% {
    height: 110rem;
  }
  100% {
    height: 110rem;
  }
}

.w-diagram .function {
  position: relative;
  z-index: 3;
}

.w-diagram .function .cir_m {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24rem;
  height: 24rem;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 50%;
  line-height: 1.4;
  word-break: keep-all;
  text-align: center;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 500;
  color: var(--wh-main-color);
  box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.25);
  background: #fff;
}

.w-diagram .function .cir_b {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  margin: 4rem 0;
  background: linear-gradient(to right, rgba(255,255,255,0.4), rgba(255,255,255,0.4)),
              url(../../public/images/business/cir_b_bg.png)no-repeat center /100% 100%;
}

.w-diagram .function .cir_b > * {
  position: absolute;
}

.w-diagram .cir_b .sol {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1/1;
}

.w-diagram .cir_b .sol li {
  z-index: 3;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  height: 12rem;
  padding: 2rem;
  border-radius: 50%;
  line-height: 1.4;
  word-break: keep-all;
  text-align: center;
  font-size: 1.8rem;
  font-family: var(--en);
  font-weight: 500;
  color: var(--wh-main-color);
  background: #fff;
  box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.15);
}

.w-diagram .cir_b .sol li:nth-child(1) {
  transform: translate(-12rem, -12rem);
}

.w-diagram .cir_b .sol li:nth-child(2) {
  transform: translate(12rem, -12rem);
}

.w-diagram .cir_b .sol li:nth-child(3) {
  transform: translate(-12rem, 12rem);
}

.w-diagram .cir_b .sol li:nth-child(4) {
  transform: translate(12rem, 12rem);
}

.w-diagram .cir_b > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  aspect-ratio: 1/1;
  padding: 2rem;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
}

.w-diagram .cir_b > div * {
  text-align: center;
  word-break: keep-all;
}

.w-diagram .cir_b > div p {
  margin-bottom: 1rem;
  line-height: 1.2;
  font-size: 2.4rem;
  font-family: var(--en);
  font-weight: 600;
  color: var(--wh-main-color);
}

.w-diagram .cir_b > div span {
  line-height: 1.4;
  font-size: 1.5rem;
  font-family: var(--kr);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.w-diagram .keyword {
  padding-top: 15rem;
}

.w-diagram .keyword ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 14rem;
  border: 0.1rem solid #2766b099;
}

.w-diagram .keyword li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1/1;
  padding: 3rem;
  line-height: 1.4;
  word-break: keep-all;
  text-align: center;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 500;
  color: #fff;
  border-radius: 50%;
  background: #077badc1;
}

.w-diagram .keyword li:not(:first-child) {
  margin-top: -3rem;
}

/* ================= 2-5. 총판사업 ================== */
.partner .sumnail {
  width: 100%;
  height: 30rem;
  margin-bottom: 4rem;
  background: url(../../public/images/business/partner1.jpg)no-repeat left center / cover;
}

.partner .info > div {
  margin-bottom: 8rem;
}

.partner .info .logo {
  display: none;
}

.partner .info > div img {
  display: none;
}

.partner .btn-bl-bk {
  width: 100%;
}

.partner .btn-bl-bk a {
  width: 100%;
}

.partner .info > p {
  text-align: center;
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.partner .info > p b {
  display: block;
  margin-bottom: 1rem;
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.8rem;
  font-family: var(--kr);
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

/* ================= 3. 제품소개 공통 ================== */

.ruckus .feature .f-list .tit,
.product-info.ruckus .sc-tit,
.aruba .feature .f-list .tit,
.product-info.aruba .sc-tit,
.product-info .axgate .sc-tit {
  border-left: 0.5rem solid transparent;
  border-image: linear-gradient(360deg, #FFC224, #f47920);
  border-image-slice: 1;
}

.product-info.saisei .sc-tit,
.product-info .wguard .sc-tit,
.wips .feature .f-list .tit,
.product-info.wips .sc-tit,
.integrated .feature .f-list .tit,
.product-info.integrated .sc-tit  {
  border-left: 0.5rem solid transparent;
  border-image: linear-gradient(360deg, #FF5C00, #E00408);
  border-image-slice: 1;
}

.product-info {
  margin-top: 20rem;
}

.product-info .governing {
  overflow: hidden;
  width: 100%;
  padding: 16rem 0;
}

.product-info .governing .sub-inner {
  height: 100%;
}

.gv-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 12rem;
  height: fit-content;
  padding: 8rem 2rem;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(0.5rem);
}

.product-info .governing h3 {
  margin-bottom: 4rem;
  line-height: 1.4;
  font-size: 4rem;
  font-family: var(--kr);
  font-weight: 700;
  color: #fff;
}

.product-info .governing h3 span {
  display: block;
  margin-bottom: 1rem;
  line-height: 1.4;
  font-size: 3rem;
  font-family: var(--en);
  font-weight: 500;
  color: #fff;
}

.product-info .governing h3 + p {
  line-height: 1.4;
  font-size: 1.8rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #fff;
}

.product-info .governing .content::before {
  content: "";
  position: relative;
  display: block;
  width: 0.1rem;
  height: 8rem;
  margin-left: 50%;
  margin-bottom: 4rem;
  background: #fff;
  opacity: 0.6;
}

.product-info .governing .content p,
.product-info .governing2 .content p  {
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
}

.product-info .governing .content p:first-child {
  margin-bottom: 4rem;
}

.feature .f-list:not(:last-child) {
  margin-bottom: 16rem;
}

.feature .f-list .bg {
  width: 100%;
  height: 32rem;
  margin-bottom: 4rem;
}

.feature .f-list .tit,
.product-info .sc-tit {
  padding: 0.6rem 1.2rem;
  margin-bottom: 4rem;
  line-height: 1.4;
  word-break: keep-all;
  font-size: 2.4rem;
  font-family: var(--kr);
  font-weight: 700;
  color: #212121;
  border-left: 0.5rem solid transparent;
  border-image: linear-gradient(360deg, #58C0DA, #1779EC);
  border-image-slice: 1;
}

.feature .f-list .tit + p {
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.spec .table-wrap {
  overflow: auto;
  height: fit-content;
  margin-bottom: 4rem;
  padding: 1rem 0;
}

.spec table {
  width: fit-content;
  width: 100%;
}

.spec thead tr {
  background: var(--wh-main-color);
}

.spec thead th {
  padding: 2rem 0;
  word-break: keep-all;
  line-height: 1.4;
  font-size: 2rem;
  font-family: var(--en);
  font-weight: 500;
  color: #fff;
}

.spec tbody tr:not(:first-child),
.spec tbody tr:first-child td:first-child {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.spec td:not(:last-child) {
  border-right: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.spec img {
  width: 100%;
  max-width: 18rem;
} 


.spec td {
  padding: 2rem;
  vertical-align: middle;
  line-height: 1.4;
  text-align: center;
  word-break: keep-all;
  font-size: 1.6rem;
  font-family: var(--en);
  font-weight: 500;
  color: #212121;
}

.spec td p {
  font-size: 1.6rem;
  font-weight: 400;
  color: #212121;
}

.spec .gray {
  background: #ededed;
}

.spec .orange {
  background: #F47920;
}

.spec .red {
  background: #A82D2E;
}

.spec .blue {
  background: var(--wh-main-color);
}

.spec .p-name {
  padding: 0.6rem 1rem;
  border-radius: 4rem;
  word-break: keep-all;
  font-size: 1.8rem;
  font-family: var(--en);
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  background: #CBDBEE;
}

.spec .sub-inner > p {
  text-align: right;
}

.spec .sub-inner > p em {
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 300;
  font-style: normal;
  color: rgba(0, 0, 0, 0.6);
}

.spec .swiper-slide {
  padding: 1rem;
  box-sizing: border-box;
}

.spec .item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 32rem;
  padding: 3.2rem;
  border-radius: 1rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.spec .item:hover {
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
  border: 0;
}

.spec .spec-slide img {
  display: block;
  max-width: 29rem;
  margin: 0 auto;
}

.spec .spec-slide p {
  text-align: center;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 700;
  color: #212121;
}

.spec .spec-slide li {
  width: fit-content;
  margin: 0 auto;
  text-align: center;
  line-height: 1.5;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 500;
  color: #212121;
}

.spec .spec-slide li::before {
  content: "";
  position: absolute;
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: -3rem;
  background: url(../../public/images/product/spec_check.svg)no-repeat center / 1.6rem 1.6rem;
}

.spec .swiper-button-prev,
.spec .swiper-button-next {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  backdrop-filter: blur(2rem);
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
}

.spec .swiper-button-prev:hover,
.spec .swiper-button-next:hover {
  background: var(--wh-main-color);
  transition: all 0.5s ease;
}

.spec .swiper-button-prev,
.spec .swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 4px);
}

.spec .swiper-button-next,
.spec .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 4px);
}

.spec .swiper-button-prev:after,
.spec .swiper-button-next:after {
  display: block;
  width: 60%;
  height: 60%;
}

.spec .swiper-button-prev:after {
  background: url(../../public/images/main/arrow_left_bk.svg)no-repeat center;
}
.spec .swiper-button-prev:hover:after {
  background: url(../../public/images/main/arrow_left_wh.svg)no-repeat center;
}

.spec .swiper-button-next:after {
  background: url(../../public/images/main/arrow_right_bk.svg)no-repeat center;
}
.spec .swiper-button-next:hover:after {
  background: url(../../public/images/main/arrow_right_wh.svg)no-repeat center;
}


.spec .swiper-button-prev:after,
.spec .swiper-rtl .swiper-button-next:after,
.spec .swiper-button-next:after,
.spec .swiper-rtl .swiper-button-prev:after {
  content: "";
}

.spec li {
  font-size: 1.6rem;
  font-family: var(--en);
  font-weight: 400;
  color: #222222;
}

.link .content {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.link-item {
  width: 100%;
  max-width: 38rem;
  height: 56rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
}


.link-item::before {
  content: "";
  position: relative;
  left: 0;
  display: block;
  width: 0;
  height: 0.5rem;
  background: #F47920;
}

.link-item:hover:before {
  width: 100%;
  max-width: 38rem;
  transition: all 0.5s ease;
}

.link-item .wrap{
  height: 100%;
  padding: 6rem 4rem;
}

.link-item .inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.link-item .tit {
  margin: 2rem 0;
  word-break: keep-all;
  text-align: center;
  line-height: 1.4;
  font-size: 2rem;
  font-family: var(--en);
  font-weight: 700;
  color: #212121;
}

.link-item .txt {
  margin-bottom: 3.2rem;
  word-break: keep-all;
  text-align: center;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--en);
  font-weight: 400;
  color: #222;
}

.link-item a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.4rem 2rem;
  border-radius: 5rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  line-height: 6rem;
  font-size: 1.8rem;
  font-family: var(--kr);
  font-weight: 500;
  color: #222;
}

.link-item a:hover,
.link-item:hover a {
  color: #fff;
  border: 0;
  background: #F47920;
  transition: all 0.5s ease;
}

.link-item a span {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background: url(../../public/images/main/arrow_right_bk.svg)no-repeat center /100% 100%;
}

.link-item a:hover span,
.link-item:hover a span {
  background: url(../../public/images/main/arrow_right_wh.svg)no-repeat center /100% 100%;
  transition: all 0.5s ease;
}

.governing2 {
  overflow: hidden;
  width: 100vw;
  height: 96rem;
}

.aruba .governing2,
.axgate .governing2 {
  background: url(../../public/images/product/gv_orange.png)no-repeat center bottom 32rem / 100% 50%;
}
.coreEdge .governing2,
.wguard .governing2 {
  background: url(../../public/images/product/gv_gray.png)no-repeat center bottom 32rem / 100% 50%;
}
.grandstream .governing2 {
  background: url(../../public/images/product/gv_blue.png)no-repeat center bottom 32rem / 100% 50%;
}
.wips .governing2,
.integrated .governing2 {
  background: url(../../public/images/product/gv_red.png)no-repeat center bottom 32rem / 100% 50%;
}

.governing2 > * {
  position: absolute;
}

.governing2 > span {
  left: 0;
  width: 0;
  height: inherit;
  background: #fff;
  animation: fade-out 1s ease-in;
}

@keyframes fade-out {
  0% {
    width: 100%;
    transition: all 0.5s ease;
  }
  100% {
    width: 0;
    transition: all 0.5s ease;
  }
}

.governing2 .product-img {
  display: none;
}

.coreEdge .governing2 .product-img,
.Wlan .aruba .governing2 .product-img {
  display: initial;
  margin-top: 30rem;
}

.governing2 .sub-inner {
  height: auto;
}

.governing2 .title img {
  height: 5rem;
}

.governing2 .title span {
  display: block;
  text-align: center;
}

.governing2 .title h3 {
  margin-bottom: 2rem;
  line-height: 1.4;
  text-align: center;
  font-size: 3.2rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #222222;
}

.governing2 .title p {
  word-break: keep-all;
  line-height: 1.4;
  text-align: center;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #222;
}

.governing2 .content {
  margin-top: 10rem;
}

.governing2 .content li {
  margin-bottom: 2rem;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, .8);
  backdrop-filter: blur(0.5rem);
}

.governing2 .content li > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
}

.governing2 .content li > div p {
  margin-bottom: 0;
  line-height: 1.4;
  text-align: left;
  word-break: keep-all;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #212121;
}

.governing2 .content .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background: #f47920;
}

.governing2 .content .icon span {
  display: block;
  width: 5rem;
  height: 5rem;
}

.governing2 .content li > p {
  word-break: keep-all;
  line-height: 1.4;
  text-align: left;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #222;
}

.governing2 .product-img img {
  width: 100%;
}

.function ul {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.function li {
  cursor: pointer;;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40rem;
  padding: 3rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.function li > span:nth-child(1) {
  position: absolute;
  border-top: 0.5rem solid #F47920;
  top: 0;
  left: 0;
  right: 100%;
  transition: 0.1s;
  transition-delay: 0.3s
}
.function li:hover > span:nth-child(1) {
  right: 0%;
  transition-delay: 0s;
}

.function li > span:nth-child(2) {
  position: absolute;
  border-right: 0.5rem solid #F47920;
  top: 0;
  right: 0;
  bottom: 100%;
  transition: 0.1s;
  transition-delay: 0.2s;
}
.function li:hover > span:nth-child(2) {
  bottom: 0%;
  transition-delay: 0.1s;
}

.function li > span:nth-child(3) {
  position: absolute;
  border-bottom: 0.5rem solid #F47920;
  bottom: 0;
  left: 100%;
  right: 0;
  transition: 0.1s;
  transition-delay: 0.1s;
}
.function li:hover > span:nth-child(3) {
  left: 0%;
  transition-delay: 0.2s;
}

.function li > span:nth-child(4) {
  position: absolute;
  border-left: 0.5rem solid #F47920;
  top: 100%;
  left: 0;
  bottom: 0;
  transition-delay: 0s;
}
.function li:hover > span:nth-child(4) {
  top: 0%;
  transition-delay: 0.3s;
}

.function .wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.function .wrap > div {
  text-align: center;
}

.function .wrap span {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin-bottom: 4rem;
  border-radius: 50%;
  line-height: 5rem;
  text-align: center;
  font-size: 2rem;
  font-family: var(--en);
  font-weight: 600;
  color: #fff;
  background: #f47920;
}

.function .wrap .tit {
  margin-bottom: 2rem;
  word-break: keep-all;
  line-height: 1.4;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #212121;
}

.function .wrap .txt {
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #222;
}

.function .wrap img {
  width: 7rem;
  height: 7rem;
}

/* ================= 3-1. ipt ================== */
.Ipt .governing .content p {
  color: rgba(255, 255, 255, 0.8);
}

.callServer.product-info .governing {
  background: url(../../public/images/product/ipt/callserver_governing.jpg)no-repeat right 30% center / cover;
}

.ipPhone.product-info .governing {
  background: url(../../public/images/product/ipt/ipp_governing.jpg)no-repeat right 50% center / cover;
}

.callServer .f-list:nth-child(1) .bg {
  background: url(../../public/images/product/ipt/cs_feature1.jpg)no-repeat center / cover;
}

.callServer .f-list:nth-child(2) .bg {
  background: url(../../public/images/product/ipt/cs_feature2.jpg)no-repeat center / cover;
}

.callServer .f-list:nth-child(3) .bg {
  background: url(../../public/images/product/ipt/cs_feature3.jpg)no-repeat center / cover;
}

.callServer .f-list:nth-child(4) .bg {
  background: url(../../public/images/product/ipt/cs_feature4.jpg)no-repeat center / cover;
}

.callServer .spec td:not(:last-child) {
  border-right: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.callServer .spec tr:nth-child(2) td:nth-child(2) {
  border: 0;
}

.mediaGate .f-list:nth-child(1) .bg {
  background: url(../../public/images/product/ipt/mg_feature1.png)no-repeat center / cover;
}

.mediaGate .f-list:nth-child(2) .bg {
  background: url(../../public/images/product/ipt/mg_feature2.jpg)no-repeat center / cover;
}

.mediaGate .f-list:nth-child(3) .bg {
  background: url(../../public/images/product/ipt/mg_feature3.jpg)no-repeat center / cover;
}

.mediaGate .f-list:nth-child(4) .bg {
  background: url(../../public/images/product/ipt/mg_feature4.jpg)no-repeat center / cover;
}

.mediaGate .f-list:nth-child(2) > div p:nth-child(2) {
  margin-bottom: 8rem;
}

.etc .graph {
  overflow: auto;
  padding-bottom: 32rem;
}

.etc .graph-wrap {
  width: 100%;
}

.etc .graph-wrap .bg {
  width: 90rem;
  height: 96rem;
  margin: 0 auto;
  background: url(../../public/images/product/ipt/ipt_m_diagram.png)no-repeat center / 100%;
}

.etc .graph-wrap > div:not(:last-child) {
  width: fit-content;
}

.etc .graph-wrap ul {
  position: absolute;
  padding: 2rem;
  border-radius: 1rem;
}

.etc .app,
.etc .device,
.etc .etc-product {
  transition: all 0.5s ease;
}

.etc .app {
  transform: translateY(42rem);
}

.etc .app ul {
  background: #E2EAF6;
}

.etc .device {
  transform: translate(73rem, 4rem);
}

.etc .device ul {
  background: #CEE8EA;
}

.etc .etc-product {
  transform: translate(73rem, 35rem);
}

.etc .etc-product ul {
  background: #E5E8F2;
}

.etc .graph-wrap ul li:not(:last-child) {
  margin-bottom: 1rem;
}

.etc .graph-wrap ul li > p {
  cursor: pointer;
  display: block;
  min-width: 12rem;
  height: fit-content;
  padding: 0 0.4rem;
  border-radius: 0.8rem;
  line-height: 4rem;
  text-align: center;
  word-break: keep-all;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 500;
  background: #fff;
}

.etc .app li > p {
  color: #1368CD;
}

.etc .device li > p {
  color: #008BAE;
}

.etc .etc-product li > p {
  color: #112672;
}

.etc .graph-wrap ul li > p:hover,
.etc .graph-wrap ul li > p:active {
  color: #fff;
}

.etc .app li > p:hover,
.etc .app li > p:active {
  background: #1368CD;
}

.etc .device li > p:hover,
.etc .device li > p:active {
  background: #008BAE;
}

.etc .etc-product li > p:hover,
.etc .etc-product li > p:active {
  background: #112672;
}

.etc .tool-tip {
  z-index: 3;
  position: absolute;
  display: none;
  width: 32rem;
  margin-top: -4rem;
  padding: 2rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2rem);
}

.etc .app .tool-tip {
  right: -32rem;
}

.etc .device .tool-tip,
.etc .etc-product .tool-tip {
  left: -32rem;
}

.etc .tool-tip::before {
  content: "";
  position: absolute;
  top: 16px;
  display: block;
  width: 0; 
  height: 0; 
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
}

.etc .app .tool-tip::before {
  left: -12px;
  border-right: 12px solid rgba(255, 255, 255, 1);
}

.etc .device .tool-tip::before,
.etc .etc-product .tool-tip::before {
  right: -12px;
  border-left: 12px solid rgba(255, 255, 255, 1);
}

.etc .btn-hover:hover + .tool-tip {
  display: block;
}

.etc .tool-tip p {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.etc .tool-tip strong {
  word-break: keep-all;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 700;
  color: #212121;
}

.etc .tool-tip span {
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 300;
  color: rgba(0, 0, 0, 0.6);
}

.ruckus .governing h3 .highlight {
  position: relative;
  display: inline-block;
  margin: 0;
  font-size: 4rem;
  font-family: var(--en);
  font-weight: 600;
  color: #212121;
}

.ruckus .governing .highlight:after {
  content:"";
  z-index:-1;
  position: absolute;
  bottom:0;
  left:0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 2rem;
  margin-top: 3.2rem;
  background: #ff9c52;
}

@keyframes line_path {
  0% {
    width: 0;
  }
  30% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

/* ================= 3-2. network ================== */
.Network .ruckus .governing {
  background: url(../../public/images/product/network/ruckus_gv_bg.jpg)no-repeat center / cover;
}

.Network .ruckus .governing h3 {
  text-align: center;
  font-family: var(--en);
  font-weight: 600;
}

.Network .ruckus .governing h3 span {
  margin: 1rem 0;
}

.Network .ruckus .governing .content > div {
  width: 34rem;
  height: 34rem;
  box-sizing: border-box;
  padding: 5rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(0.2rem);
}

.Network .ruckus .governing .content > div:not(:first-child) {
  margin-top: -5rem;
}

.Network .ruckus .governing .content .tit {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid rgba(255, 255, 255, .8);
  word-break: keep-all;
  font-size: 2rem;
  font-family: var(--en);
  font-weight: 500;
  color: #f47920;
}

.Network .ruckus .governing .content > div li {
  margin-bottom: 1rem;
  padding-left: 2rem;
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #fff;
}

.Network .ruckus .governing .content > div li:before {
  content: "-";
  position: absolute;
  display: block;
  width: fit-content;
  margin-left: -1.6rem;
  font-size: 1.6rem;
}

.Network .ruckus .spec tr:nth-child(2) {
  border-bottom: 0;
}

.ruckus .spec .check {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: auto;
  background: url(../../public/images/product/spec_check.svg)no-repeat center;
  filter: invert(100%) sepia(68%) saturate(2043%) hue-rotate(334deg) brightness(118%) contrast(100%);
}


.Network .aruba .governing2 li > div > p {
  text-align: left;
  font-size: 2rem;
  font-weight: 700;
}

.Network .aruba .governing2 li:first-child .icon span {
  background: url(../../public/images/product/network/aruba_point1.svg)no-repeat center;
}

.Network .aruba .governing2 li:nth-child(2) .icon span {
  background: url(../../public/images/product/network/aruba_point2.svg)no-repeat center;
}

.Network .aruba .governing2 li:last-child .icon span {
  background: url(../../public/images/product/network/aruba_point3.svg)no-repeat center;
}

.Network .coreEdge .governing2 .sub-inner {
  left: 0;
  right: 0;
  margin: 0 auto;
}

.coreEdge .governing2 .title span {
  line-height: 1.4;
  font-size: 5.6rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #222;
}

.coreEdge .line-up-sw .content {
  overflow: auto;
  width: 100%;

}
.coreEdge .line-up-sw .content img {
  width: 100%;
  min-width: 100rem;
  margin-bottom: 2rem;
}

.coreEdge .line-up-dev .content > ul {
  display: flex;
  flex-direction: column;
  row-gap: 10rem;
  padding-bottom: 16rem;
}

.coreEdge .line-up-dev .tit {
  width: 100%;
  margin-bottom: 4rem;
  border-radius: 6rem;
  word-break: keep-all;
  line-height: 6rem;
  text-align: center;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #fff;
}

.coreEdge .line-up-dev li:nth-child(1) .tit {
  background: #63B6CE;
}

.coreEdge .line-up-dev li:nth-child(2) .tit {
  background: #5082CC;
}

.coreEdge .line-up-dev li:nth-child(3) .tit {
  background: #2E6AB3;
}

.coreEdge .line-up-dev li:nth-child(4) .tit {
  background: #083E80;
}

.coreEdge .line-up-dev ul ul {
  padding: 2.4rem;
  border-radius: 1rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.16);
}

.coreEdge .line-up-dev ul ul::before {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  width: 0; 
  height: 0; 
  margin-top: -4rem;
  margin-left: -2rem;
  border-left: 2rem solid transparent;
  border-bottom: 2rem solid #fff;
  border-right: 2rem solid transparent;
}

.coreEdge .line-up-dev li li {
  padding-left: 1.6rem;
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #222;
}

.coreEdge .line-up-dev li li::before {
  content: "・";
  position: absolute;
  display: block;
  margin-left: -1rem;
  line-height: 1;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.6);
}

.coreEdge .line-up-dev li li:not(:last-child) {
  margin-bottom: 1.6rem;
}

.coreEdge .line-up-dev .content > div {
  width: 100%;
  padding: 2rem 6rem;
  border-radius: 10rem;
  border: 0.1rem solid rgba(46, 106, 179, 0.4);
  box-shadow: 0 0 1.2rem rgba(0, 127, 255, 0.2);
}

.coreEdge .line-up-dev .content > div::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  width: 5rem;
  height: 5rem;
  margin: -12rem auto 0 auto;
  background: url(../../public/images/product/network/arrow_bottom.svg) no-repeat center;
}

.coreEdge .line-up-dev .content > div p:first-child {
  margin-bottom: 2rem;
  word-break: keep-all;
  line-height: 1.4;
  text-align: center;
  font-size: 2.4rem;
  font-family: var(--kr);
  font-weight: 600;
  color: var(--wh-main-color);
}

.coreEdge .line-up-dev .content > div p:last-child {
  word-break: keep-all;
  line-height: 1.4;
  text-align: center;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #222;
}

.coreEdge .link-item::before {
  background: var(--wh-main-color);
}

.coreEdge .link-item a:hover,
.coreEdge .link-item:hover a {
  background: var(--wh-main-color);
}

.Network .grandstream .governing2 {
  background: url(../../public/images/product/gv_blue.png)no-repeat center bottom 0rem / 100% 75%;
}

.Network .grandstream .governing2 .title h3 {
  margin-top: 4rem;
  font-size: 2.8rem;
  font-weight: 700;
}

.Network .grandstream .governing2 .title h3 span {
  font-size: 3.2rem;
  font-weight: 700;
}

.Network .grandstream .governing2 .title p {
  word-break: keep-all;
}

.Network .grandstream .governing2 .title p br {
  display: none;
}

.Network .grandstream .governing2 .content ul {
  width: fit-content;
  margin: 0 auto;
}

.Network .grandstream .governing2 .content li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24rem;
  height: 24rem;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  border: 0.2rem solid #fff;
  line-height: 1.4;
  text-align: center;
  font-size: 2.4rem;
  font-family: var(--kr);
  font-weight: 700;
  color: #fff;
  background: rgba(0, 63, 126, 0.7);
  backdrop-filter: blur(0);
}

.Network .grandstream .governing2 .content li:not(:first-child) {
  margin-top: -4rem;
}

.Network .spec tr:first-child td:not(:first-child) {
  border-color: #fff;
}

.Network .spec .p-name {
  background: #FDE4D2;
}

.Network .spec tr:first-child p {
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 500;
  color: #fff;
}

.saisei .governing {
  padding: 16rem 0 0 0;
}

.saisei .governing .title img {
  height: 4rem;
  margin-bottom: 2rem;
}

.saisei .governing .tit {
  word-break: keep-all;
  line-height: 1.4;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #212121;
}

.saisei .governing .txt {
  color: #222;
}

.saisei .feature {
  padding: 16rem 0;
  background: #F9F3F3;
}

.saisei .feature .sc-tit {
  padding: 0;
  border: 0;
  text-align: center;
  font-size: 3rem;
}

.saisei .feature .content > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8rem;
}

.saisei .feature .content ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  padding: 2.4rem;
  border-radius: 18rem;
}

.saisei .feature .content > div:first-child ul {
  background: rgba(195, 56, 42, 0.2);
}

.saisei .feature .content > div:last-child ul {
  background: rgba(249, 138, 6, 0.2);
}

.saisei .feature .content > div:first-child .tit {
  font-size: 2.4rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #C3382A;
}

.saisei .feature .content > div:last-child .tit {
  font-size: 2.4rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #F98A24;
}

.saisei .feature .content li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24rem;
  height: 24rem;
  padding: 2rem;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.2);
}

.saisei .feature .content li:not(:first-child) {
  margin-top: -2rem;
}

.saisei .feature li p:first-child {
  margin-bottom: 1rem;
  line-height: 1.4;
  text-align: center;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 600;
}

.saisei .feature .content > div:first-child li p:first-child {
  color: #C3382A;
}

.saisei .feature .content > div:last-child li p:first-child {
  color: #F98A24;
}

.saisei .feature li p:last-child {
  word-break: keep-all;
  text-align: center;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}

.fc-toggle .detail-list {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}

.fc-toggle .detail-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  padding: 2.4rem;
  border-radius: 1rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.fc-toggle .detail-item button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  transition: all 0.5s ease-in;
}

.fc-toggle .detail-item button > div:first-child {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.fc-toggle .detail-item button p {
  display: inline-block;
  word-break: keep-all;
  line-height: 1.4;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #212121;
}

.fc-toggle .detail-item button span {
  margin-right: 1rem;
  word-break: keep-all;
  line-height: 1.4;
  font-size: 2rem;
  font-family: var(--en);
  font-weight: 600;
  color: #C3382A;
}

.fc-toggle .detail-item .arrow {
  width: 5rem;
  height: 5rem;
  background: url(../../public/images/product/network/toggle_btn.svg)no-repeat center;
  filter: invert(93%) sepia(0%) saturate(839%) hue-rotate(102deg) brightness(93%) contrast(83%);
}

.fc-toggle .detail-item button.active {
  padding-bottom: 3rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.fc-toggle .detail-item button.active .arrow {
  transform: rotate(180deg);
  filter: invert(25%) sepia(71%) saturate(3549%) hue-rotate(351deg) brightness(83%) contrast(80%);
}

.fc-toggle .detail-item > div {
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: all 0.5s ease-in;
}

.fc-toggle .detail-item button.active + div {
  margin-top: 3rem;
}

.fc-toggle .detail-item > div p {
  padding-left: 2rem;
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.fc-toggle .detail-item > div p::before {
  content: "-";
  position: absolute;
  display: none;
  margin-left: -2rem;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.fc-toggle .detail-item button.active + div p::before {
  display: block;
}

.fc-toggle .detail-item > div img {
  display: block;
  width: 80%;
  margin: 4rem auto 0 auto;
}

.effect .sc-txt {
  word-break: keep-all;
  text-align: center;
}

.effect .title {
  margin-bottom: 8rem;
}

.effect .content > ul {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.effect .content > ul > li {
  padding: 2rem 0;
  border-top: 0.2rem solid rgba(0, 0, 0, 0.4);
}

.effect .content li span {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-family: var(--en);
  font-weight: 600;
  color: #C3382A;
}

.effect .content li p {
  word-break: keep-all;
  line-height: 1.4;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #212121;
}

.effect ul ul {
  margin-top: 4rem;
}

.effect .content li li {
  margin-bottom: 2rem;
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.effect .content img {
  width: 100%;
}

/* ================= 3-3. wlan ================== */
.Wlan .ruckus .governing {
  background: url(../../public/images/product/wlan/ruckus_gv_bg.jpg)no-repeat top -3rem right -27rem / 300%;
}

.Wlan .ruckus .governing p:first-child {
  margin-bottom: 2rem;
  word-break: keep-all;
  text-align: center;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #222;
}

.Wlan .ruckus .governing p:last-child {
  text-align: center;
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #222;
}

.Wlan .ruckus .governing .sub-inner {
  margin: 16rem 1.6rem 0 1.6rem;
  padding: 3.2rem 2.4rem;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
}

.Wlan .ruckus .governing h3 {
  text-align: center;
  font-size: 4rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #212121;
}

.merit ul {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
}

.merit li {
  height: 100%;
  padding: 2rem;
  border-radius: 1rem;
  background: #fff;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.merit li > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.merit li div p {
  word-break: keep-all;
  line-height: 1.4;
  font-size: 2.4rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #212121;
}

.merit li > p {
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #222;
}

.merit .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background: #f47920;
}

.merit .icon span {
  display: block;
  width: 5rem;
  height: 5rem;
}

.merit li:nth-child(1) .icon span {
  background: url(../../public/images/product/wlan/ruckus_icon1.svg)no-repeat center;
}

.merit li:nth-child(2) .icon span {
  background: url(../../public/images/product/wlan/ruckus_icon2.svg)no-repeat center;
}

.merit li:nth-child(3) .icon span {
  background: url(../../public/images/product/wlan/ruckus_icon3.svg)no-repeat center;
}

.merit li:nth-child(4) .icon span {
  background: url(../../public/images/product/wlan/ruckus_icon4.svg)no-repeat center;
}

.merit li:nth-child(5) .icon span {
  background: url(../../public/images/product/wlan/ruckus_icon5.svg)no-repeat center;
}

.Wlan .ruckus .spec table {
  table-layout: fixed;
}

.Wlan .ruckus .spec .col_8 {
  width: 8rem;
}

.Wlan .ruckus .spec .col_12 {
  width: 12rem;
}

.Wlan .ruckus .spec .col_16 {
  width: 16rem;
}

.Wlan .ruckus .spec .col_48 {
  width: 48rem;
}

.Wlan .ruckus .spec td {
  height: 24rem;
}

.Wlan .ruckus .spec td .td-inner {
  display: flex;
  justify-content: center;
}

.Wlan .ruckus .spec td .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Wlan .ruckus .spec .td-inner .img {
  display: flex;
  align-items: center;
  width: 40%;
}

.Wlan .ruckus .spec .p-name {
  width: fit-content;
  margin-bottom: 1rem;
  background: #FDE4D2;
}

.Wlan .ruckus .spec tbody tr:first-child {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.Wlan .aruba .governing2 {
  height: 100vh;
  background: url(../../public/images/product/gv_orange.png)no-repeat center bottom / 100% 64%;
}

.Wlan .aruba .governing2 .title p {
  word-break: keep-all;
}

.Wlan .aruba .governing2 .title p br {
  display: none;
}

.Wlan .aruba .governing2 .content li .tit {
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
}

.aruba .merit div div {
  margin-bottom: 12rem;
}

.aruba .merit div div::before {
  content: "";
  display: block;
  width: 0.1rem;
  height: 10rem;
  margin: 0 auto 4rem auto;
  background: #f47920;
}

.aruba .merit div > p:first-child {
  margin-bottom: 2rem;
  word-break: keep-all;
  text-align: center;
  line-height: 1.4;
  font-size: 2.4rem;
  font-family: var(--kr);
  font-weight: 700;
  color: #212121;
}

.aruba .merit div > p:last-child {
  word-break: keep-all;
  text-align: center;
  line-height: 1.4;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 500;
  color: #212121;
}

.aruba .merit ul {
  align-items: center;
  row-gap: 0;
  padding: 2rem 0;
  background: url(../../public/images/product/wlan/merit_m_bg.png)no-repeat center / 100%;
}

.aruba .merit li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 32rem;
  height: 32rem;
  background: none;
  border: 0;
}

.aruba .merit li:nth-child(2) {
  margin: -3rem 0;
}

.aruba .merit li p {
  text-align: center;
  line-height: 1.4;
}

.aruba .merit li .tit {
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 700;
  color: #f47920;
}

.Wlan .aruba .spec .item {
  height: 48rem;
}

.Wlan .grandstream .governing2 {
  overflow: hidden;
  width: 100vw;
}

.Wlan .grandstream .governing2:nth-child(1) {
  height: 110rem;
  background: url(../../public/images/product/gv_blue.png) no-repeat center bottom / 100% 50%;
}

.Wlan .grandstream .governing2 .product-img {
  left: 0;
  right: 0;
  display: block;
  width: 90%;
  margin: 60rem auto 0 auto;
}

.Wlan .grandstream .governing2 .sub-inner {
  left: 0;
  right: 0;
  margin: 0 auto;
}

.Wlan .grandstream .governing2 .content p {
  text-align: center;
  line-height: 1.4;
}

.Wlan .grandstream .spec .blue p {
  font-size: 2.4rem;
  font-weight: 600;
  color: #fff;
}

.Wlan .grandstream .governing2:nth-child(3) {
  background: url(../../public/images/product/gv_gray.png) no-repeat center bottom / 100% 80%;
}

.Wlan .grandstream .governing2:nth-child(3) .product-img {
  margin-top: 50rem;
}

.Wlan .grandstream .governing2:nth-child(3) h3 {
  font-size: 4rem;
}

.Wlan .grandstream .governing2:nth-child(3) .title p {
  font-size: 1.6rem;
}

.Wlan .grandstream .governing2:nth-child(3) .title p br {
  display: none;
}

.product-info.Wlan .grandstream .governing2:nth-child(3) .content p {
  word-break: keep-all;
  line-height: 1.4;
  text-align: center;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #222;
}

.wlc ul {
  display: flex;
  flex-direction: column;
  row-gap: 8rem;
}

.wlc label {
  display: block;
  margin-bottom: 4rem;
  padding-bottom: 2rem;
  word-break: keep-all;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #222;
}

.wlc li:nth-child(1) label {
  background: url(../../public/images/product/wlan/label_bg1.svg)no-repeat left bottom / 100% auto;
}

.wlc li:nth-child(2) label {
  background: url(../../public/images/product/wlan/label_bg2.svg)no-repeat left bottom / 100% auto;
}

.wlc li:nth-child(3) label {
  background: url(../../public/images/product/wlan/label_bg3.svg)no-repeat left bottom / 100% auto;
}

.wlc li div {
  width: 100%;
  padding: 2rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  background: #fff;
}

.wlc li div p:nth-child(1) {
  margin-bottom: 4rem;
  text-align: right;
}

.wlc li div img {
  width: 6rem;
}

.wlc div p:nth-child(2) {
  text-align: left;
  line-height: 1.4;
  word-break: keep-all;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #222;
}


/* ================= 3-4. security ================== */
.firewall .governing2{
  background-size: 100% 36%;
  background-position: center bottom;
}

.firewall .governing2 .product-img {
  display: block;
  margin-top: 64rem;
}

.firewall .governing2 .title > span {
  margin-bottom: 8rem;
}

.firewall .governing2 .title h3,
.firewall .governing2 .title h3 span {
  font-size: 4rem;
  font-family: var(--en);
  font-weight: 700;
}

.firewall .governing2 .underline {
  display: inline-block;
  width: fit-content;
}

.firewall .governing2 .underline:after {
  width: 10rem;
  height: 3rem;
  margin-bottom: -1rem;
  background-size: 90%;
}

.firewall .governing2 .content .tit {
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600;
}

.firewall .governing2 .content .txt {
  text-align: center;
}

.firewall .function .inner-scroll {
  overflow: auto;
  height: 10rem;
  padding: 1rem;
}

.firewall .function li ul {
  row-gap: 1rem;
}

.firewall .function li li {
  width: initial;
  height: auto;
  padding: 0;
  margin-left: 1rem;
  border: 0;
  text-align: left;
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #222;
}

.firewall .function li li::before {
  content: "";
  position: absolute;
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  margin: 1rem 0 0 -1rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
}

.firewall .spec thead th {
  background: #f47920;
}

.firewall .spec .p-name {
  background: #FDE4D2;
}

.utm li {
  overflow: hidden;
  width: 100%;
  height: fit-content;
  border-radius: 1rem;
  border: 0.1rem solid;
}

.utm li:nth-child(odd) div:first-child::before {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  width: 0; 
  height: 0; 
  margin-top: 11rem;
  margin-left: -1.6rem;
  border-left: 1.6rem solid transparent;
  border-top: 1.6rem solid #ED1F24;
  border-right: 1.6rem solid transparent;
}

.utm li:nth-child(even) div:first-child::before {
  content: "";
  position: absolute;
  left: 50%;
  display: block;
  width: 0; 
  height: 0; 
  margin-top: 11rem;
  margin-left: -1.6rem;
  border-left: 1.6rem solid transparent;
  border-top: 1.6rem solid #95070B;
  border-right: 1.6rem solid transparent;
}

.utm li:not(:first-child) {
  margin-top: 2rem;
}

.utm li:nth-child(odd) {
  border-color: #ED1F24;
}

.utm li:nth-child(even) {
  border-color: #95070B;
}


.utm li div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  line-height: 1.4;
  text-align: center;
  font-size: 2rem;
  font-family: var(--en);
  font-weight: 700;
  color: #fff;
}

.utm li:nth-child(odd) div:first-child {
  background: #ED1F24;
}

.utm li:nth-child(even) div:first-child {
  background: #95070B;
}

.utm li div:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  line-height: 1.4;
  text-align: center;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 500;
  color: #222;
}

.wguard .spec thead th {
  background: #ED1F24;
}

.wguard .spec .p-name,
.wips .spec .p-name {
  background: #FBD2D3;
}

.wguard .spec .p-name em {
  display: inline-block;
  font-size: 1.4rem;
  font-family: var(--en);
  font-weight: 300;
  font-style: normal;
}

.wguard .spec .sub-heading {
  text-align: left;
  border-left: 0.1rem solid;
  border-right: 0.1rem solid;
  border-color: rgba(0, 0, 0, 0.2);
  color: #ED1F24;
}

.wguard .feature .f-list {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.wguard .feature .item {
  padding: 2rem;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0.4rem 0.4rem 1.2rem rgba(0, 0, 0, 0.2);
}

.wguard .feature .item .icon {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  min-width: 5rem;
  height: 5rem;
  min-height: 5rem;
  border-radius: 50%;
  background: #ED1F24;
}

.wguard .feature .icon span {
  display: block;
  width: 4rem;
  height: 4rem;
}

.wguard .feature .item:nth-child(1) .icon span {
  background: url(../../public/images/product/security/it_feature1.svg)no-repeat center / 100% 100%;
}
.wguard .feature .item:nth-child(2) .icon span {
  background: url(../../public/images/product/security/it_feature2.svg)no-repeat center / 100% 100%;
}
.wguard .feature .item:nth-child(3) .icon span {
  background: url(../../public/images/product/security/it_feature3.svg)no-repeat center / 100% 100%;
}
.wguard .feature .item:nth-child(4) .icon span {
  background: url(../../public/images/product/security/it_feature4.svg)no-repeat center / 100% 100%;
}
.wguard .feature .item:nth-child(5) .icon span {
  background: url(../../public/images/product/security/it_feature5.svg)no-repeat center / 100% 100%;
}

.wguard .feature .item p span {
  margin-right: 1rem;
  font-size: 2.4rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #ED1F24;
}

.wguard .feature .item p:nth-child(2) {
  float: left;
  line-height: 5rem;
  font-size: 2rem;
  font-family: var(--en);
  font-weight: 400;
  color: #4D4D4D;
}

.wguard .feature .item p:nth-child(3) {
  clear: both;
  margin-top: 10rem;
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.wguard .feature .cir-r {
  display: none;
}

.wguard .link-item img {
  width: 60%;
  margin: 0 auto;
}

.wguard .link-item::before,
.wguard .link-item a:hover,
.wguard .link-item:hover a {
  background: #ED1F24;
}

.wips .governing2 {
  height: 110rem;
}

.wips .governing2 h3 span {
  font-size: 3.2rem;
  font-family: var(--en);
}

.wips .governing2 .box1 > li {

  background: rgba(255, 255, 255, 0.8);
}

.wips .governing2 li li {
  padding: 0;
  margin: 0;
  background: none;
  box-shadow: none;
  backdrop-filter: none;
}

.wips .governing2 .box div {
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.wips .governing2 .box div p {
  line-height: 6rem;
  font-size: 2rem;
  font-family: var(--en);
  font-weight: 600;
  color: #212121;
}

.wips .governing2 .box ul {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.wips .governing2 .box li li {
  padding-left: 2rem;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #222;
}

.wips .governing2 .box li li::before,
.addition li li:before {
  content: "";
  position: absolute;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  margin: 1rem 0 0 -1.2rem;
  border-radius: 50%;
  background: #95070B;
}

.addition > .sub-inner > ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  padding: 1rem 0;
}

.addition > .sub-inner > ul > li {
  width: 100%;
  aspect-ratio: 1/1;
  padding: 2rem;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0 0 0.8rem rgba(113, 11, 16, 0.25);
}

.addition p {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
  word-break: keep-all;
  text-align: center;
  line-height: 1.4;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #95070B;
}

.addition ul ul {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.addition li {
  padding-left: 2rem;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 300;
  color: rgba(0, 0, 0, 0.8);
}

.addition li li:before {
  background: rgba(0, 0, 0, 0.4);
}

.wips .function li > span:nth-child(1),
.wips .function li > span:nth-child(2),
.wips .function li > span:nth-child(3),
.wips .function li > span:nth-child(4) {
  border-color: #ED1F24;
}

.wips .function .wrap > div {
  height: 100%;
}

.wips .function .wrap span {
  background: #95070B;
}

.wips .function .inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70%;
  margin: 0 auto;
}

.wips .function .inner img {
  width: 70%;
  height: auto;
}

.wips .spec:nth-child(4) thead tr {
  background: #95070B;
}

.wips .spec:nth-child(4) td p {
  text-align: left;
}

.wips .spec:nth-child(4) tbody tr:nth-child(1) td:nth-child(1) p {
  text-align: center;
}

.wips .spec:nth-child(4) tbody tr:nth-child(1) td:nth-child(2)~td {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.wips .spec:nth-child(5) tbody {
  border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.wips .spec:nth-child(5) tr:last-child td:last-child ul {
  display: flex;
  column-gap: 2rem;
}

.wips .spec:nth-child(5) li {
  margin-bottom: 1rem;
  padding-left: 2rem;
  text-align: left;
}

.wips .spec:nth-child(5) tr:nth-child(3) li::before {
  content: "";
  position: absolute;
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  margin: 0.8rem 0 0 -1.6rem;
  border-radius: 50%;
  background: #95070B;
}

.wips .spec p span {
  font-size: 1.6rem;
  font-family: var(--en);
  font-weight: 600;
  color: #212121;
}

.wips .link-item::before,
.wips .link-item a:hover,
.wips .link-item:hover a {
  background: #ED1F24; 
}

.integrated .governing2  {
  background-position: center bottom 20rem;
}

.integrated .governing2 .content img {
  display: block;
  width: 100%;
}

.integrated .governing2 .title h3 span {
  font-size: 3.2rem;
  font-family: var(--en);
  font-weight: 600;
  color: #222;
}

.integrated .function li > span:nth-child(1),
.integrated .function li > span:nth-child(2),
.integrated .function li > span:nth-child(3),
.integrated .function li > span:nth-child(4) {
  border-color: #ED1F24;
}

.integrated .function .wrap span {
  background: #95070B;
}

.integrated .function .inner p {
  word-break: keep-all;
  line-height: 1.4;
  font-size: 1.6rem;
  font-family: var(--kr);
  font-weight: 400;
  color: #222;
}


.integrated .merit .icon {
  min-width: 7rem;
  min-height: 7rem;
  background: #95070B;
}

.integrated .merit li:nth-child(1) .icon span {
  background: url(../../public/images/product/security/integrated_icon1.svg)no-repeat center;
}

.integrated .merit li:nth-child(2) .icon span {
  background: url(../../public/images/product/security/integrated_icon2.svg)no-repeat center;
}

.integrated .merit li:nth-child(3) .icon span {
  background: url(../../public/images/product/security/integrated_icon3.svg)no-repeat center;
}

.integrated .merit li:nth-child(4) .icon span {
  background: url(../../public/images/product/security/integrated_icon4.svg)no-repeat center;
}

.integrated .merit li:nth-child(5) .icon span {
  background: url(../../public/images/product/security/integrated_icon5.svg)no-repeat center;
}

.integrated .merit li:nth-child(6) .icon span {
  background: url(../../public/images/product/security/integrated_icon6.svg)no-repeat center;
}

.integrated .merit li:nth-child(7) .icon span {
  background: url(../../public/images/product/security/integrated_icon7.svg)no-repeat center;
}


.certify ul {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 2rem;
}

.certify li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30rem;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
}

.certify li img {
  width: 16rem;
  margin-bottom: 4rem;
}

.certify li p {
  line-height: 1.4;
  word-break: keep-all;
  font-size: 2rem;
  font-family: var(--kr);
  font-weight: 600;
  color: #222;
}



/* ================= 3-5. R&D ================== */









/* ================= 4-1. 견적문의 ================== */
.QuoteInquiry .customer .title {
  display: flex;
  flex-direction: column;
  row-gap: 6rem;
  margin-bottom: 10rem;
}

.QuoteInquiry .customer .sc-tit {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 3rem;
}

.QuoteInquiry .required {
  text-align: center;
}

.QuoteInquiry .required i {
  font-size: 1.6rem;
  font-weight: 500;
  font-style: normal;
  color: #d80d0d;
}

.QuoteInquiry .info-wrap dl {
  display: flex;
  margin-bottom: 1rem;
}

.QuoteInquiry .info-wrap dt {
  width: 30%;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.6);
}

.QuoteInquiry .info-wrap dd {
  width: 70%;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.8);
}

.form-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.form-item {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}

.item-wrap {
  position: relative;
  margin-bottom: 2.4rem;
}

.QuoteInquiry .customer .form-item .item-wrap .title {
  width: fit-content;
}

.form-item .item-wrap .title {
  position: absolute;
  top: 2.4rem;
  left: 0;
  transform-origin: left;
  transition: .25s;
}

.form-item .item-wrap .title label {
  width: fit-content;
  font-size: 1.6rem;
  line-height: 1.75;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.form-item .title.has-content {
  transform: scale(0.8) translateY(-3.6rem);
}

.form-item .item-wrap .content > * {
  width: 100%;
}

.item-wrap .content input[type="text"],
.item-wrap .content input[type="email"],
.item-wrap .content select,
.item-wrap .content textarea {
  height: 6.7rem;
  padding-top: 1.6rem;
  border: 0;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.4);
  font-size: 1.6rem;
  background: transparent;
}

.item-wrap .content {
  position: relative;
}

.item-wrap .content textarea {
  height: 13rem;
  padding-top: 2.4rem;
}

.item-wrap textarea {
  height: 5rem;
  line-height: 1.57;
  resize: none;
}

.check-box {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-top: 1.5rem;
}

.check-box input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.check-box input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

.check-box span {
  position: relative;
  display: inline-block;
  flex: none;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
}

.check-box span::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0.2rem;
  background: url(../../public/images/support/check_default.svg);
  background-size: cover;
}

.check-box span::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 0.2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background: url(../../public/images/support/check_active.svg); 
  background-size: cover;
}

.check-box.default span::after {
  display: none;
}

.check-box.active span::after {
  display: block;
}

.check-box a {
  flex: 0 1 auto;
  display: inline-block;
  padding-left: 1rem;
  font-size: 1.5rem;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.6);
  align-items: center;
  word-break: break-all;
  cursor: pointer;
}

.btn-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding-top: 4rem;
  overflow: hidden;
  text-align: center;
}

.apply-form .btn-group input {
  width: 100%;
  height: 5rem;
  box-sizing: border-box;
  border: 0.1rem solid var(--wh-main-color);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.75;
  color: var(--wh-main-color);
  background: none;
}

.apply-form .btn-group input:hover {
  color: #fff;
  background: var(--wh-main-color);
}

/* ================= 4-2. 오시는길 ================== */
.map .kkomap_btn {
  z-index: 2;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.map .kkomap_btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 0.4rem solid #fff;
  background: #3396ff;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;
}

.map .kkomap_btn a:hover {
  background: #0b5fb8;
  scale: 1.1;
}

.map .kkomap_btn img {
  width: 3.2rem;
  height: 3.2rem;
}

.address .title h3 {
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 3.2rem;
  font-family: var(--kr);
  font-weight: 700;
  color: #212121;
}

.address .wrap:nth-child(2) {
  margin-bottom: 16rem;
}

.address .map {
  height: 40rem;
  margin-bottom: 4rem;
}

.address-info > p {
  margin-bottom: 4rem;
  font-size: 2.4rem;
  font-family: var(--en);
  font-weight: 600;
  color: #222;
}

.address-info li {
  display: flex;
  margin: 2rem 0;
}

.address-info label {
  display: block;
  width: 20%;
  font-size: 1.8rem;
  font-family: var(--kr);
  font-weight: 700;
  color: #222;
}

.address-info label + p {
  display: block;
  width: 80%;
  line-height: 1.4;
  font-size: 1.8rem;
  font-family: var(--kr);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}

.address-info label + p span {
  line-height: 1.4;
  font-size: 1.8rem;
  font-family: var(--kr);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

@media all and (min-width:48em) {
  .sub-tab .sub-inner {
    margin: 0 auto;
  }

  .contents .main-btn a {
    width: 40rem;
  }

  .contents .main-btn span {
    margin-right: 18rem;
  }

  .CompanyIntro section:nth-child(2) {
    margin: 16rem 0;
  }

  .about .sub-inner {
    padding: 0;
  }

  .about .bg {
    overflow: hidden;
    width: 100vw;
    height: 50rem;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
                url(../../public/images/company/about_bg.jpg)no-repeat center / cover;
  }

  .about .bg span {
    position: absolute;
    display: block;
    width: 0;
    height: 50rem;
    background: #fff;
  }

  .about .bg span:nth-child(1) {
    left: 0;
    animation: fade_l 1s ease-in;
  }

  @keyframes fade_l {
    0% {
      width: 50%;
    }
    100% {
      width: 0;
    }
  }

  .about .bg span:nth-child(2) {
    right: 0;
    transform: translateX(calc(100% / 2) - 10%);
    animation: fade_r 1s ease-in;
  }

  @keyframes fade_r {
    0% {
      width: 50%;
      transform: translateX(0);
    }
    100% {
      width: 0;
      transform: translateX(calc(100% / 2) - 10%);
    }
  }

  .about .content {
    position: relative;
    display: flex;
    width: 95%;
    margin-left: 5%;
    margin-top: -10rem;
    padding: 4rem;
    box-sizing: border-box;
    background: #ffffff;
  }

  .about .content .sc-tit {
    width: 50%;
    text-align: left;
  }

  .about .content > div {
    width: 50%;
  }

  .about .content .sc-txt {
    text-align: justify;
    letter-spacing: -0.1rem;
  }

  .value {
    padding: 12rem 0;
    background: url(../../public/images/company/value_bg_top.png)no-repeat center top / 100%,
                url(../../public/images/company/value_bg_bottom.png)no-repeat center bottom / 100%;
  }

  .value .sc-tit {
    text-align: left;
  }

  .value .content {
    padding-bottom: 16rem;
  }

  .value .diagram {
    width: 50rem;
    height: 50rem;
  }

  .value .value-list li {
    width: 20rem;
    height: 20rem;
    padding: 4rem;
  }

  .value .value-list li:nth-child(1) {
    transform: translate(-8rem, 0rem);
  }

  .value .value-list li:nth-child(2) {
    transform: translate(38rem, 0rem);
  }

  .value .value-list li:nth-child(3) {
    transform: translate(15rem, 42rem);
  }

  .value .value-list div:nth-child(1) {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  .value .value-core img {
    display: block;
    width: 6rem;
    margin-top: 2rem;
  }

  @keyframes cir_1 {
    0% {
      transform: rotate(120deg) translateY(25rem);
    }
  
    100% {
      transform: rotate(240deg) translateY(25rem);
    }
  }
  
  @keyframes cir_2 {
    0% {
      transform: rotate(0deg) translateY(25rem);
    }
  
    100% {
      transform: rotate(120deg) translateY(25rem);
    }
  }
  
  @keyframes cir_3 {
    0% {
      transform: rotate(240deg) translateY(25rem);
    }
  
    100% {
      transform: rotate(360deg) translateY(25rem);
    }
  }

  .history .sc-tit {
    margin-bottom: 10rem;
  }

  .history .h-list::after {
    height: calc(100% - 30rem);
  }

  .history .h-list.outspread::after {
    height: calc(100% - 22rem);
  }

  .history .h-list .item.left {
    text-align: left;
  }

  .history .h-list .item.right {
    text-align: right;
  }

  .history .h-list .item::after {
    top: 50%;
  }

  .history .h-list .item div {
    width: 45%;
    text-align: left;
  }

  .history .h-list strong {
    font-size: 2.4rem;
    font-weight: 600;
  }

  .history .h-list p {
    text-align: left;
    font-size: 1.8rem;
  }


  .org-list {
    width: 60%;
    margin: -8rem auto 0 auto;
  }

  .org-list div p {
    font-size: 2.4rem;
  }

  .org-list div i {
    font-size: 1.6rem;
  }

  .org-list ul li p {
    font-size: 1.8rem;
  }

  .org-list ul li i {
    font-size: 1.6rem;
  }

  .tit br {
    display: block;
  }

  .talent-list {
    justify-content: space-between;
  }

  .talent-list li {
    width: 48%;
  }

  .benefits li {
    width: 20rem;
  }

  .benefits li img {
    width: 10rem;
  }



  .governing .tit {
    font-size: 6.4rem;
  }

  .w-diagram {
    padding: 20rem 0;
    background: url(../../public/images/company/value_bg_top.png)no-repeat center top / 100%,
                url(../../public/images/company/value_bg_bottom.png)no-repeat center bottom / 100%;
  }

  .w-diagram .sub-inner::before {
    height: 1.7rem;
    margin: 0 auto;
  }

  @keyframes ver_line {
    0% {
      height: 0;
    }
    40% {
      height: 107rem;
    }
    100% {
      height: 107rem;
    }
  }

  .w-diagram .function .cir_b {
    margin: 4rem auto;
  }

  .w-diagram .function .cir_b,
  .w-diagram .cir_b .sol {
    width: 50%;
  }

  .w-diagram .cir_b > div {
    width: 30%;
  }

  .w-diagram .keyword {
    padding-top: 14rem;
  }  

  .w-diagram .keyword ul {
    flex-direction: row;
  }

  .w-diagram .keyword li:not(:first-child) {
    margin-top: 0;
  }

  .WlanBuilding .governing .txt {
    font-size: 2.4rem;
    color: rgba(0, 0, 0, 0.8);
  }

  .governing .txt br {
    display: block;
  }

  .business-info .intro .tit {
    margin-bottom: 4
    rem;
    font-size: 4rem;
  }

  .business-info .intro .txt {
    line-height: 150%;
    font-size: 1.7rem;
  }

  .business-info .intro .txt br {
    display: block;
  }

  .business-info .info-list > li:not(:first-child) {
    margin-top: 16rem;
  }

  .business-info .info-list > li .bg {
    overflow: hidden;
    width: 100%;
    height: 48rem;
  }

  .business-info .info-list > li .bg span {
    display: block;
    width: 100%;
    height: 100%;
  }

  .business-info .info-list > li .con {
    position: relative;
    width: 90%;
    margin-left: 10%;
    margin-top: -10rem;
    padding: 5rem 0 0 6rem;
  }

  .business-info .info-list > li .con .num {
    margin-bottom: -0.8rem;
    font-size: 13rem;
  }

  .business-info .info-list > li .con .tit {
    font-size: 2.7rem;
  }

  .business-info .info-list > li .con .tit span {
    font-size: 1.8rem;
  }

  .business-info .info-list > li .con .txt {
    margin-bottom: 2rem;
    font-size: 1.7rem;
  }

  .business-info .info-list > li .con li {
    font-size: 1.4rem;
  }

  .business-info .proc-list {
    margin-left: 4rem;
    padding-left: 6rem;
  }

  .business-info .proc-list > li::after {
    margin-top: -12%;
    margin-left: 2rem;
  }

  .business-info .svc-list {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 2rem;
  }

  .business-info .svc-list > li.col-5 {
    width: calc(50% - 1rem);
  }

  .feature .f-list .tit,
  .product-info .sc-tit {
    font-size: 3.2rem;
  }

  .process .swiper div {
    height: auto;
  }

  .process .item-inner {
    display: flex;
    align-items: center;
  }

  .process .item-inner > div {
    width: 50%;
  }

  .process .col .tit {
    margin: 0;
  }

  .process .col .area {
    display: none;
    width: 24rem;
    padding-right: 3rem;
    box-sizing: border-box;
  }

  .process .col.active .area {
    position: absolute;
    display: block;
    margin-left: 40rem;
    transition: all 0.5s ease;
  }

  .process .col:not(:last-child) .area::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 3rem;
    height: 3rem;
    margin: auto 0;
    background: url(../../public/images/main/arrow_right_bk.svg)no-repeat center / 60%;
  }

  .service .svc-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .service .svc-list .item {
    width: 49%;
  }

  .trend .title {
    display: flex;
    justify-content: space-between;
  }

  .trend .sc-txt {
    width: 60%;
    margin: 0;
  }

  .trend .area > div {
    width: 90%;
  }

  .trend .area > div:last-child {
    margin-left: 10%;
  }

  .trend .area div > p:nth-child(2) {
    margin-bottom: 8rem;
  }

  .trend .bg + div {
    padding: 4rem 6rem;
  }

  .trend .area img {
    width: 6rem;
    height: 6rem;
  }

  .Maintenance .governing p,
  .Maintenance .governing span {
    font-size: 3.2rem;
  }

  .inquiry_banner .sc-txt {
    font-size: 1.6rem;
  }

  .governing2 .sub-inner {
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .governing2 li {
    margin: 0 auto;
  }

  .governing2 .content li {
    width: 40rem;
  }

  .coreEdge .governing2 {
    height: 110rem;
    background: url(../../public/images/product/gv_gray.png)no-repeat center bottom 8rem / 100% 50%;
  }

  .Network .aruba .governing2 {
    background: url(../../public/images/product/gv_orange.png)no-repeat center bottom 0 / 100% 80%;
  }

  .function ul {
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 2rem;
    row-gap: 2rem;
    justify-content: flex-start;
  }

  .function li {
    width: calc((100% - 2rem) / 2);
  }

  .link .content {
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 2rem;
  }

  .link-item {
    width: calc((100% - 2rem) / 2);
  }

  .Network .ruckus .governing h3 span {
    display: inline-block;
    margin: 0;
  }

  .Network .ruckus .governing .content > div {
    max-width: 40rem;
    max-height: 40rem;
    margin: 0 auto;
  }

  .Network .grandstream .governing2 .title p br {
    display: block;
  }

  .Network .grandstream .governing2 .content ul {
    display: flex;
  }

  .Network .grandstream .governing2 .content li:not(:first-child) {
    margin-top: 0;
    margin-left: -4rem;
  }

  .Wlan .aruba .governing2 .title img {
    height: 6rem;
  }

  .Wlan .aruba .governing2 .title h3 {
    font-size: 4rem;
  }

  .Wlan .aruba .governing2 .title p {
    font-size: 2rem;
  }

  .Wlan .aruba .governing2 .title p br {
    display: block;
  }

  .coreEdge .line-up-dev .content > ul {
    width: 70%;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .saisei .feature .content > div {
    row-gap: 4rem;
  }

  .saisei .feature .content .tit {
    order: 2;
  }

  .saisei .feature .content ul {
    order: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 51.2rem;
    height: 47.8rem;
    margin-top: 0;
    border-radius: 0;
  }

  .saisei .feature .content > div:first-child ul {
    background: url(../../public/images/product/network/saisei_f_bg1.png)no-repeat center / 100%;
  }

  .saisei .feature .content > div:last-child ul {
    background: url(../../public/images/product/network/saisei_f_bg2.png)no-repeat center / 100%;
  }

  .saisei .feature .content li {
    position: absolute;
  }

  .saisei .feature .content li:not(:first-child) {
    margin-top: 0;
  }

  .saisei .feature .content li:first-child {
    transform: translate(-11rem,10rem);
  }
  .saisei .feature .content li:nth-child(2) {
    transform: translate(0.5rem,-9.5rem);
  }
  .saisei .feature .content li:last-child {
    transform: translate(11rem,10rem);
  }

  .effect .title {
    display: flex;
    justify-content: space-between;
  }

  .effect .sc-txt {
    width: 70%;
    text-align: left;
  }

  .Wlan .ruckus .governing {
    background: url(../../public/images/product/wlan/ruckus_gv_bg.jpg)no-repeat top -3rem right -27rem / 200%;
  }

  .Wlan .ruckus .governing .sub-inner {
    margin-top: 30rem;
  }

  .Wlan .ruckus .governing h3 br {
    display: none;
  }

  .Wlan .ruckus .governing .sub-inner {
    padding: 6rem 10rem;
  }

  .Wlan .ruckus .governing p:first-child {
    font-size: 2rem;
  }

  .merit ul {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 2rem;
  }

  .merit li {
    width: calc((100% - 2rem) / 2);
  }

  .aruba .merit div > p:first-child {
    font-size: 4rem;
  }

  .aruba .merit div > p:last-child {
    font-size: 2.4rem;
  }

  .aruba .merit ul {
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    margin: 0 auto;
  }

  .aruba .merit li {
    width: 36rem;
    height: 36rem;
    padding: 4rem;
  }

  .aruba .merit li:nth-child(2) {
    margin: -5rem 0;
  }

  .Wlan .grandstream .governing2 {
    overflow: hidden;
    width: 100vw;
    background: url(../../public/images/product/gv_blue.png)no-repeat center bottom / 100% 60%;
  }

  .Wlan .grandstream .governing2 .product-img {
    margin-top: 50rem;
  }

  .Wlan .grandstream .governing2 h3 {
    margin: 4rem 0;
    font-size: 5rem;
  }  

  .Wlan .grandstream .governing2 .title p,
  .Wlan .grandstream .governing2:nth-child(3) .title p {
    font-size: 1.6rem;
  }

  .Wlan .grandstream .governing2:nth-child(3) .product-img {
    width: 80%;
    margin-top: 48rem;
  }

  .Wlan .grandstream .governing2:nth-child(3) h3 {
    font-size: 5.6rem;
  }

  .Wlan .grandstream .governing2:nth-child(3) .title p br {
    display: block;
  }

  .Wlan .grandstream .governing2 .content {
    width: 60%;
    margin: 4rem auto 0 auto;
  }

  .Wlan .grandstream .governing2 .content p,
  .Wlan .grandstream .governing2:nth-child(3) .content p {
    font-size: 1.6rem;
  }

  .wlc ul {
    flex-direction: row;
    row-gap: 0;
    column-gap: 2rem;
  }

  .wlc li {
    flex: 1;
  }

  .wlc li div {
    height: 30rem;
  }

  .wlc li div p:nth-child(2) br {
    display: none;
  }

  .firewall .governing2 .product-img {
    left: 0;
    right: 0;
    width: 80%;
    margin: 64rem auto 0 auto;
  }

  .firewall .governing2 .title h3,
  .firewall .governing2 .title h3 span {
    font-size: 5.4rem;
  }

  .firewall .governing2 .underline:after {
    left: 50%;
    width: 45rem;
    height: 4rem;
    margin-left: -22rem;
  }

  @keyframes line_path {
    0% {
      width: 0;
    }
    30% {
      width: 45rem;
    }
    100% {
      width: 45rem;
    }
  }

  .firewall .governing2 .content .tit,
  .firewall .governing2 .content .txt {
    font-size: 2rem;
  }

  .utm ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
  }

  .utm li {
    width: calc((100% - 2rem) / 2);
  }

  .utm li:not(:first-child) {
    margin: 0;
  }

  .utm li:nth-child(odd) div:first-child::before,
  .utm li:nth-child(even) div:first-child::before {
    left: auto;
  }

  .wguard .feature .f-list {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
  }

  .wguard .feature .item {
    width: calc((100% - 2rem) / 2);
  }

  .wips .governing2 {
    height: 96rem;
  }

  .wips .governing2 .title h3,
  .wips .governing2 .title h3 span {
    font-size: 5.4rem;
  }

  .wips .governing2 .title p {
    width: 74%;
    margin: 0 auto;
    word-break: keep-all;
  }

  .wips .governing2 .box {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .wips .governing2 .box > li {
    width: 100%;
    margin: 0;
  }

  .wips .governing2 .box ul {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 2rem;
  }

  .wips .governing2 .box ul li {
    width: calc((100% - 2rem) / 2);
  }

  .addition > .sub-inner > ul {
    row-gap: 0;
    width: fit-content;
    padding: 2rem;
    margin: 0 auto;
    border-radius: 50rem;
    background: #F7D1D4;
  }

  .addition > .sub-inner > ul > li {
    width: 44rem;
    height: 44rem;
    padding: 7rem;
    border-radius: 50%;
  }

  .addition > .sub-inner > ul > li:nth-child(2) {
    margin: -4rem 0;
  }

  .wips .link-item img {
    width: 80%;
    margin: 0 auto;
  }

  .integrated .governing2 {
    height: 120rem;
  }

  .integrated .governing2 .product-img {
    left: 0;
    right: 0;
    display: block;
    width: 70%;
    margin: 32rem auto 0 auto;
  }

  .integrated .governing2 .title h3,
  .integrated .governing2 .title h3 span {
    font-size: 4.8rem;
  }

  .integrated .governing2 .title p {
    width: 60%;
    margin: 0 auto;
  }

  .integrated .governing2 .content {
    width: 80%;
    margin: 44rem auto 0 auto;
  }

  .integrated .merit li {
    height: 24rem;
  }

  .certify ul {
    flex-direction: row;
    column-gap: 2rem;
  }

  .certify li div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
  }

  .certify li img {
    width: 80%;
  }

  .QuoteInquiry .customer .title {
    flex-direction: row;
    justify-content: space-between;
  }

  .QuoteInquiry .customer .sc-tit,
  .QuoteInquiry .customer .required {
    text-align: left;
  }

  .address-info > p {
    font-size: 3.2rem;
  }
}


@media all and (min-width:64em) {
  .feature .f-list .tit,
  .product-info .sc-tit {
    font-size: 3.2rem;
  }

  .sub .lnb {
    position: relative;
    display: block;
    height: 8rem;
  }

  .sub .lnb-inner {
    width: 100%;
    height: 8rem;
    box-sizing: border-box;
    border-bottom: 0.1rem solid #ddd;
    text-align: center;
  }

  .sub .lnb .area {
    width: 76.8%;
    max-width: 160rem;
    margin: 0 auto;
  }

  .sub .lnb ul {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sub .lnb ul li {
    float: left;
    width: 14.25%;
    box-sizing: border-box;
    transition: all 0.4s;
  }

  .sub .lnb a {
    position: relative;
    display: block;
    display: table;
    width: 100%;
    height: 7.9rem;
    transition: all 0.4s;
  }

  .sub .lnb a:hover span,
  .sub .lnb li.on a span {
    font-weight: 500;
    color: var(--wh-main-color);
  }

  .sub .lnb a::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0.2rem;
    background: var(--wh-main-color);
    transition: all 0.4s;
  }

  .sub .lnb a:hover::before,
  .sub .lnb li.on a::before {
    width: 100%;
    width: calc(100% + 0.1rem);
    margin-left: -50%;
  }

  .sub .lnb a span {
    display: table-cell;
    vertical-align: middle;
    padding: 0 1.5rem;
    line-height: 1.2;
    font-size: 1.4rem;
    font-family: var(--kr);
    font-weight: 400;
    letter-spacing: -.065em;
    word-break: keep-all;
    color: rgba(0, 0, 0, 0.6);
  }

  .dropdown {
    display: none;
  }

  .sub-inner {
    width: 100%;
    margin: 0 auto;
    padding: 2.4rem;
  }

  .CompanyIntro .sc-tit {
    font-size: 4rem;
  }

  .value .content > div:first-child p,
  .value .content > div:first-child span {
    font-size: 3.2rem;
  }

  .value .value-list div:nth-child(1) p {
    font-size: 1.6rem;
  }

  .value .value-list span {
    font-size: 1.6rem;
  }

  .value .value-list div:nth-child(2) p {
    font-size: 1.6rem;
    font-weight: 400;
  }

  .org-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
  }

  .org-list > li {
    width: calc((100% - 2rem) / 2);
  }

  .talent-list {
    justify-content: space-between;
  }

  .talent-list li {
    width: 48%;
  }

  .benefits li {
    width: 30rem;
  }

  .process .proc-list {
    height: fit-content;
  }

  .business-info .info-list {
    overflow: auto;
  }

  .business-info .info-list > li {
    width: 100%;
  }

  .business-info .info-list > li:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    float: left;
  }

  .business-info .info-list > li:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    float: right;
  }

  .business-info .info-list > li:nth-child(3) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    float: left;
  }

  .business-info .info-list > li:last-child {
    clear: both;
  }

  @keyframes ver_line {
    0% {
      height: 0;
    }
    40% {
      height: 108rem;
    }
    100% {
      height: 108rem;
    }
  }

  .w-diagram .keyword {
    padding-top: 20rem;
  }  

  .service .svc-list .item {
    padding: 4rem;
  }

  .service .icon + div {
    width: fit-content;
  }

  .Maintenance .governing p,
  .Maintenance .governing span {
    font-size: 4rem;
  }

  .partner .info .logo {
    display: block;
  }

  .partner .info {
    display: flex;
    justify-content: space-between;
  }

  .partner .info > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
    margin-bottom: 0;
  }

  .partner .info > p {
    width: 60%;
    text-align: justify;
  }

  .inquiry_banner div {
    padding: 10rem 2rem;
  }

  .gv-wrap {
    row-gap: 18rem;
    width: 60%;
    max-width: 70rem;
    padding: 8rem 6rem;
  }

  .gv-wrap .title {
    text-align: left;
  }

  .product-info .governing .content::before {
    display: none;
  }

  .product-info .governing .content p {
    text-align: left;
  }

  .coreEdge .governing2 .product-img {
    left: 0;
    right: 0;
    width: 70%;
    margin: 36rem auto 0 auto;
  }

  .Network .aruba .governing2 .content {
    margin-top: 24rem;
  }

  .feature .f-list {
    display: flex;
    column-gap: 4rem;
  }

  .feature .f-list > div {
    flex: 1;
  }

  .feature .f-list .bg {
    height: 48rem;
    margin: 0;
  }

  .feature .f-list .bg + div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .feature .f-list:nth-child(even) .bg {
    order: 2;
  }

  .feature .f-list:nth-child(even) .bg + div {
    order: 1;
  }

  .spec-slide .swiper-wrapper {
    flex-wrap: wrap;
  }

  .spec-slide .swiper-wrapper .swiper-slide {
    width: calc((100% - 4rem) / 3);
  }

  .spec .item {
    height: 32rem;
    padding: 2.4rem;
  }

  .spec .swiper-button-prev,
  .spec .swiper-button-next {
    display: none;
  }

  .spec-slide .item.gray {
    background: #ededed;
  }

  .spec-slide .item.gray::before {
    content: '\002B';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 6rem;
    height: 6rem;
    margin: auto;
    line-height: 6rem;
    text-align: center;
    font-size: 6rem;
    font-family: var(--kr);
    font-weight: 100;
    color: rgba(0, 0, 0, 0.2);
  }

  .etc .app {
    transform: translateY(44rem);
  }

  .etc .device {
    transform: translate(80rem, 4rem);
  }

  .etc .etc-product {
    transform: translate(80rem, 35rem);
  }

  .etc .tool-tip {
    margin-top: -5rem;
  }

  .Network .ruckus .governing .content {
    display: flex;
    justify-content: center;
  }

  .Network .ruckus .governing .content > div {
    width: 30rem;
    height: 30rem;
    margin: 0;
    padding: 3rem;
  }

  .Network .ruckus .governing .content > div:not(:first-child) {
    margin-top: 0;
    margin-left: -2rem;
  }

  .Network .ruckus .governing h3 {
    margin-bottom: 16rem;
  }

  .Network .ruckus .governing .content .tit {
    text-align: center;
  }

  .Network .ruckus .governing .content > div li {
    margin-bottom: 0.4rem;
    font-size: 1.4rem;
  }

  .governing2 ul {
    display: flex;
    column-gap: 2rem;
  }

  .governing2 li {
    width: calc((100% - 4rem) / 3);
  }


  .coreEdge .line-up-dev .content > ul {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 4rem;
    row-gap: 8rem;
    width: 100%;
  }

  .coreEdge .line-up-dev .content > ul > li {
    width: calc((100% - 4rem) / 2);
  }

  .coreEdge .line-up-dev ul ul::before {
    left: initial;
    margin-left: 20rem;
  }

  .saisei .governing .txt {
    width: 60%;
    margin: 0 auto;
  }

  .Wlan .ruckus .governing {
    height: 100vh;
    background: url(../../public/images/product/wlan/ruckus_gv_bg.jpg)no-repeat center / cover;
  }

  .Wlan .ruckus .governing .sub-inner {
    position: relative;
    left: 0;
    width: 40%;
    height: fit-content;
    margin: -10rem 0 0 2.4rem;
    padding: 3rem 2rem;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(2rem);
  }

  .Wlan .aruba .governing2 .product-img {
    left: 0;
    right: 0;
    width: 70%;
    margin: 30rem auto 0 auto;
  }

  .merit ul {
    column-gap: 2.4rem;
    row-gap: 2.4rem;
  }

  .merit li {
    width: calc((100% - 4.8rem) / 3);
    height: 24rem;
  }

  .Wlan .grandstream .governing2 .product-img,
  .Wlan .grandstream .governing2:nth-child(3) .product-img {
    width: 60%;
  }

  .Wlan .grandstream .governing2:nth-child(1) .product-img {
    margin-top: 60rem;
  }

  .firewall .governing2 {
    height: 110rem;
  }

  .firewall .governing2 .product-img {
    width: 70%;
    margin-top: 72rem;
  }

  .firewall .governing2 .content {
    width: 60%;
    margin: 10rem auto 0 auto;
  }

  .wguard .feature .item {
    flex: none;
    width: calc((100% - 4rem) / 3);
  }

  .wips .governing2 {
    background-position: center bottom;
    background-size: 100% 75%;
  }

  .wips .governing2 .title p {
    width: 70%;
    font-size: 2rem;
  }

  .wips .governing2 .box {
    flex-direction: row;
    row-gap: 0;
    column-gap: 2rem;
  }

  .wips .governing2 .content {
    margin-top: 16rem;
  }

  .wips .governing2 .box >li {
    width: calc((100% - 2rem) / 2);
  }

  .wips .governing2 .box div p {
    font-size: 2.4rem;
  }

  .integrated .governing2 .product-img {
    margin-top: 40rem;
  }

  .integrated .governing2 .title h3,
  .integrated .governing2 .title h3 span {
    font-size: 5.4rem;
  }

  .integrated .governing2 .title p {
    font-size: 1.8rem;
  }

  .integrated .governing2 .content {
    width: 50%;
    margin: 50rem 0 0 0;
  }

  .integrated .merit li {
    width: calc((100% - 4.8rem) / 2);
    height: 28rem;
    padding: 4rem;
  }

  .QuoteInquiry .inner {
    display: flex;
  }

  .QuoteInquiry .inner > * {
    flex: 1;
  }

  .QuoteInquiry .info-wrap dt {
    width: 20%;
  }

  .form-group {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .form-group > * {
    padding: 0 1rem;
  }

  .form-item.col-10.col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .form-item.col-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }

}

@media all and (min-width:80em) {
  .sub .visual .sv-title {
    padding: 0;
  }

  .sub .visual .sv-title > div {
    width: 100%;
    max-width: 76.8%;
    margin: 0 auto;
    padding: 20rem 0 8rem;
  }

  .sub .visual .sv-tit,
  .sub .visual .sv-txt {
    text-align: left;
  }

  .sub-inner {
    width: 100%;
    max-width: 76.8%;
    height: 100%;
    padding: 0;
  }

  .about .sub-inner {
    width: 100%;
    max-width: none;
    margin-left: 0;
  }

  .about .content {
    padding: 6rem 10rem;
  }

  .value .sc-tit {
    margin-bottom: 16rem;
  }

  .org .sc-tit {
    text-align: left;
  }

  .org-list {
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;
    width: 100%;
  }

  .org-list > li {
    flex: 1;
  }

  .org-list > li,
  .org-list li li {
    margin: 0;
  }
    
  .org-list div,
  .org-list li li {
    margin-bottom: 2rem;
  }

  .talent-list {
    justify-content: space-between;
    row-gap: 2rem;
  }
  
  .talent-list li {
    width: 32%;
  }

  .NetworkBuilding .info-list > li:nth-child(2) {
    clear: both;
  }

  .business-info .info-list > li:nth-child(2) .con {
    margin-left: 0;
  }

  .business-info .info-list > li .con {
    width: 45rem;
    padding: 5rem 0 1.4rem 6rem;
  }

  .business-info .proc-list {
    flex-direction: row;
    column-gap: 2rem;
    margin: 0;
    padding: 8rem 0 0 0;
    border-left: 0;
    border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
  }

  .business-info .proc-list > li {
    flex: 1;
  }

  .business-info .proc-list > li::after {
    left: initial;
    top: 0;
    margin-top: -2rem;
    margin-left: 6rem;
  }

  .business-info .proc-list > li p {
    height: 6.5rem;
    word-break: keep-all;
    line-height: 1.4;
  }

  .process .col .proc-item {
    margin-left: 0;
  }

  .process .col {
    overflow: visible;
    position: relative;
    width: 22rem;
    height: 22rem;
    margin-right: -4rem;
    background: transparent;
    transition: all 0.5s ease;
  }

  .process .col.active {
    width: 24rem;
    margin-right: 30rem;
    transition: all 0.5s ease;
  }

  .process .col .tit {
    width: 22rem;
    height: 22rem;
    padding: 4rem;
  }

  .process .col .tit p {
    font-size: 2rem;
  }

  .process .col .area {
    display: none;
    width: 24rem;
    padding-right: 3rem;
    box-sizing: border-box;
    opacity: 0;
  }

  .process .col.active .area {
    position: absolute;
    display: block;
    margin-left: 26rem;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .process .col:not(:last-child) .area::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 3rem;
    height: 3rem;
    margin: auto 0;
    background: url(../../public/images/main/arrow_right_bk.svg)no-repeat center / 60%;
  }

  .process .pagi {
    display: none;
  }

  .NetworkBuilding .process .col {
    width: 22rem;
    height: 22rem;
  }

  .NetworkBuilding .process .col .tit {
    width: 18rem;
    height: 18rem;
    padding: 4rem;
  }

  .NetworkBuilding .process .col .tit span {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 1.6rem;
  }

  .process .col.active .tit p {
    font-size: 3.2rem;
  }

  .NetworkBuilding .process .col .area {
    width: 20rem;
    margin-left: 20rem;
  }

  .NetworkBuilding .process .col.active {
    margin-right: 22rem;
  }

  .service .svc-list {
    justify-content: flex-start;
    column-gap: 2rem;
  }

  .service .svc-list .item {
    width: calc((100% - 4rem) / 3);
  }

  .trend .title {
    justify-content: center;
    column-gap: 16rem;
  }

  .trend .sc-tit,
  .trend .sc-tit span {
    display: block;
    line-height: inherit;
    font-size: 5.4rem;
  }

  .trend .sc-tit span {
    margin-top: 1rem;
  }

  .trend .sc-txt {
    width: 50%;
    line-height: 1.5;
    font-size: 1.8rem;
    color: rgba(0, 0, 0, 0.8);
  }

  .trend .area {
    display: flex;
    justify-content: space-between;
  }

  .trend .area > div {
    width: 48%;
  }

  .trend .area > div:first-child::after {
    content: "";
    position: absolute;
    top: 65%;
    right: 0;
    left: 0;
    display: block;
    width: 14.5rem;
    height: 12rem;
    margin-left: 39.5rem;
    background: url(../../public/images/business/trend_line.svg)no-repeat center /100% 100%;
    transition: all 0.5s ease;
  }

  .trend .area > div:last-child {
    margin-left: 0;
    margin-top: 6rem;
  }

  .trend .area > div:last-child > div {
    margin-left: 0;
  }

  .WlanBuilding .service .list-wrap {
    padding: 5rem 4rem;
  }

  .WlanBuilding .service .list-wrap > div:first-child {
    display: flex;
    column-gap: 8rem;
  }

  .WlanBuilding .service .svc-list .item {
    flex: 1;
    display: block;
  }

  .WlanBuilding .service .svc-list .icon {
    margin-bottom: 4rem;
  }

  .w-diagram .function {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .w-diagram .sub-inner::before {
    width: 100%;
    height: 0.1rem;
    margin: 19% 0 0 0;
  }

  @keyframes ver_line {
    0% {
      width: 0;
      height: 0.1rem;
    }
    40% {
      width: 100%;
      height: 0.1rem;
    }
    100% {
      width: 100%;
      height: 0.1rem;
    }
  }

  .w-diagram .function .cir_b {
    width: 40%;
  }

  .w-diagram .cir_b > div {
    width: 24%;
  }

  .w-diagram .keyword::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    width: 0.1rem;
    height: 12rem;
    margin: -12rem auto 0 auto;
    background: var(--wh-main-color);
  }
  

  .Maintenance .service .svc-list .item {
    flex-direction: column;
  }

  .Maintenance .service .icon {
    margin-bottom: 4rem;
  }

  .governing2 {
    height: 64rem;
  }

  .aruba .governing2 {
    background: url(../../public/images/product/gv_orange.png)no-repeat center bottom / 100% 80%;
  }

  .governing2 .product-img {
    left: 50%;
    display: block;
    width: 50%;
    max-width: 60rem;
  }

  .governing2 .product-img img {
    width: 100%;
  }

  .governing2 .title span,
  .governing2 .title h3,
  .governing2 .title p {
    text-align: left;
  }

  .governing2 .content {
    margin-top: 20rem;
  }

  .etc .app {
    transform: translate(4rem, 44rem);
  }

  .Network .coreEdge .governing2 {
    background: url(../../public/images/product/gv_gray.png)no-repeat center bottom 0 / 100% 72%;
  }

  .coreEdge .governing2 .product-img {
    left: 20%;
    width: 80rem;
    max-width: 80rem;
    margin-top: 18rem;
  }

  .function li {
    width: calc((100% - 4rem) / 3);
  }

  .Wlan .ruckus .governing .sub-inner {
    margin-left: 10%;
  }

  .Wlan .aruba .governing2 .product-img {
    left: 30%;
    width: 60%;
    max-width: fit-content;
    margin-top: 24rem;
  }

  .aruba .merit ul {
    flex-direction: row;
    padding: 4rem 0;
    background: url(../../public/images/product/wlan/merit_pc_bg.png) no-repeat center / 100%;
  }

  .aruba .merit li {
    width: 34rem;
    height: 32rem;
  }

  .aruba .merit li:nth-child(2) {
    margin: 0 -4rem;
  }

  .Wlan .aruba .spec .item {
    height: 36rem;
  }

  .fc-toggle .detail-item button > div:first-child {
    flex-direction: row;
    align-items: center;
  }

  .Wlan .grandstream .governing2 {
    height: 80rem;
    background: url(../../public/images/product/gv_blue.png)no-repeat center bottom / 100% 72%;
  }

  .Wlan .grandstream .governing2:nth-child(1) {
    height: 80rem;
    background: url(../../public/images/product/gv_blue.png)no-repeat center bottom / 100% 70%;
  }

  .Wlan .grandstream .governing2 .product-img,
  .Wlan .grandstream .governing2:nth-child(3) .product-img {
    left: 48%;
    width: 48%;
    max-width: initial;
    margin: 20rem 0 0 0;
  }

  .Wlan .grandstream .governing2:nth-child(1) .product-img {
    margin-top: 24rem;
  }

  .Wlan .grandstream .governing2:nth-child(3) .product-img {
    margin-top: 6rem;
  }

  .Wlan .grandstream .governing2:nth-child(1) .title p {
    width: 44%;
    word-break: keep-all;
  }

  .Wlan .grandstream .governing2:nth-child(1) .title p br {
    display: none;
  }

  .Wlan .grandstream .governing2 .content {
    width: 40%;
    margin: 20rem 0 0 0;
  }

  .Wlan .grandstream .governing2:nth-child(1) .content p {
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
  }

  .Wlan .grandstream .governing2:nth-child(3) .content {
    margin-top: 30rem;
  }

  .Wlan .grandstream .governing2:nth-child(3) .content p {
    text-align: left;
  }

  .axgate .governing2 {
    background-size: 100% 80%;
  }

  .wguard .governing2 {
    background-size: 100% 80%;
  }

  .firewall .governing2 .product-img {
    overflow: hidden;
    left: 44%;
    width: 56%;
    max-width: fit-content;
    margin: 20rem 0 0 0;
  }

  .axgate .governing2 .product-img img {
    width: 110%;
  }

  .wguard .governing2 .product-img img {
    width: 110%;
  }

  .firewall .governing2 .title > span {
    margin-bottom: 2rem;
  }

  .firewall .governing2 .underline:after {
    left: calc((100% - 76.8%) / 2);
    margin-left: -14rem;
  }

  .axgate .governing2 .content {
    margin: 35rem 0 0 0;
  }

  .wguard .governing2 .content {
    margin: 44rem 0 0 0;
  }

  .axgate .governing2 .content .tit,
  .axgate .governing2 .content .txt {
    text-align: left;
    color: #fff;
  }

  .wguard .governing2 .content .txt {
    text-align: left;
  }

  .utm li {
    width: calc((100% - 4rem) / 3);
  }

  .wips .governing2 .title p {
    width: 45%;
    margin: 0;
  }

  .integrated .governing2 {
    height: 100vh;
    background-position: center bottom;
    background-size: 100% 70%;
  }

  .integrated .governing2 .product-img {
    left: 56%;
    width: 40%;
    margin: 10rem 0 0 0;
  }

  .integrated .governing2 .title p {
    width: 48%;
    margin: 0;
  }

  .integrated .governing2 .content {
    width: 50%;
    margin: 10rem 0 0 0;
  }

  .QuoteInquiry .customer .sub-inner {
    display: flex;
    justify-content: space-between;
  }

  .QuoteInquiry .customer .title {
    flex-direction: column;
    width: 40%;
    margin-bottom: 0;
  }

  .QuoteInquiry .apply-form {
    width: 60%;
  }

  .check-box {
    margin-left: 1rem;
  }

  .btn-group {
    padding: 4rem 0.5rem 0 1.5rem;
  }
}

@media all and (min-width:96em) {

  .sub .lnb a span {
    font-size: 1.6rem;
  }

  .value {
    padding: 24rem 0;
  }

  .value .value-core p {
    font-size: 2.4rem;
  }

  .value .value-core span {
    font-size: 1.8rem;
  }

  .value .value-list li {
    width: 24rem;
    height: 24rem;
  }

  .value .value-list li:nth-child(1) {
    transform: translate(-12rem, 0rem);
  }

  .value .value-list li:nth-child(3) {
    transform: translate(13rem, 42rem);
  }

  .value .value-list div:nth-child(1) p {
    font-size: 2rem;
  }

  .value .value-list span {
    font-size: 1.8rem;
  }

  .value .value-list div:nth-child(2) p {
    font-size: 1.6rem;
    font-weight: 400;
  }

  .org .line-group {
    display: inline-block;
    position: absolute;
    z-index: 3;
    height: 58rem;
  }

  .org .line-group .step1 {
    height: 100%;
  }

  .org .line-group .step2 {
    display: none;
  }

  .org .line-group svg {
    position: absolute;
    left: 0;
    width: 46rem;
    height: 100%;
    text-align: center;
  }

  .org .line-group .line1 path,
  .org .line-group .line2 path,
  .org .line-group .line3 path,
  .org .line-group .line4 path,
  .org .line-group .line5 path,
  .org .line-group .line6 path,
  .org .line-group .line7 path,
  .org .line-group .line8 path {
    stroke: #144F95;
  }

  .org .line-group .line1 circle,
  .org .line-group .line5 circle {
    stroke: #fff;
  }

  .org .content-title {
    margin-bottom: 4rem;
  }

  .org-chart {
    align-items: center;
  }

  .org-chart,
  .org-list,
  .org-list > li,
  .org-list ul {
    display: flex;
  }

  .ceo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50rem;
    height: 50rem;
    margin: 4rem 0;
    background: url(../../public/images/company/ceo_bg.png)no-repeat center / cover;
    transform: translateX(-8rem);
  }

  .ceo p {
    font-size: 3.2rem;
    font-family: var(--en);
    font-weight: 700;
    color: var(--wh-main-color);
  }

  .org-list {
    position: absolute;
    left: 50%;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 3rem;
    margin-top: 0;
    margin-left: -13rem;
  }

  .org-list ul {
    column-gap: 3rem;
  }

  .org-list > li {
    column-gap: 3rem;
    width: 100%;
  }

  .org-list > li > div,
  .org-list ul li {
    width: 16rem;
    height: 16rem;
    padding: 2rem;
    margin-bottom: 0;
    border-radius: 1rem;
  }

  .org-list ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.2rem solid var(--wh-main-color);
  }

  .org-list > li > div {
    box-shadow: -.8rem .8rem 1rem rgba(0, 0, 0, 0.2);
    background: linear-gradient(135deg,#008BAE, #1868C7);
  }

  .org-list > li > div > * {
    color: #fff;
  }

  .org-list > li > div span {
    display: block;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: right;
  }

  .org-list > li > div p {
    margin: 4rem 0 0.8rem 0;
    font-size: 2rem;
    font-weight: 600;
  }

  .org-list > li > div i {
    font-size: 1.2rem;
    font-style: normal;
    color: rgba(255, 255, 255, 0.6);
  }

  .org-list > li li p {
    margin-bottom: 0.8rem;
    text-align: center;
    line-height: 150%;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: var(--wh-main-color);
  }

  .org-list > li li i {
    font-size: 1.2rem;
    font-style: normal;
    color: rgba(0, 0, 0, 0.6);
  }

  .step .title {
    display: flex;
    align-items: center;
  }

  .step .title .sc-tit {
    margin-right: 8rem;}

  .step .swiper {
    overflow: visible;
  }

  .step .swiper-wrapper {
    width: 100%;
  }

  .swiper-slide {
    width: calc(100% + 4rem);
    height: 20rem;
  }

  .step-item {
    width: 100%;
    height: 20rem;
    margin-right: 1.2rem;
    padding: 2.4rem 2.6rem;
    box-sizing: border-box;
    color: #fff;
    background: url(../../public/images/company/step_bg.png)no-repeat left 0 center / 100% 100%;
  }
  
  .swiper-slide:not(:first-child) .step-item {
    padding-left: 4.5rem;
  }

  .swiper-slide:first-child .step-item {
    background-color: #7599C3;
    background-image: url(../../public/images/company/step_bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

  .swiper-slide:last-child .step-item {
    background-color: transparent;
    background-image: url(../../public/images/company/step_bg_gd2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

  .step-item .after {
    right: -3.9rem;
    width: 4rem;
  }

  .benefits {
    padding-top: 10rem;
  }

  .benefits::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 40rem;
    margin-top: -10rem;
    background: linear-gradient(90deg, #008BAE, #1868C7);
  }

  .benefits .sub-inner {
    display: flex;
    width: 90%;
    max-width: none;
    margin-left: 10%;
  }

  .benefits .sc-tit {
    color: #fff;
  }

  .benefits .content {
    overflow-x: scroll;
    margin-left: 20rem;
  }

  .benefits ul {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: fit-content;
    padding: 2rem;
  }

  .benefits li {
    width: 24rem;
    height: 44rem;
    box-sizing: border-box;
    background: #fff;
  }

  .governing .tit {
    margin-bottom: 6rem;
    font-size: 6.4rem;
  }

  .governing .txt {
    font-size: 2rem;
  }

  .Wlan .ruckus .governing .sub-inner {
    padding: 6rem 4rem;
  }

  .Wlan .ruckus .governing h3,
  .product-info .governing h3 .highlight {
    font-size: 5rem;
  }

  .trend .area > div:first-child::after {
    top: 60%;
    width: 10rem;
    height: 15rem;
    margin-left: 51rem;
  }

  .business-info .info-list > li:not(:first-child) {
    margin-top: 24rem;
  }

  .business-info .service .inner {
    flex-direction: row;
    justify-content: space-between;
  }

  .business-info .service .content-area {
    width: 70%;
  }

  .Maintenance .governing p,
  .Maintenance .governing span {
    font-size: 3.2rem;
  }

  .w-diagram .sub-inner::before {
    margin-top: 16.5%;
  }

  .w-diagram .function .cir_b {
    width: 36%;
  }

  .w-diagram .cir_b > div {
    width: 20%;
  }

  .w-diagram .cir_b .sol li {
    width: 16rem;
    height: 16rem;
  }

  .w-diagram .cir_b .sol li:nth-child(1) {
    transform: translate(-15rem, -15rem);
  }
  
  .w-diagram .cir_b .sol li:nth-child(2) {
    transform: translate(15rem, -15rem);
  }
  
  .w-diagram .cir_b .sol li:nth-child(3) {
    transform: translate(-15rem, 15rem);
  }
  
  .w-diagram .cir_b .sol li:nth-child(4) {
    transform: translate(15rem, 15rem);
  }

  .w-diagram .keyword ul {
    width: fit-content;
  }
  
  .w-diagram .keyword li {
    width: 24rem;
    height: 24rem;
  }

  .w-diagram .keyword li:nth-child(2) {
    margin: 0 -4rem;
  }

  .process .col,
  .process .col.active,
  .process .col .tit {
    width: 26rem;
    height: 26rem;
  }

  .process .col.active {
    margin-right: 27rem;
  }

  .process .col.active .area {
    margin-left: 28rem;
  }

  .NetworkBuilding .process .col .tit {
    width: 22rem;
    height: 22rem;
  }

  .NetworkBuilding .process .col.active .area {
    width: 20rem;
    margin-left: 24rem;
  }

  .service .svc-list .item {
    flex: 1;
    display: block;
  }

  .service .svc-list .icon {
    margin-bottom: 4rem;
  }

  .partner .sumnail {
    height: 40rem;
  }

  .feature .f-list .tit,
  .product-info .sc-tit {
    margin-bottom: 16rem;
  }

  .product-info {
    margin-top: 24rem;
  } 

  .product-info .governing h3 {
    font-size: 5.4rem;
  }

  .product-info .governing h3 span {
    font-size: 4rem;
  }

  .feature .f-list .bg {
    height: 72rem;
  }

  .feature .f-list .tit {
    width: 70%;
    margin: 0 auto 4rem auto;
    font-size: 3.2rem;
  }

  .feature .f-list .tit + p {
    width: 70%;
    margin: 0 auto;
    line-height: 1.45;
    font-size: 1.8rem;
  }

  .product-info .sc-tit {
    font-size: 4rem;
  }

  .spec-slide .swiper-wrapper .swiper-slide {
    width: calc(100% / 4);
    max-width: calc(100% / 4);
  }

  .spec-slide .swiper-slide {
    height: 40rem;
  }

  .spec .item {
    height: 100%;
  }

  .Wlan .aruba .spec .spec-slide img {
    max-width: 16rem;
  }

  .Wlan .aruba .spec .item {
    height: 100%;
  }

  .spec .spec-slide p {
    font-size: 2.4rem;
  }

  .etc .graph-wrap .app ul {
    padding: 2rem 1rem;
  }

  .etc .app {
    scale: 0.9;
    transform: translate(4rem,44rem);
  }

  .etc .device {
    scale: 0.9;
    transform: translate(110rem, 16rem);
  }

  .etc .etc-product {
    scale: 0.9;
    transform: translate(110rem, 48rem);
  }

  .etc .graph-wrap ul li > p {
    font-weight: 600;
  }

  .etc .graph-wrap .bg {
    width: 90%;
    background: url(../../public/images/product/ipt/ipt_pc_diagram.png)no-repeat center / 100%;
  }

  .function .title {
    margin-bottom: 8rem;
  }

  .function li {
    height: 48rem;
  }

  .governing2 {
    height: 96rem;
  }

  .governing2 .product-img {
    max-width: 70rem;
  }

  .governing2 .title img {
    height: 9.4rem;
  }

  .governing2 .title h3 {
    font-size: 4.8rem;
  }

  .governing2 .title p {
    font-size: 2rem;
  }

  .ruckus .governing .highlight:after {
    margin-top: 4.2rem;
  }

  .coreEdge .line-up-dev .content > ul {
    column-gap: 6rem;
  }

  .coreEdge .line-up-dev .content > ul > li {
    flex: 1;
  }

  .coreEdge .line-up-dev li:not(:last-child) .tit::after {
    content: "";
    position: absolute;
    display: block;
    width: 5rem;
    height: 5rem;
    margin: -5.5rem 0 0 25.6rem;
    background: url(../../public/images/product/network/arrow_both.svg)no-repeat center;
  }

  .coreEdge .line-up-dev .content > ul ul {
    height: 25rem;
  }

  .coreEdge .line-up-dev ul ul::before {
    margin-left: 9rem;
  }

  .Network .grandstream .governing2 {
    background: url(../../public/images/product/gv_blue.png)no-repeat center bottom 0rem / 100% 90%;
  }

  .Network .grandstream .governing2 .title img {
    height: 5.4rem;
  }

  .Network .grandstream .governing2 .title h3 {
    font-size: 3.6rem;
  }

  .Network .grandstream .governing2 .title h3 span {
    font-size: 4.2rem;
  }

  .Network .grandstream .governing2 .content {
    margin-top: 17rem;
  }
  
  .Network .grandstream .governing2 .content ul {
    margin: 0;
  }

  .Network .grandstream .spec .table-wrap{
    padding: 0;
  }

  .Network .grandstream .spec .img img{
    max-height: 8rem;
  }

  .saisei .governing .title img {
    height: 6rem;
    margin-bottom: 4rem;
  }

  .saisei .governing .tit {
    margin-bottom: 2rem;
  }

  .saisei .feature .content {
    display: flex;
    justify-content: space-between;
  }

  .saisei .function .sub-inner {
    display: flex;
    justify-content: space-between;
  }

  .fc-toggle {
    width: 70%;
  }

  .effect .sub-inner {
    display: flex;
    justify-content: space-between;
  }

  .effect .title {
    flex-direction: column;
    justify-content: flex-start;
    width: 20%;
  }

  .effect .sc-txt {
    width: initial;
    font-size: 1.8rem;
  }

  .effect .content {
    width: 70%;
  }

  .Wlan .ruckus .governing h3 br {
    display: none;
  }

  .Wlan .ruckus .governing .sub-inner {
    width: 35%;
  }

  .Wlan .ruckus .governing .sub-inner p {
    text-align: left;
    font-size: 2rem;
  }

  .merit li {
    width: calc((100% - 4.8rem) / 3);
  }

  .merit li div p {
    font-size: 3rem;
  }

  .merit li > p {
    font-size: 1.8rem;
  }

  .aruba .merit li {
    width: 44rem;
    height: 44rem;
    padding: 7rem;
  }

  .aruba .merit li:nth-child(2) {
    margin: 0 -8rem;
  }

  .Wlan .grandstream .governing2 .title img {
    height: 6rem;
  }

  .Wlan .grandstream .governing2 .content {
    width: 36%;
    margin-top: 20rem;
  }

  .Wlan .grandstream .governing2:nth-child(3) .product-img {
    width: 40%;
  }

  .Wlan .grandstream .governing2:nth-child(3) .content {
    margin-top: 30rem;
  }

  .Wlan .grandstream .governing2:nth-child(1) .title p {
    width: 38%;
  }

  .wlc label {
    font-size: 2.4rem;
  }

  .wlc li div p:nth-child(2) br {
    display: block;
  }

  .link .content {
    column-gap: 1rem;
  }

  .firewall .governing2 .product-img img {
    width: 100%;
  }

  .axgate .governing2 .content {
    width: 38%;
    margin-top: 33rem;
  }

  .wguard .governing2 .product-img {
    left: 48%;
    width: 50%;
  }

  .wguard .governing2 .content {
    width: 50%;
    margin-top: 40rem;
  }

  .utm .content::before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 8rem;
    margin-top: 6rem;
    background: #ededed;
  }

  .utm ul {
    z-index: 2;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    column-gap: initial;
    row-gap: initial;
  }

  .utm li {
    width: 22rem;
    height: 22rem;
    border-radius: 50%;
  }

  .utm li > div {
    height: 50%;
  }

  .utm li > div:first-child {
    padding: 3rem;
  }

  .utm li > div:last-child {
    background: #fff;
  }

  .utm li:nth-child(odd) div:first-child::before,
  .utm li:nth-child(even) div:first-child::before {
    margin-left: 0;
  }

  .wguard .feature .content {
    overflow: hidden;
    width: 100%;
  }

  .wguard .f-list {
    z-index: 5;
    position: absolute;
    display: block;
  }

  .wguard .f-list .item {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20rem;
    height: 20rem;
    margin: 0 auto;
    border-radius: 50%;
  }

  .wguard .feature .item:nth-child(1) {
    transform: translate(-6rem, 20rem);
  }

  .wguard .feature .item:nth-child(2) {
    transform: translate(12rem, -8rem);
  }

  .wguard .feature .item:nth-child(3) {
    transform: translate(49rem, -22rem);
  }

  .wguard .feature .item:nth-child(4) {
    transform: translate(86rem, -8rem);
  }

  .wguard .feature .item:nth-child(5) {
    transform: translate(105rem, 20rem);
  }

  .wguard .feature .item > span {
    display: none;
    width: 4rem;
    height: 4rem;
  }

  .wguard .feature .item > span,
  .wguard .feature .item > p {
    float: initial;
    text-align: center;
  }

  .wguard .feature .item p:nth-child(2) {
    line-height: 1.4;
    font-size: 1.8rem;
  }

  .wguard .feature .item p span {
    display: block;
    margin-right: 0;
    font-size: 1.8rem;
  }

  .wguard .feature .item p:nth-child(3) {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.4rem;
  }

  .wguard .feature .cir-r {
    display: block;
    width: fit-content;
    height: 40rem;
    margin: 0 auto;
  }

  .wguard .cir-r .bg {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    background: url(../../public/images/product/security/cir_bg.png)no-repeat center top 1rem / 90% auto;
  }

  .wguard .cir-r span {
    display: block;
  }

  .wguard .cir-r .cir-bar {
    width: fit-content;
    height: fit-content;
    margin-top: -5rem;
    scale: 0.9;
    animation-delay: 1s;
    animation: cir_bar 10s infinite;
  }

  @keyframes cir_bar {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(45deg);
    }
    50% {
      transform: rotate(90deg);
    }
    75% {
      transform: rotate(135deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  .cir-r .proc-img {
    z-index: 3;
    position: absolute;
    left: 0;
    right: 0;
    width: 36%;
    margin: 0 auto;
  }

  .cir-r .proc-img img{
    width: 100%;
  }

  .wips .governing2 .content {
    margin-top: 32rem;
  }

  .wips .governing2 .box div {
    padding-bottom: 2rem;
    margin-bottom: 4rem;
  }

  .wips .governing2 .box div p {
    font-size: 3.2rem;
  }

  .addition > .sub-inner > ul {
    flex-direction: row;
    margin-left: -5rem;
  }

  .addition > .sub-inner > ul > li:nth-child(2) {
    margin: 0 -4rem;
  }

  .integrated .governing2 .product-img {
    left: 50%;
    width: 42%;
    max-width: fit-content;
    margin-top: 10rem;
  }

  .integrated .governing2 .title p {
    width: 40%;
  }

  .integrated .governing2 .content {
    width: 42%;
    margin-top: 10rem;
  }

  .integrated .merit ul {
    justify-content: space-between;
  }

  .integrated .merit li {
    flex: none;
    width: calc((100% - 7.2rem) / 3);
    height: 32rem;
  }

  .integrated .merit li > div {
    margin-bottom: 4rem;
  }

  .QuoteInquiry .customer .sc-tit {
    font-size: 4rem;
  }

  .QuoteInquiry .info-wrap dl {
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .QuoteInquiry .info-wrap dt,
  .QuoteInquiry .info-wrap dd {
    font-size: 1.6rem;
  }

  .QuoteInquiry .apply-form {
    width: 56%;
  }

  .address .title h3 {
    font-size: 4rem;
  }

  .address-info {
    display: flex;
  }

  .address-info > p {
    width: 40%;
  }

  .address-info li {
    margin: 0 0 2rem 0;
  }
}

@media all and (min-width:120em) {

  .sub-tab button {
    font-size: 1.6rem;
  }

  .sv-tit {
    margin-bottom: 2rem;
    font-size: 5.6rem;
  }

  .sv-txt {
    font-size: 2rem;
  }

  .sc-tit {
    font-size: 4rem;
  }

  .about .content {
    padding: 8rem calc((100% - 160rem) / 2);
  }

  .about .content .sc-txt {
    font-size: 2rem;
  }

  .history .h-list {
    width: 80%;
    margin: 0 auto;
  }

  .history .h-list .item div {
    width: 40%;
  }

  .org .line-group svg {
    width: 65rem;
    height: 70rem;
  }

  .org .line-group .step1 {
    display: none;
  }

  .org .line-group .step2 {
    display: block;
    height: 100%;
  }

  .ceo {
    width: 60rem;
    height: 60rem;
  }

  .org-list {
    left: 54%;
  }

  .org-list > li > div,
  .org-list ul li {
    width: 18rem;
    height: 18rem;
  }

  .org-list > li > div i,
  .org-list > li li i {
    line-height: 1.4;
    font-size: 1.6rem;
  }

  .talent-list div {
    height: 32rem;
  }

  .talent-list li strong {
    font-size: 2.4rem;
  }

  .talent-list li p:last-child {
    font-size: 1.6rem;
  }

  .step .title .txt {
    font-size: 2rem;
  }

  .step .swiper-slide {
    height: 24rem;
  }

  .step-item {
    height: 24rem;
  }

  .step-item .top {
    font-size: 2rem;
  }

  .step-item p {
    margin-bottom: 10rem;
    font-size: 2.4rem;
  }

  .step-item .after {
    right: -4.9rem;
    width: 5rem;
    background-color: transparent;
  }

  .step-item em {
    font-size: 1.8rem;
  }

  .step-item .line {
    right: -1rem;
  }

  .swiper-slide:not(:first-child) .step-item {
    padding-left: 6rem;
  }

  .benefits .content {
    width: 82%;
  }

  .benefits p {
    font-size: 2rem;
  }

  .Maintenance .governing p,
  .Maintenance .governing span {
    font-size: 4rem;
  }

  .business-info .info-list > li {
    max-width: 100rem;
  }

  .business-info .info-list > li:not(:first-child) {
    margin-top: 32rem;
  }

  .business-info .info-list > li .bg {
    height: 54rem;
  }

  .business-info .info-list > li .con {
    width: 60rem;
  }

  .business-info .info-list > li .con .tit {
    font-size: 4rem;
  }

  .business-info .info-list > li .con .txt {
    font-size: 2rem;
    margin: 2rem 0;
  }

  .business-info .info-list > li .con li {
    font-size: 1.8rem;
  }

  .trend .area > div:first-child::after {
    top: 60%;
    width: 16rem;
    height: 18rem;
    margin-left: 62rem;
  }

  .w-diagram .cir_b .sol li {
    width: 18rem;
    height: 18rem;
    font-size: 2rem;
  }

  .w-diagram .cir_b .sol li:nth-child(1) {
    transform: translate(-20rem, -20rem);
  }
  
  .w-diagram .cir_b .sol li:nth-child(2) {
    transform: translate(20rem, -20rem);
  }
  
  .w-diagram .cir_b .sol li:nth-child(3) {
    transform: translate(-20rem, 20rem);
  }
  
  .w-diagram .cir_b .sol li:nth-child(4) {
    transform: translate(20rem, 20rem);
  }

  .w-diagram .cir_b > div p {
    font-size: 3.2rem;
  }

  .w-diagram .cir_b > div span {
    font-size: 1.8rem;
  }

  .w-diagram .function .cir_m {
    width: 30rem;
    height: 30rem;
    padding: 4rem;
    font-size: 1.8rem;
  }

  .w-diagram .keyword ul {
    border-radius: 20rem;
  }

  .w-diagram .keyword li {
    width: 30rem;
    height: 30rem;
  }

  .sub section .title {
    margin-bottom: 16rem;
  }

  .process .col,
  .process .col.active,
  .process .col .tit {
    width: 33rem;
    height: 33rem;
  }

  .process .col.active .area {
    margin-left: 35rem;
  }

  .process .col .tit {
    padding: 6rem;
  }

  .process .col .tit p {
    font-size: 2.4rem;
  }

  .NetworkBuilding .process .col {
    width: 28rem;
    height: 28rem;
  }

  .NetworkBuilding .process .col .tit {
    width: 28rem;
    height: 28rem;
  }
  
  .NetworkBuilding .process .col.active .area {
    margin-left: 30rem;
  }

  .service .svc-list .item {
    padding: 6rem 4rem;
  }

  .service .icon {
    width: 7rem;
    height: 7rem;
  }

  .service .content p {
    font-size: 2.4rem;
  }

  .service .content li {
    font-size: 1.8rem;
  }

  .etc .app {
    scale: 1;
    transform: translate(4rem, 38rem);
  }

  .etc .device {
    scale: 1;
    transform: translate(124rem, 2rem);
  }

  .etc .etc-product {
    scale: 1;
    transform: translate(124rem, 48rem);
  }

  .partner .sumnail {
    height: 50rem;
  }

  .partner .info > p {
    font-size: 2rem;
  }

  .partner .info > p b {
    font-size: 2.4rem;
  }

  .inquiry_banner .sc-tit {
    font-size: 4rem;
  }

  .inquiry_banner .sc-txt {
    font-size: 2rem;
  }

  .governing2 {
    height: 90vh;
  }

  .governing2 .product-img {
    max-width: 72rem;
    margin-top: 16rem;
  }

  .coreEdge .governing2 .product-img {
    width: 90rem;
    max-width: 90rem;
  }

  .Network .grandstream .governing2 {
    background: url(../../public/images/product/gv_blue.png)no-repeat center bottom 0rem / 100% 80%;
  }

  .Network .grandstream .governing2 .product-img {
    max-width: 80rem;
  }

  .governing2 .title {
    margin: 10rem 0 0 0;
  }

  .governing2 .content {
    margin-top: 30rem;
  }

  .Network .grandstream .governing2 .content {
    margin-top: -2rem;
  }

  .governing2 .content li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    width: auto;
    height: 32rem;
    padding: 4rem;
    margin: 0;
  }

  .governing2 .content li > div p {
    font-size: 2.4rem;
  }

  .governing2 .content li > p {
    font-size: 1.8rem;
  }

  .product-info .sc-tit {
    margin-bottom: 8rem;
    font-size: 4.8rem;
  }

  .product-info .sc-tit,
  .feature .f-list .tit {
    padding: 0.6rem 3rem;
  }

  .feature .f-list {
    column-gap: 16rem;
  }

  .feature .f-list:not(:last-child) {
    margin-bottom: 20rem;
  }

  .feature .f-list .tit {
    width: auto;
    margin: 0 0 8rem 0;
    font-size: 4rem;
  }

  .feature .f-list .tit + p {
    width: 90%;
    margin: 0;
    font-size: 2rem;
  }

  .saisei .feature .title,
  .saisei .feature .sc-tit {
    margin-bottom: 8rem;
    font-size: 4.8rem;
  }

  .saisei .feature .content ul {
    width: 68rem;
    height: 64rem;
  }

  .saisei .feature .content li {
    width: 32rem;
    height: 32rem;
    padding: 4rem;
  }

  .saisei .feature .content li:first-child {
    transform: translate(-15rem, 13rem);
  }

  .saisei .feature .content li:nth-child(2) {
    transform: translate(0.5rem, -13rem);
  }

  .saisei .feature .content li:last-child {
    transform: translate(15rem, 13rem);
  }

  .saisei .feature .content .tit {
    font-size: 3.2rem;
  }

  .saisei .feature li p:first-child {
    font-size: 2.4rem;
  }

  .saisei .feature li p:last-child {
    font-size: 1.8rem;
  }

  .function ul {
    column-gap: 4rem;
    row-gap: 4rem;
  }

  .function li {
    width: calc((100% - 8rem) / 3);
    height: 54rem;
    padding: 4rem;
  }

  .function .wrap .tit {
    font-size: 2.4rem;
  }

  .function .wrap .txt {
    font-size: 1.8rem;
  }

  .spec thead th {
    font-size: 2.4rem;
  }

  .spec td,
  .spec td p,
  .spec .p-name,
  .spec li,
  .wips .spec p span {
    font-size: 2rem;
  }

  .coreEdge .line-up-dev li:not(:last-child) .tit::after {
    margin-left: 33rem;
  }

  .saisei .feature .content {
    justify-content: space-around;
  }

  .saisei .spec .sub-inner {
    display: flex;
    justify-content: space-between;
  }

  .saisei .spec .table-wrap {
    width: 70%;
  }

  .Wlan .ruckus .governing .sub-inner {
    margin-top: 10rem;
    margin-left: calc((100% - 160rem) / 2);
  }

  .Wlan .aruba .governing2 .product-img {
    width: 50%;
    margin-top: 32rem;
  }

  .Wlan .aruba .governing2 .title h3 {
    font-size: 5.6rem;
  }

  .Wlan .aruba .governing2 .title p {
    font-size: 2.4rem;
  }

  .merit li {
    flex: 1;
    height: auto;
  }

  .merit li > div {
    margin-bottom: 8rem;
  }

  .coreEdge .line-up-dev .tit {
    font-size: 2.4rem;
  }

  .coreEdge .line-up-dev li li {
    font-size: 1.8rem;
  }

  .coreEdge .line-up-dev .content > div p:first-child {
    font-size: 3.2rem;
  }

  .coreEdge .line-up-dev .content > div p:last-child {
    font-size: 2rem;
  }

  .fc-toggle {
    width: 60%;
  }

  .fc-toggle .detail-item button span,
  .fc-toggle .detail-item button p {
    font-size: 2.4rem;
  }

  .fc-toggle .detail-item > div p {
    font-size: 1.8rem;
  }

  .effect .title {
    width: 30%;
  }

  .effect .sc-txt {
    font-size: 2.4rem;
  }

  .effect .content {
    width: 60%;
  }

  .effect .content > ul {
    row-gap: 4rem;
  }

  .effect .content > ul > li {
    padding: 4rem 0;
  }

  .effect .content li span,
  .effect .content li p {
    font-size: 2.4rem;
  }

  .effect .content li li {
    font-size: 1.8rem;
  }

  .link-item {
    max-width: 48rem;
    height: 72rem;
  }

  .link-item:hover:before {
    max-width: 48rem;
  }

  .link-item .tit {
    font-size: 3.2rem;
  }

  .link-item .txt {
    font-size: 2rem;
  }

  .Wlan .grandstream .governing2:nth-child(1),
  .Wlan .grandstream .governing2:nth-child(3) {
    height: 100vh;
  }

  .Wlan .grandstream .governing2 .product-img {
    left: 42%;
    width: 45%;
    margin-top: 24rem;
  }

  .Wlan .grandstream .governing2:nth-child(3) .product-img {
    left: 46%;
    width: 42%;
  }

  .Wlan .grandstream .governing2 h3 {
    font-size: 6.4rem;
  }

  .Wlan .grandstream .governing2 .title p,
  .Wlan .grandstream .governing2:nth-child(3) .title p,
  .Wlan .grandstream .governing2 .content p,
  .Wlan .grandstream .governing2:nth-child(3) .content p {
    font-size: 2rem;
  }

  .Wlan .grandstream .governing2 .content {
    width: 32%;
    margin-top: 20rem;
  }

  .Wlan .grandstream .governing2:nth-child(3) .content {
    margin-top: 40rem;
  }

  .wlc ul {
    column-gap: 4rem;
  }

  .wlc li div {
    height: 25rem;
  }

  .wlc label {
    padding-bottom: 4rem;
    font-size: 3.2rem;
  }

  .wlc li div img {
    width: 8rem;
  }

  .firewall .governing2 .underline:after {
    width: 48rem;
    margin-left: -19rem;
  }

  @keyframes line_path {
    0% {
      width: 0;
    }
    30% {
      width: 48rem;
    }
    100% {
      width: 48rem;
    }
  }

  .axgate .governing2 .content {
    margin-top: 24rem;
  }

  .wguard .governing2 .content {
    width: 48%;
    margin-top: 32rem;
  }

  .utm .content::before {
    height: 12rem;
    margin-top: 8rem;
  }

  .utm ul {
    column-gap: 2rem;
  }

  .utm li {
    flex: 1;
    height: auto;
    aspect-ratio: 1/1;
  }

  .utm li div:first-child,
  .utm li div:last-child {
    height: 50%;
  }

  .utm li:nth-child(even) div:first-child::before,
  .utm li:nth-child(odd) div:first-child::before {
    margin-top: 15rem;
  }

  .wguard .spec .p-name em {
    font-size: 1.6rem;
  }

  .wguard .feature .content, 
  .wguard .feature .cir-r {    
    height: 51rem;
  }

  .wguard .feature .item {
    width: 24rem;
    height: 24rem;
  }

  .wguard .feature .item > span {
    display: block;
    min-width: 4rem;
    min-height: 4rem;
  }

  .wguard .feature .item:nth-child(1) {
    transform: translate(-2rem, 26rem);
  }

  .wguard .feature .item:nth-child(2) {
    transform: translate(17rem, -9rem);
  }

  .wguard .feature .item:nth-child(3) {
    transform: translate(62rem, -26rem);
  }

  .wguard .feature .item:nth-child(4) {
    transform: translate(107rem, -9rem);
  }

  .wguard .feature .item:nth-child(5) {
    transform: translate(126rem, 26rem);
  }

  .wguard .cir-r .bg {
    background: url(../../public/images/product/security/cir_bg.png)no-repeat center top 1rem / 100% 100%;
  }

  .wguard .cir-r .cir-bar {
    margin-top: 0;
    scale: 1;
  }

  .wips .governing2 .title p {
    width: 34%;
  }

  .wips .governing2 .box > li {
    justify-content: initial;
    height: 36rem;
  }

  .wips .governing2 .box ul li {
    flex: none;
    height: fit-content;
  }
  
  .addition > .sub-inner > ul {
    margin: 0 auto;
  }

  .integrated .governing2 .title p {
    font-size: 2rem;
  }

  .integrated .function .inner p {
    font-size: 2rem;
  }

  .form-item .item-wrap .title label {
    font-size: 1.8rem;
  }

  .item-wrap .content input[type="text"],
  .item-wrap .content input[type="email"],
  .item-wrap .content select,
  .item-wrap .content textarea {
    font-size: 1.8rem;
  }

  .address .map {
    height: 56rem;
    margin-bottom: 10rem;
  }

  .address-info > p {
    font-size: 4rem;
  }

  .address-info > ul {
    width: 50%;
  }
}

@media all and (min-width:160em) {
  .sub .lnb a span,
  .sub-tab button {
    font-size: 2rem;
  }

  .sub-tab {
    margin: 4rem 0;
  }

  .sub-tab button {
    padding: 1rem 4rem;
  }

  .about .content {
    width: 88%;
    margin-left: 12%;
    padding: 8rem 20% 8rem 6%;
  }

  .org .line-group {
    margin-left: 18rem;
  }

  .org .content-area {
    margin-left: 10rem;
  }

  .org .ceo {
    transform: translateX(0);
  }

  .org-list {
    left: 46%;
  }

  .talent-list {
    row-gap: 4rem;
  }

  .talent-list div {
    height: 40rem;
    padding: 4rem;
  }

  .talent-list li strong {
    font-size: 3.2rem;
  }

  .talent-list li p:last-child {
    font-size: 2rem;
  }

  .step .swiper-slide,
  .step-item {
    height: 30rem;
  }

  .step-item {
    width: 99%;
    padding: 5.6rem 6rem;
  }

  .swiper-slide:not(:first-child) .step-item {
    padding-left: 10rem;
  }

  .step-item .after {
    right: -5.9rem;
    width: 7rem;
  }

  .step .btn-bl-bk a {
    width: 30rem;
  }

  .trend .sc-tit, .trend .sc-tit span {
    font-size: 6.4rem;
  }

  .trend .sc-txt {
    width: 40%;
    font-size: 2rem;
  }

  .trend .area > div:first-child::after{
    top: 55%;
    width: 27rem;
    height: 24rem;
    margin-left: 80rem;
  }

  .business-info .info-list > li {
    max-width: 70%;
  }

  .business-info .info-list > li .bg {
    height: 70rem;
  }

  .business-info .info-list > li .con {
    width: 80rem;
    margin-top: -20rem;
  }

  .business-info .info-list > li .con .num {
    margin-bottom: -2rem;
    font-size: 20rem
  }

  .business-info .info-list > li .con .tit {
    font-size: 5rem;
  }

  .business-info .info-list > li .con .txt {
    font-size: 2.4rem;
  }

  .business-info .info-list > li .con li {
    font-size: 2rem;
  }

  .Maintenance .governing .gif {
    max-width: 130rem;
  }

  .process .col,
  .process .col.active,
  .process .col .tit {
    width: 42rem;
    height: 42rem;
  }

  .process .col.active {
    margin-right: 36rem;
  }

  .process .col.active .area {
    width: 70%;
    margin-left: 44rem;
    padding: 0;
  }

  .process .col span {
    width: 7rem;
    height: 7rem;
    line-height: 7rem;
    font-size: 3rem;
  }

  .process .col .tit p {
    font-size: 3.2rem;
  }

  .process .area ul {
    width: fit-content;
    margin: 0;
  }

  .process li p {
    width: fit-content;
    font-size: 2rem;
  }

  .process .col:not(:last-child) .area::after {
    right: -4rem;
    width: 5rem;
    height: 5rem;
  }

  .NetworkBuilding .process .col,
  .NetworkBuilding .process .col.active,
  .NetworkBuilding .process .col .tit {
    width: 36rem;
    height: 36rem;
  }

  .NetworkBuilding .process .col.active {
    margin-right: 29rem;
  }

  .NetworkBuilding .process .col.active .area {
    margin-left: 38rem;
  }

  .w-diagram .sub-inner::before {
    margin-top: 15.5%;
  }

  .w-diagram .cir_b > div p {
    font-size: 4.8rem;
  }

  .w-diagram .cir_b > div span {
    font-size: 2.4rem;
  }

  .w-diagram .cir_b .sol li {
    width: 24rem;
    height: 24rem;
    font-size: 2.4rem;
  }

  .w-diagram .function .cir_m {
    width: 36rem;
    height: 36rem;
    padding: 6rem;
    font-size: 2.4rem;
  }

  .w-diagram .cir_b .sol li:nth-child(1) {
    transform: translate(-24rem, -24rem);
  }

  .w-diagram .cir_b .sol li:nth-child(2) {
    transform: translate(24rem, -24rem);
  }

  .w-diagram .cir_b .sol li:nth-child(3) {
    transform: translate(-24rem, 24rem);
  }

  .w-diagram .cir_b .sol li:nth-child(4) {
    transform: translate(24rem, 24rem);
  }

  .w-diagram .keyword ul {
    padding: 4rem;
    border-radius: 30rem;
  }

  .w-diagram .keyword li {
    width: 40rem;
    height: 40rem;
    font-size: 3rem;
  }

  .partner .sumnail {
    height: 60rem;
  }

  .spec td,
  .spec td p,
  .spec .p-name,
  .spec li,
  .wips .spec p span {
    font-size: 2.4rem;
  }

  .spec-slide .swiper-slide {
    height: 45rem;
  }

  .spec .spec-slide p {
    font-size: 3.2rem;
  }

  .spec .spec-slide li {
    font-size: 2rem;
  }

  .function li {
    height: 64rem;
    padding: 4rem 8rem;
  }

  .function .wrap span {
    width: 6rem;
    height: 6rem;
    margin-bottom: 6rem;
    line-height: 6rem;
    font-size: 2.4rem;
  }

  .function .wrap .tit {
    font-size: 3.2rem;
  }

  .function .wrap .txt {
    font-size: 2rem;
  }

  .function .wrap img {
    width: 8rem;
    height: 8rem;
  }

  .etc .graph-wrap > div:not(.bg) {
    scale: 1.4;
  }

  .etc .app {
    transform: translate(10rem, 35rem);
  }

  .etc .device {
    transform: translate(114rem, 1rem);
  }

  .etc .etc-product {
    transform: translate(114rem, 32rem);
  }

  .etc .graph-wrap .bg {
    max-width: 78.6%;
    height: 122rem;
  }

  .governing2 .product-img {
    left: 42%;
    width: 50%;
    max-width: initial;
  }

  .ruckus .governing .highlight:after {
    margin-top: 5.2rem;
  }

  .aruba .governing2 .product-img {
    left: 45%;
    width: 40%;
  }

  .coreEdge .governing2 {
    height: 130rem;
  }

  .coreEdge .governing2 .product-img {
    left: 30%;
    width: 45%;
    max-width: fit-content;
  }

  .governing2 .title h3 {
    font-size: 5.6rem;
  }

  .coreEdge .governing2 .title h3,
  .coreEdge .governing2 .title span {
    font-size: 7.2rem;
  } 

  .coreEdge .governing2 .title p {
    font-size: 3.2rem;
  }

  .governing2 .title p {
    font-size: 2.4rem;
  }

  .governing2 .content {
    margin-top: 44rem;
  }

  .governing2 .content li {
    height: auto;
    padding: 6rem;
  }

  .governing2 .content li > div p{
    font-size: 3.2rem;
  }

  .governing2 .content .icon {
    width: 8rem;
    height: 8rem;
  }

  .governing2 .content li > p {
    font-size: 2rem;
  }

  .Network .ruckus .governing h3 {
    margin-bottom: 24rem;
    font-size: 6.4rem;
  }

  .Network .ruckus .governing h3 img {
    height: 10rem;
  }

  .Network .ruckus .governing .content > div {
    width: 40rem;
    height: 40rem;
    padding: 6rem;
  }

  .Network .ruckus .governing .content .tit {
    font-size: 2.4rem;
  }

  .Network .ruckus .governing .content > div li {
    font-size: 1.8rem;
  }

  .Network .aruba .governing2 .content {
    margin-top: 44rem;
  }

  .Network .aruba .governing2 li > div > p {
    font-size: 4rem;
  }

  .Network .aruba .product-info .governing2 .content p {
    font-size: 2.4rem;
  }

  .Network .coreEdge .governing2 {
    background: url(../../public/images/product/coreedge_bg.png)no-repeat left 29rem bottom 12rem / 56% 44%,
                url(../../public/images/product/gv_gray.png)no-repeat center bottom / 100% 72%;
  }

  .coreEdge .governing2 .product-img {
    margin-top: 10rem;
  }

  .coreEdge .line-up-dev li:not(:last-child) .tit::after {
    margin-left: 45rem;
  }

  .Network .grandstream .governing2 .product-img {
    max-width: fit-content;
  }

  .Network .grandstream .governing2 .title img {
    height: 6.4rem;
  }

  .Network .grandstream .governing2 .title h3 {
    font-size: 5.6rem;
  }

  .Network .grandstream .governing2 .title h3 span {
    font-size: 6.4rem;
  }

  .Network .grandstream .governing2 .content {
    margin-top: 30rem;
  }

  .Wlan .ruckus .governing .sub-inner {
    width: 30%;
    margin-top: 24rem;
    margin-left: calc((100% - 76.8%) / 2);
    padding: 6rem;
    border-radius: 2rem;
    scale: 1.2;
  }

  .Wlan .ruckus .governing h3 {
    margin-bottom: 12rem;
  }

  .Wlan .ruckus .governing h3,
  .product-info .governing h3 .highlight {
    font-size: 6rem;
  }

  .Wlan .aruba .governing2 .product-img {
    width: 45%;
    margin-top: 32rem;
  }

  .Wlan .aruba .governing2 .title img {
    height: 8rem;
  }

  .Wlan .aruba .governing2 .title h3 {
    font-size: 7.2rem;
  }

  .Wlan .aruba .governing2 .title p {
    font-size: 3rem;
  }

  .aruba .merit div > p:first-child {
    margin-bottom: 4rem;
    font-size: 6.4rem;
  }

  .aruba .merit div > p:last-child {
    font-size: 4rem;
  }

  .aruba .merit li {
    width: 56rem;
    height: 56rem;
    padding: 8rem;
  }

  .aruba .merit li .tit {
    font-size: 3.2rem;
  }

  .merit li {
    padding: 4rem;
  }

  .merit li div p {
    font-size: 3.2rem;
  }

  .merit li > p {
    font-size: 2rem;
  }

  .link-item a {
    padding: 0.8rem 3.2rem;
    font-size: 2rem;
  }

  .link-item a span {
    width: 2rem;
    height: 2rem;
  }

  .Wlan .grandstream .governing2:first-child,
  .Wlan .grandstream .governing2:nth-child(3) {
    height: 144rem;
  }

  .Wlan .grandstream .governing2 h3,
  .Wlan .grandstream .governing2:nth-child(3) h3 {
    font-size: 6.4rem;
  }

  .Wlan .grandstream .governing2 .title p,
  .Wlan .grandstream .governing2:nth-child(3) .title p,
  .Wlan .grandstream .governing2 .content p,
  .Wlan .grandstream .governing2:nth-child(3) .content p {
    font-size: 2.4rem;
  }

  .Wlan .grandstream .governing2 .content {
    margin-top: 44rem;
  }

  .Wlan .grandstream .governing2:nth-child(3) .title {
    margin-top: 24rem;
  }

  .Wlan .grandstream .governing2:nth-child(3) .content {
    margin-top: 50rem;
  }

  .Wlan .grandstream .governing2:nth-child(1) .title p {
    width: 34%;
  }

  .firewall .governing2 .title h3,
  .firewall .governing2 .title h3 span {
    font-size: 6.4rem;
  }

  .axgate .governing2 .content {
    width: 30%;
    margin-top: 17rem;
  }

  .firewall .governing2 .content .tit,
  .firewall .governing2 .content .txt { 
    font-size: 2.4rem;
  }

  .firewall .function .inner-scroll {
    height: fit-content;
  }

  .firewall .function .content > ul > li {
    height: 83rem;
  }

  .firewall .function li li {
    font-size: 2rem;
  }

  .wguard .governing2 .content {
    width: 36%;
    margin-top: 28rem;
  }

  .utm .content::before {
    height: 16rem;
    margin-top: 10rem;
  }

  .utm li div:first-child{
    padding: 6rem;
    font-size: 3.2rem;
  }

  .utm li div:last-child {
    font-size: 2rem;
  }

  .utm li:nth-child(even) div:first-child::before,
  .utm li:nth-child(odd) div:first-child::before {
    margin-top: 20rem;
  }

  .wguard .feature {
    height: 100vh;
  }

  .wguard .feature .item {
    justify-content: space-evenly;
    width: 32rem;
    height: 32rem;
    padding: 6rem;
  }

  .wguard .feature .item:nth-child(1) {
    transform: translate(5rem, 53rem);
  }

  .wguard .feature .item:nth-child(2) {
    transform: translate(30rem, 6rem);
  }

  .wguard .feature .item:nth-child(3) {
    transform: translate(92rem, -19rem);
  }

  .wguard .feature .item:nth-child(4) {
    transform: translate(156rem, 6rem);
  }

  .wguard .feature .item:nth-child(5) {
    transform: translate(180rem, 53rem);
  }

  .wguard .feature .item > span {
    min-width: 7rem;
    min-height: 7rem;
    margin-bottom: 2rem;
  }

  .wguard .feature .item p:nth-child(2) {
    font-size: 2rem;
  }

  .wguard .feature .item p span {
    font-size: 2.4rem;
  }

  .wguard .feature .item p:nth-child(3) {
    font-size: 1.8rem;
  }

  .wguard .feature {
    margin-left: -5%;
  }

  .wguard .feature .content,
  .wguard .feature .cir-r {
    height: 83rem;
  }

  .wguard .feature .cir-r {
    width: inherit;
    height: inherit;
  }

  .wguard .cir-r .bg {
    align-items: center;
    height: 63%;
    margin-top: 21rem;
    background: url(../../public/images/product/security/cir_bg.png) no-repeat left 57.6% top 3rem / 50% auto;
    scale: 1.4;
  }

  .wguard .cir-r .cir-bar {
    margin: 0 0 0 15rem;
    scale: 0.96;
  }

  .cir-r .proc-img {
    width: 31%;
  }

  .wips .governing2 .product-img {
    left: 48%;
    width: 42%;
  }

  .wips .governing2 .title h3,
  .wips .governing2 .title h3 span {
    font-size: 6.4rem;
  }

  .wips .governing2 .title p {
    width: 25%;
  }

  .wips .governing2 .box div p {
    font-size: 4rem;
  }

  .wips .governing2 .box > li {
    height: 42rem;
  }

  .wips .governing2 .box li li {
    font-size: 2rem;
  }

  .addition > .sub-inner > ul {
    justify-content: space-between;
    width: 100%;
  }

  .addition > .sub-inner > ul > li {
    width: calc((100% + 16rem) / 3);
    height: auto;
    aspect-ratio: 1/1;
    padding: 12rem;
    box-sizing: border-box;
  }

  .addition > .sub-inner > ul > li:nth-child(2) {
    margin: 0 -8rem;
  }

  .addition p {
    padding-bottom: 4rem;
    margin-bottom: 4rem;
    font-size: 3.2rem;
  }

  .addition li li {
    font-size: 2rem;
  }

  .wips .function li {
    width: calc((100% - 12rem) / 4);
    height: 50rem;
  }

  .integrated .governing2 .title h3,
  .integrated .governing2 .title h3 span {
    font-size: 6.4rem;
  }

  .integrated .governing2 .title p {
    width: 34%;
  }

  .integrated .merit ul {
    justify-content: flex-start;
  }

  .integrated .merit li {
    width: calc((100% - 9.6rem) / 4);
    height: 36rem;
  }

  .address .title h3 {
    font-size: 5.4rem;
  }

  .address-info > p {
    font-size: 4.8rem;
  } 

  .address li label,
  .address li p,
  .address li p span {
    font-size: 2rem;
  }
}
