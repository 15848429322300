.guide {
	position: absolute;
	width: 100%;
}

#scroll-down-animation {
  position: fixed;
  bottom: 4rem;
  left: 50%;
	z-index: 9;
  transform: translateX(-50%);
	transition: all .2s;
}

#scroll-down-animation h2 {
	margin-top: .8rem;
  color: #212121;
  font-weight: 300;
  font-size: 1.2rem;
}

#scroll-down-animation .mouse {
  margin: 0 auto;
  display: block;
  border-radius: 2rem;
  border: .2rem solid #212121;
  height: 4.8rem;
  width: 2.8rem;
  position: relative;
}

#scroll-down-animation .move {
  position: absolute;
  background-color: #212121;
  height: .8rem;
  width: .8rem;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  animation: move 1.5s linear infinite;
}

#scroll-down-animation.action {
	opacity: 0;
	transform: translate(-50%, 4rem);
}

@keyframes move {
  0% {
    transform: translate(-50%, 0);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, 1.6rem);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 3.2rem);
    opacity: 0;
  }
}

/* Header Style */
.jsoftlab .header-wrap {
	background-color: rgba(255, 255, 255, .5);
	transition: all 0.2s;
}

.jsoftlab .header-wrap.open {
	background-color: transparent;
}

.jsoftlab .header-wrap h1 img {
	filter: brightness(.3);
}

.jsoftlab .header-wrap.open h1 img {
	filter: brightness(1);
}

.jsoftlab .header.active {
	background-color: inherit;
}

.jsoftlab .header.active h1 img {
	filter: brightness(1);
}

.jsoftlab .header-wrap .nav-icon span {
	background-color: rgba(0, 0, 0, .5);
}

.jsoftlab .header-wrap.open .nav-icon span {
	background-color: #FFF;
}

.jsoftlab .header.active .nav-icon span {
	background-color: #FFF;
}

.jsoftlab {
	background-color: #f5f5f5;
}

.jsl-wrap .section {
	position: relative;
	width: 100%;
}

.jsl-wrap .container {
	position: relative;
	width: 100%;
	max-width: 76.8rem;
	margin: 0 auto;
}

@media all and (min-width:80em) {
	.jsoftlab .header .header-list a {
		color: #101010;
	}

	.jsoftlab .header.active .header-list a {
		color: #FFFFFF;
	}

	.jsoftlab .header-wrap.open .header-list a {
		color: #FFFFFF;
	}
}

@media all and (min-width:96em) {
	.jsl-wrap .container {
		max-width: 128rem;
	}
}

/* Line Set */
.jsoftlab .line-set {
	display: none;
}

@media all and (min-width:96em) {
	.jsoftlab .line-set {
		display: inline-block;
		position: absolute;
		z-index: 3;
		width: 100%;
	}

	.jsoftlab .line-set img {
		position: absolute;
		left: calc(50% - 3.2rem);
	}

	.jsoftlab .line-set .diAp {
		top: 26.3rem;
		transform: translateX(-34.1rem);
	}

	.jsoftlab .line-set .diBuild {
		top: 6.8rem;
		transform: translateX(-51.3rem);
	}

	.jsoftlab .line-set .diMobile {
		top: 49.6rem;
		transform: translateX(6.6rem);
	}

	.jsoftlab .line-set .diIpp {
		top: 16.7rem;
		transform: translateX(31rem);
	}

	.jsoftlab .line-set .diServer {
		top: 35.7rem;
		transform: translateX(47.2rem);
	}

	.jsoftlab .line-set .diSwitchpoe {
		top: 16.9rem;
		transform: translateX(63.4rem);
	}

	.jsoftlab .line-set svg {
		position: absolute;
		left: 50%;
		width: 128rem;
		text-align: center;
		transform: translateX(-64rem);
	}

	.jsoftlab .line-set svg path {
		stroke-dasharray: 1000;
		stroke-dashoffset: 1000;
	}

	.jsoftlab .line-set .step1 svg {
		height: 240rem;
	}

	.jsoftlab .line-set .step1 svg path {
		stroke: #616161;
	}

	.jsoftlab .line-set .step1 svg.line01 path,
	.jsoftlab .line-set .step1 svg.line03 path,
	.jsoftlab .line-set .step1 svg.line06 path {
		stroke: #a1a1a1;
	}

	.jsoftlab .line-set .step2 svg {
		top: 240rem;
		height: 96rem;
	}

	.jsoftlab .line-set .step2 svg.line01 path {
		stroke: #414141;
	}

	.jsoftlab .line-set .step2 svg.line02 path {
		stroke: #56B78F;
	}

	.jsoftlab .line-set .step2 svg.line03 path {
		stroke: #176DC3;
	}

	.jsoftlab .line-set .step2 svg.line04 path {
		stroke: #6757A7;
	}

	.jsoftlab .line-set .step2 svg.line05 path {
		stroke: #FFAC00;
	}

	.jsoftlab .line-set .step2 svg.line06 path {
		stroke: #818181;
	}

	.jsoftlab .line-set .step3 svg {
		top: 336rem;
		height: 96rem;
	}

	.jsoftlab .line-set .step4 svg {
		top: 528rem;
		height: 96rem;
	}

	.jsoftlab .line-set .step5 svg {
		top: 624rem;
		height: 96rem;
	}
}


/* Display */
.jsoftlab .display {
	min-height: 16rem;
	background-image: url(/src/pages/company/jsoftlab/images/display_bg.jpg);
	background-position: top center;
	background-size: 190%;
	background-repeat: no-repeat;
}

@media all and (min-width:48em) {
	.jsoftlab .display {
		min-height: 28rem;
	}
}

@media all and (min-width:80em) {
	.jsoftlab .display {
		background-size: 120%;
	}
}

@media all and (min-width:96em) {
	.jsoftlab .display {
		min-height: 72rem;
		background-size: 256rem;
	}
}


/* About JSL */
.jsoftlab .about-jsl {
	position: relative;
	left: 50%;
	z-index: 3;
	width: 100%;
	padding-bottom: 8rem;
	overflow: hidden;
	transform: translateX(-50%);
}

.jsoftlab .about-jsl .cf-wrap {
	z-index: 9;
	width: 32rem;
	height: 32rem;
	margin: 0 auto;
}

.jsoftlab .about-jsl .cf-left {
	position: relative;
	width: auto;
	height: calc((57.6rem / 4) * 2.5);
}

.jsoftlab .about-jsl .cf-left img {
	width: 100%;
	filter: drop-shadow(.8rem .8rem 2.4rem rgba(0, 0, 0, .15));
}

.jsoftlab .about-jsl .cf-left h2 {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	font-size: 2.8rem;
	font-weight: 200;
	letter-spacing: -0.14rem;
	transform: translate(3.2rem, 6.4rem);
}

.jsoftlab .about-jsl .cf-left h2 strong {
	font-size: 3.2rem;
	font-weight: 600;
}

.jsoftlab .about-jsl .cf-left h2 span {
	font-size: 2rem;
	font-weight: 500;
	color: rgba(0, 0, 0, .7);
	letter-spacing: -.19rem;
}

.jsoftlab .about-jsl .cf-left h2 small {
	margin-left: .4rem;
	font-size: 1.6rem;
}

.jsoftlab .about-jsl .cf-left .cb-003 {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: auto;
	height: calc((1.2rem / 4) * 2.5);
	transform: translate(3.2rem, 14.4rem);
}

.jsoftlab .about-jsl .cf-left span.desc {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	line-height: 1.6rem;
	font-size: 1.1rem;
	transform: translate(3.2rem, 16.8rem);
}

.jsoftlab .about-jsl .cf-left span.desc strong {
	font-size: 1.1rem;
	font-weight: 600;
}

.jsoftlab .about-jsl .cf-left .cb-001 {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 1;
	width: auto;
	height: calc((40rem / 4) * 2.5);
	transform: translate(3.2rem, -3.2rem);
}

.jsoftlab .about-jsl .cf-left .cb-002 {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 1;
	width: auto;
	height: calc((7.4rem / 4) * 2.5);
	transform: translate(-.8rem, -4.8rem);
}

.jsoftlab .about-jsl .cb-cube {
	position: absolute;
	right: 0;
	z-index: 3;
	width: auto;
	height: calc((32rem / 4) * 2.5);
	transform: translate(8.2rem, -26.4rem);
}

.jsoftlab .about-jsl .cf-right {
	display: none;
}

@media all and (min-width: 48em) {
	.jsoftlab .about-jsl .cf-wrap {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		column-gap: .4rem;
		width: auto;
		height: auto;
	}

	.jsoftlab .about-jsl .cf-left h2 {
		font-size: 3.2rem;
		transform: translate(4rem, 6.4rem);
	}
	
	.jsoftlab .about-jsl .cf-left h2 strong {
		font-size: 3.6rem;
	}
	
	.jsoftlab .about-jsl .cf-left h2 span {
		font-size: 2.4rem;
	}
	
	.jsoftlab .about-jsl .cf-left h2 small {
		font-size: 1.8rem;
	}

	.jsoftlab .about-jsl .cf-left span.desc {
		line-height: 1.8rem;
		font-size: 1.2rem;
		transform: translate(4rem, 17.8rem);
	}

	.jsoftlab .about-jsl .cf-left .display-left-bg{
		width: 100%;
		height: 100%;
	}

	.jsoftlab .about-jsl .cf-right {
		display: inline-block;
		position: relative;
		width: auto;
		height: calc((44rem / 4) * 2.5);
	}

	.jsoftlab .about-jsl .cf-right .display-right-bg{
		width: 100%;
		height: 100%;
	}

	.jsoftlab .about-jsl .cf-left .cb-001 {
		height: calc((40rem / 4) * 3);
		transform: translate(3.6rem, 1rem);
	}
	
	.jsoftlab .about-jsl .cf-left .cb-002 {
		transform: translate(-.8rem, -.8rem);
	}

	.jsoftlab .about-jsl .cf-left .cb-003 {
		transform: translate(4rem, 15.2rem);
	}

	.jsoftlab .about-jsl .cb-cube {
		top: 50%;
		right: 50%;
		height: calc((32rem / 4) * 3);
		transform: translate(16.4rem, -8rem);
	}

	.jsoftlab .about-jsl .cf-right .cb-004,
	.jsoftlab .about-jsl .cf-right .cb-005,
	.jsoftlab .about-jsl .cf-right .cb-006,
	.jsoftlab .about-jsl .cf-right .cb-007,
	.jsoftlab .about-jsl .cf-right .cb-008,
	.jsoftlab .about-jsl .cf-right .jnms-emblem {
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 1;
		width: auto;
	}

	.jsoftlab .about-jsl .cf-right .jnms-emblem {
		top: 0;
		left: 0;
		bottom: inherit;
		right: inherit;
		height: calc((8rem / 4) * 3);
		transform: translate(2.4rem, 2.4rem);
	}

	.jsoftlab .about-jsl .cf-right .cb-004 {
		left: 0;
		right: inherit;
		height: calc((3.2rem / 4) * 3);
		transform: translate(.8rem, -.8rem);
	}

	.jsoftlab .about-jsl .cf-right .cb-005 {
		top: 0;
		bottom: inherit;
		height: calc((8rem / 4) * 3);
		transform: translate(-.8rem, .8rem);
	}

	.jsoftlab .about-jsl .cf-right .cb-006 {
		z-index: 3;
		height: calc((4rem / 4) * 3);
		transform: translate(-1.6rem, -13rem);
	}

	.jsoftlab .about-jsl .cf-right .cb-007 {
		height: calc((20rem / 4) * 3);
		transform: translate(-.8rem, -2.4rem);
	}

	.jsoftlab .about-jsl .cf-right .cb-008 {
		top: 0;
		bottom: inherit;
		height: calc((1.6rem / 4) * 3);
		transform: translate(-.8rem, -8rem);
	}
}

@media all and (min-width: 96em) {
	.jsoftlab .about-jsl {
		padding-bottom: 14.4rem;
	}

	.jsoftlab .about-jsl .cf-left {
		height: 57.6rem;
	}
	
	.jsoftlab .about-jsl .cf-left h2 {
		font-size: 4.8rem;
		transform: translate(6.4rem, 14.4rem);
	}

	.jsoftlab .about-jsl .cf-left h2 strong {
		line-height: 5.6rem;
		font-size: 5.6rem;
		letter-spacing: -.2rem;
	}

	.jsoftlab .about-jsl .cf-left h2 span,
	.jsoftlab .about-jsl .cf-left h2 small {
		font-size: 3.2rem;
		/* letter-spacing: inherit; */
	}

	.jsoftlab .about-jsl .cf-left span.desc {
		line-height: 2.4rem;
		font-size: 1.4rem;
		transform: translate(6.4rem, 31.2rem);
	}

	.jsoftlab .about-jsl .cf-left span.desc strong {
		font-size: 1.4rem;
	}

	.jsoftlab .about-jsl .cb-cube {
		height: 36rem;
		transform: translate(25rem, -12rem);
	}

	.jsoftlab .about-jsl .cf-right {
		height: 44rem;
	}

	.jsoftlab .about-jsl .cf-right .jnms-emblem {
		height: 12rem;
		transform: translate(2.4rem, 4.4rem);
	}

	.jsoftlab .about-jsl .cf-left .cb-001 {
		height: 40rem;
		transform: translate(2.4rem, -.8rem);
	}

	.jsoftlab .about-jsl .cf-left .cb-002 {
		width: 1.6rem;
		height: auto;
		transform: translate(-1.6rem, -1.6rem);
	}

	.jsoftlab .about-jsl .cf-left .cb-003 {
		height: 1.2rem;
		transform: translate(6.4rem, 27.4rem);
	}

	.jsoftlab .about-jsl .cf-right .cb-004 {
		height: 4rem;
		transform: translate(1.6rem, -1.6rem);
	}

	.jsoftlab .about-jsl .cf-right .cb-005 {
		height: 10rem;
		transform: translate(-1.6rem, 1.6rem);
	}

	.jsoftlab .about-jsl .cf-right .cb-006 {
		height: 4rem;
		transform: translate(-3.2rem, -25.6rem);
	}

	.jsoftlab .about-jsl .cf-right .cb-007 {
		height: 24rem;
	}

	.jsoftlab .about-jsl .cf-right .cb-008 {
		height: 1.6rem;
		transform: translate(-1.6rem, -12rem);
	}
}
		


/* About JSL */
.jsoftlab .jnms-info {
	position: relative;
	display: flex;
	justify-content: center;
	z-index: 3;
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
}

.jsoftlab .jnms-info .jnms {
	position: relative;
	width: 32rem;
	margin: 0 auto 8rem;
	transform: translateY(4rem);
}

.jsoftlab .jnms-info .jnms svg {
	width: 100%;
}

.jsoftlab .jnms-info .jnms svg path {
	stroke: black;
	stroke-width: .1rem;
}

.jsoftlab .jnms-info .wisight {
	position: absolute;
	top: 0;
	left: calc(50% - 18rem);
	width: 36rem;
	margin: 0 auto;
}

.jsoftlab .jnms-info .wisight img {
	width: 100%;
}

.jsoftlab .jnms-info .input-motion-wrap {
	position: relative;
}

.jsoftlab .jnms-info .input-motion::before {
	content: '';
	position: absolute;
	top: 9.6rem;
	left: calc(50% - 12rem);
	z-index: -1;
	width: 24rem;
	height: 5.6rem;
	border: .1rem solid #000000;
	background-color: #F7F7F7;
}

.jsoftlab .jnms-info .input-motion {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	row-gap: 1rem;
	width: 24rem;
	margin: 0 auto;
}

.jsoftlab .jnms-info .input-motion span {
	position: absolute;
	width: 100%;
	font-weight: 500;
	text-align: center;
	animation: textRolling 6s ease-in-out infinite;
	opacity: 0;
}

.jsoftlab .jnms-info .input-motion span:first-of-type {
	animation-delay: 0;
}

.jsoftlab .jnms-info .input-motion span:nth-of-type(2) {
	animation-delay: 1s;
}

.jsoftlab .jnms-info .input-motion span:nth-of-type(3) {
	animation-delay: 2s;
}

.jsoftlab .jnms-info .input-motion span:nth-of-type(4) {
	animation-delay: 3s;
}

.jsoftlab .jnms-info .input-motion span:nth-of-type(5) {
	animation-delay: 4s;
}

.jsoftlab .jnms-info .input-motion span:last-of-type {
	animation-delay: 5s;
}

@keyframes textRolling {
	0% {
		top: -2.4rem;
		line-height: 3.2rem;
		font-size: 2.4rem;
		opacity: .15;
	}
	20% {
		top: 2.4rem;
		line-height: 4rem;
		font-size: 3.2rem;
		opacity: .5;
	}
	40% {
		top: 9.2rem;
		line-height: 5.6rem;
		font-size: 4rem;
		opacity: 1;
	}
	60% {
		top: 16rem;
		line-height: 4rem;
		font-size: 3.2rem;
		opacity: .5;
	}
	80% {
		top: 20.8rem;
		line-height: 3.2rem;
		font-size: 2.4rem;
		opacity: .15;
	}
	100% {
		top: 23.2rem;
		line-height: 3.2rem;
		font-size: 2.4rem;
		opacity: 0;
	}
}

.jsoftlab .jnms-info .input-motion-wrap .nms {
	display: block;
	position: absolute;
	top: 27rem;
	width: 100%;
	font-size: 2.4rem;
	font-weight: 300;
	text-align: center;
}

.jsoftlab .jnms-info .input-motion-wrap .nms small {
	display: none;
}

.jsoftlab .jnms-info .wi-mockup-wrap {
	position: absolute;
	min-height: 36rem;
	right: 0;
	bottom: 0;
}

.jsoftlab .jnms-info .wi-mockup-wrap .gs-mark {
	height: 3.2rem;
}

.jsoftlab .jnms-info .wi-mockup-wrap .wi-mockup {
	position: absolute;
	bottom: 0;
	right: 0;
	width: auto;
	height: 32rem;
}

.jsoftlab .jnms-info .wi-mockup-wrap span {
	display: block;
	position: absolute;
	bottom: 5.6rem;
	right: 17.2rem;
	width: 12.8rem;
	line-height: 1.6rem;
	font-size: 1.1rem;
	text-align: right;
}

.jsoftlab .jnms-info .wi-mockup-wrap span small {
	display: block;
}

.jsoftlab .jnms-info .wi-mockup-wrap .gs-mark {
	position: absolute;
	right: 17.2rem;
	bottom: 11.2rem;
}

@media all and (min-width:48em) {
	.jsoftlab .jnms-info {
		min-height: 72rem;
		height: fit-content;
	}
	.jsoftlab .jnms-info .container {
		margin: 0 auto;
	}

	.jsoftlab .jnms-info .input-motion-wrap .nms {
		bottom: -9.6rem;
		font-size: 3.2rem;
	}

	.jsoftlab .jnms-info .input-motion-wrap .nms small {
		display: inline-block;
		margin-left: .4rem;
		font-size: 2.4rem;
	}
}

@media all and (min-width: 96em) {
	.jsoftlab .jnms-info {
		height: 96rem;
	}

	.jsoftlab .jnms-info .container {
		max-width: 100%;
	}

	.jsoftlab .jnms-info .jnms {
		z-index: 3;
		width: auto;
		height: 26rem;
		margin: 0 auto;
		transform: translate(-.35rem, 7.2rem);
	}

	.jsoftlab .jnms-info .jnms svg {
		height: 100%;
	}

	.jsoftlab .jnms-info .jnms svg path {
		fill: #f3f3f3;
	}

	.jsoftlab .jnms-info .wisight {
		left: calc(50% - 72rem);
		width: auto;
		height: 24rem;
	}

	.jsoftlab .jnms-info .wisight img {
		width: auto;
		height: 100%;
		opacity: .5;
	}

	.jsoftlab .jnms-info .input-motion-wrap {
		position: absolute;
		bottom: 17.6rem;
		width: 100%;
		height: 24rem;
	}

	.jsoftlab .jnms-info .input-motion {
		transform: translateX(-23.5rem);
	}

	.jsoftlab .jnms-info .input-motion-wrap .nms {
		left: 50%;
		bottom: 0;
		top: inherit;
		width: fit-content;
		line-height: 24rem;
		font-size: 4rem;
		transform: translateX(-9.9rem);
	}

	.jsoftlab .jnms-info .input-motion-wrap .nms small {
		font-size: 4rem;
		font-weight: 100;
	}

	.jsoftlab .jnms-info .wi-mockup-wrap {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.jsoftlab .jnms-info .wi-mockup-wrap .wi-mockup {
		height: 64rem;
	}

	.jsoftlab .jnms-info .wi-mockup-wrap .gs-mark {
		right: 36rem;
		bottom: 16rem;
		height: 4.8rem;
	}

	.jsoftlab .jnms-info .wi-mockup-wrap span {
		right: 36rem;
		bottom: 9.6rem;
		width: 32rem;
		line-height: 2.4rem;
		font-size: 1.6rem;
	}

	.jsoftlab .jnms-info .wi-mockup-wrap span small {
		line-height: 2.4rem;
		font-size: 1.6rem;
		opacity: .5;
	}
}


/* Features */
.jsoftlab .features {
	position: relative;
	width: 100%;
	background-color: #252627;
}

.jsoftlab .features .container {
	display: flex;
	flex-wrap: wrap;
	row-gap: 0;
	align-content: space-around;
	height: 100vh;
	padding: 10vh 1.6rem;
}

.jsoftlab .features .sec-title {
	position: absolute;
	top: 0;
	left: -1.6rem;
	font-family: 'Poppins';
	font-weight: 600;
	font-size: 8rem;
	color: #333333;
	text-transform: uppercase;
	letter-spacing: -.5rem;
}

.jsoftlab .features dl {
	z-index: 6;
}

.jsoftlab .features dt {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	padding-bottom: 1.6rem;
	margin-bottom: 1.6rem;
}

.jsoftlab .features dt strong {
	font-size: 1.8rem;
	font-weight: 500;
	color: #FFF;
	text-transform: uppercase;
}

.jsoftlab .features dl:first-of-type dt {
	border-bottom: .1rem solid #818181;
}

.jsoftlab .features dl:nth-of-type(2) dt {
	border-bottom: .1rem solid #FFAC00;
}

.jsoftlab .features dl:nth-of-type(3) dt {
	border-bottom: .1rem solid #6757A7;
}

.jsoftlab .features dt::after {
	content: '';
	position: absolute;
	bottom: -.3rem;
	right: -.2rem;
	width: .5rem;
	height: .5rem;
	border-radius: .2rem;
}

.jsoftlab .features dl:first-of-type dt::after {
	background-color: #818181;
}

.jsoftlab .features dl:nth-of-type(2) dt::after {
	background-color: #FFAC00;
}

.jsoftlab .features dl:nth-of-type(3) dt::after {
	background-color: #6757A7;
}

.jsoftlab .features dl dt span {
	display: none;
	margin-left: 1.6rem;
	font-size: 1.2rem;
}

.jsoftlab .features dl:first-of-type dt span {
	color: #818181;
}

.jsoftlab .features dl:nth-of-type(2) dt span {
	color: #FFAC00;
}

.jsoftlab .features dl:nth-of-type(3) dt span {
	color: #6757A7;
}

.jsoftlab .features dd {
	line-height: 1.8rem;
	font-size: 1.2rem;
	color: #A1A1A1;
}

@media all and (min-width:48em) {
	.jsoftlab .features .container {
		display: flex;
		flex-wrap: wrap;
		row-gap: 8rem;
		align-content: space-around;
		height: fit-content;
		padding: 12rem 3.2rem;
	}

	.jsoftlab .features .sec-title {
		top: 12rem;
		left: 3.2rem;
		writing-mode: vertical-lr;
	}

	.jsoftlab .features dl {
		margin-left: 16rem;
	}

	.jsoftlab .features dt strong {
		min-width: 16rem;
	}

	.jsoftlab .features dl dt span {
		display: inline-block;
	}
}

@media all and (min-width:96em) {
	.jsoftlab .features .container {
		padding: 16rem 0;
	}

	.jsoftlab .features .sec-title {
		top: 16rem;
		left: 0;
	}

	.jsoftlab .features dl {
		margin-left: inherit;
		width: 80rem;
		min-height: 16rem;
	}

	.jsoftlab .features dt strong {
		line-height: 2.4rem;
		font-size: 2.4rem;
	}

	.jsoftlab .features dl dt span {
		line-height: 2.4rem;
		font-size: 1.8rem;
	}

	.jsoftlab .features dl dt {
		border-bottom: 0 !important;
	}

	.jsoftlab .features dt::after {
		display: none;
	}

	.jsoftlab .features dd {
		line-height: 2.4rem;
		font-size: 1.4rem;
	}
}


/* Line Up */
.jsoftlab .services {
	position: relative;
	width: 100%;
	min-height: 100vh;
	background-color: #1C1D1E;
}

.jsoftlab .services .container {
	display: flex;
	flex-wrap: wrap;
	row-gap: 0;
	align-content: space-around;
	min-height: 100vh;
	padding: 16vh 0 8vh;
}

.jsoftlab .services .sec-title {
	position: absolute;
	top: 0;
	left: -1.6rem;
	font-family: 'Poppins';
	font-weight: 600;
	font-size: 8rem;
	color: #252627;
	text-transform: uppercase;
	letter-spacing: -.5rem;
}

.jsoftlab .services .contents-wrap {
	width: 100%;
	padding: 0;
}

.jsoftlab .services .item {
	padding: 3.2rem;
	margin-bottom: .8rem;
	transition: all .2s;
}

.jsoftlab .services .item:nth-of-type(4),
.jsoftlab .services .item:nth-of-type(5) {
	padding-bottom: 0;
	margin-bottom: 0;
}

.jsoftlab .services .item:nth-of-type(6) {
	padding-top: 0;
}

.jsoftlab .services .item:last-of-type {
	margin-bottom: 0;
}

.jsoftlab .services .item .m-title {
	margin-bottom: .8rem;
	font-family: 'Poppins', sans-serif;
	font-size: 2.4rem;
	font-weight: 800;
	color: #FFF;
}

.jsoftlab .services .item strong {
	line-height: 1.8rem;
	font-size: 1.3rem;
	color: #FFF;
}

.jsoftlab .services .item li {
	line-height: 1.8rem;
	font-size: 1.2rem;
	font-weight: 400;
	color: #FFF;
}

.jsoftlab .services .item span.plus {
	font-size: 2.4rem;
	font-weight: 100;
	color: #FFF;
}

.jsoftlab .services .item .title {
	margin-bottom: 1.6rem;
	font-family: 'Poppins', sans-serif;
	font-size: 2rem;
	color: #FFF;
}

.jsoftlab .services .item span {
	line-height: 1.8rem;
	font-size: 1.2rem;
	font-weight: 400;
	color: #FFF;
}

.jsoftlab .services .item.inms,
.jsoftlab .services .item.wnms,
.jsoftlab .services .item.snms {
	background-color: rgba(16, 16, 16, .3);
}

.jsoftlab .services .item.option {
	max-width: 32rem;
	margin: .8rem auto;
	border: .05rem dashed rgba(255, 255, 255, .2);
	border-radius: .8rem;
}

.jsoftlab .services .item span strong {
	font-weight: 400;
}

@media all and (min-width:48em) {
	.jsoftlab .services .container {
		display: flex;
		flex-wrap: wrap;
		row-gap: 8rem;
		align-content: space-around;
		height: fit-content;
		padding: 12rem 3.2rem;
	}

	.jsoftlab .services .sec-title {
		top: 12rem;
		left: inherit;
		right: 3.2rem;
		writing-mode: vertical-lr;
	}

	.jsoftlab .services .item {
		margin-right: 16rem;
	}

	.jsoftlab .services .item .m-title {
		margin-bottom: 1.6rem;
		font-size: 2.4rem;
	}

	.jsoftlab .services .item strong {
		line-height: 2.4rem;
		font-size: 1.3rem;
	}
	
	.jsoftlab .services .item li {
		line-height: 2rem;
		font-size: 1.3rem;
		font-weight: 300;
	}

	.jsoftlab .services .item.option {
		display: inline-block;
		width: calc(100% / 3 - 2.2rem);
		margin-left: 1.6rem;
	}

	.jsoftlab .services .item span {
		line-height: 2rem;
		font-size: 1.3rem;
		font-weight: 300;
	}
}

@media all and (min-width:96em) {
	.jsoftlab .services {
		min-height: fit-content;
	}

	.jsoftlab .services .container {
		z-index: 6;
		min-height: auto;
		padding: 14.4rem 0;
	}

	.jsoftlab .services .sec-title {
		top: 16rem;
		right: 0;
	}

	.jsoftlab .services .contents-wrap {
		display: inline-flex;
		flex-wrap: wrap;
		margin-right: 32rem;
	}

	.jsoftlab .services .item {
		width: 28.8rem;
		height: 19.2rem;
		margin: 1.6rem;
		padding: 1.6rem;
		border-radius: .8rem;
	}

	.jsoftlab .services .item:nth-of-type(4),
	.jsoftlab .services .item:nth-of-type(5) {
		padding-bottom: 1.6rem;
		margin-bottom: 1.6rem;
	}

	.jsoftlab .services .item:nth-of-type(6) {
		padding-top: 1.6rem;
	}

	.jsoftlab .services .item.inms, 
	.jsoftlab .services .item.wnms, 
	.jsoftlab .services .item.snms {
		background-color: transparent;
	}

	.jsoftlab .services .item.inms:hover, 
	.jsoftlab .services .item.wnms:hover, 
	.jsoftlab .services .item.snms:hover {
		background-color: rgba(16, 16, 16, .7)
	}

	.jsoftlab .services .item.inms:hover ~ .item.common, 
	.jsoftlab .services .item.wnms:hover ~ .item.common, 
	.jsoftlab .services .item.snms:hover ~ .item.common {
		background-color: rgba(16, 16, 16, .7);
	}

	.jsoftlab .services .item.inms:hover ~ .item.option, 
	.jsoftlab .services .item.wnms:hover ~ .item.option, 
	.jsoftlab .services .item.snms:hover ~ .item.option {
		border: .05rem dashed #616161;
	}

	.jsoftlab .services .item.option {
		width: 28.8rem;
		border: .05rem solid transparent;
		border-radius: .8rem;
		margin: 1.6rem;
	}

	.jsoftlab .services .item .m-title {
		line-height: 3.2rem;
		margin-bottom: 3.2rem;
		font-size: 3.2rem;
	}

	.jsoftlab .services .item strong {
		font-size: 1.4rem;
	}
	
	.jsoftlab .services .item li {
		line-height: 2.4rem;
	}

	.jsoftlab .services .item .title {
		line-height: 2.4rem;
		font-size: 2.4rem;
	}

	.jsoftlab .services .item span {
		line-height: 2.4rem;
		font-size: 1.4rem;
	}
}


/* Demo */
.jsoftlab .demo {
	position: relative;
	width: 100%;
	min-height: 100vh;
}

.jsoftlab .demo.bg-build-wrap {
	background: linear-gradient(180deg, #C5E8D1 0%, #E9F6EC 100%);
}

.jsoftlab .demo.bg-map-wrap {
	background: linear-gradient(180deg, #002A79 0%, #176EC4 100%);
}

.jsoftlab .demo.bg-multi-wrap {
	background: linear-gradient(180deg, #E8C631 0%, #E8C71D 100%);
}

.jsoftlab .demo.bg-network-wrap {
	background: linear-gradient(180deg, #AD94CD 0%, #E0D4EF 100%);
}

.jsoftlab .demo .card {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 25vh;
	border-bottom: .05rem solid #313131;
}

.jsoftlab .demo .card .default-contents {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	width: 100%;
	height: 25vh;
	background-color: #18191A;
	transition: all .1s;
}

.jsoftlab .demo .card.active .default-contents {
	top: -25vh;
}

.jsoftlab .demo .card .default-contents .title {
	position: relative;
	font-family: 'Noto Sans KR';
	font-size: 3.2rem;
	font-weight: 100;
	color: #FFF;
	text-transform: uppercase;
}

.jsoftlab .demo .card.build-wrap .default-contents .title {
	color: #56B78F;
}

.jsoftlab .demo .card.map-wrap .default-contents .title {
	color: #176EC4;
}

.jsoftlab .demo .card.multi-wrap .default-contents .title {
	color: #FFAC00;
}

.jsoftlab .demo .card.network-wrap .default-contents .title {
	color: #6757A7;
}

.jsoftlab .demo .card .default-contents .title span {
	display: none;
}

.jsoftlab .demo .card .active-contents {
	overflow: hidden;
	z-index: 1;
}

.jsoftlab .demo .card .active-contents .title {
	position: absolute;
	top: 3.2rem;
	right: 3.2rem;
	font-family: 'Poppins', sans-serif;
	font-size: 4rem;
	font-weight: 800;
	color: #FFF;
	text-transform: uppercase;
}

.jsoftlab .demo .card.build-wrap .active-contents .title,
.jsoftlab .demo .card.build-wrap .active-contents .btnDemo span {
	color: #2C4635;
}

.jsoftlab .demo .card.multi-wrap .active-contents .title,
.jsoftlab .demo .card.multi-wrap .active-contents .btnDemo span {
	color: #18191A
}

.jsoftlab .demo .card .active-contents .btnDemo {
	position: absolute;
	top: 8rem;
	right: 3.2rem;
	text-align: right;
}

.jsoftlab .demo .card .active-contents .btnDemo span {
	display: block;
	font-size: 1.6rem;
	font-weight: 400;
	color: #FFF;
}

.jsoftlab .demo .card .active-contents .btnDemo a {
	display: inline-block;
	width: 4rem;
	height: 2.4rem;
	line-height: 2.4rem;
	margin-top: .8rem;
	border-radius: 1.2rem;
	background-color: #000000;
	font-size: 1.1rem;
	font-weight: 400;
	color: #e6e6e6;
	text-align: center;
}

.jsoftlab .demo .card img {
	position: absolute;
	top: -3.2rem;
	left: -1.6rem;
	width: 24rem;
	transform: rotate(-24deg);
}

@media all and (min-width:48em) {
	.jsoftlab .demo {
		min-height: fit-content;
	}

	.jsoftlab .demo .container {
		display: flex;
		flex-wrap: wrap;
	}

	.jsoftlab .demo .card {
		display: inline-block;
		width: 50%;
		height: 24rem;
		border: .025rem solid #313131;
	}

	.jsoftlab .demo .card .default-contents {
		height: 24rem;
	}

	.jsoftlab .demo .card.active .default-contents {
		top: -24rem;
	}

	.jsoftlab .demo .card img {
		width: 28rem;
	}

	.jsoftlab .demo .left-bg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: calc((100% - 76.8rem) / 2);
		background-color: #18191A;
	}
}

@media all and (min-width:96em) {
	.jsoftlab .demo .card {
		width: 25%;
		height: 96rem;
		border: 0;
		border-left: .1rem solid transparent;
		border-image: linear-gradient(to bottom, #18191A 0%, #232424 25%, #232424 75%, #18191A 100% );
    border-image-slice: 1;
	}

	.jsoftlab .demo .card .default-contents {
		justify-content: flex-start;
		height: 96rem;
	}

	.jsoftlab .demo .card.active .default-contents {
		top: 0;
		left: -100%;
	}

	.jsoftlab .demo .card .default-contents::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: 24rem;
		height: 64rem;
		border: .1rem solid #232424;
		border-left: 0;
		border-radius: 0 1.6rem 1.6rem 0;
		transform: translateY(-50%);
	}

	.jsoftlab .demo .card .default-contents::after {
		content: '';
		position: absolute;
		top: 4.8rem;
		line-height: 8rem;
		font-family: 'Poppins';
		font-size: 8rem;
		font-weight: bold;
		color: #212121;
	}

	.jsoftlab .demo .card.build-wrap .default-contents::after {
		content: 'D';
		left: -1.8rem;
	}

	.jsoftlab .demo .card.map-wrap .default-contents::after {
		content: 'E';
		left: -1.6rem;
	}

	.jsoftlab .demo .card.multi-wrap .default-contents::after {
		content: 'M';
		left: -1.8rem;
	}

	.jsoftlab .demo .card.network-wrap .default-contents::after {
		content: 'O';
		left: -1.8rem;
	}

	.jsoftlab .demo .card .default-contents .title {
		transform: translateX(-.8rem);
	}

	.jsoftlab .demo .card .default-contents:hover .title {
		transform: translateX(2.4rem);
	}

	.jsoftlab .demo .card .default-contents .title span {
		display: block;
		color: #818181;
		transform: translateX(16rem);
		opacity: 0;
		transition: all .4s;
	}

	.jsoftlab .demo .card .default-contents:hover .title span {
		transform: translateX(4rem);
		opacity: 1;
	}

	.jsoftlab .demo .card .active-contents .btnDemo {
		top: 16.8rem;
		right: inherit;
		left: 1.6rem;
	}

	.jsoftlab .demo .card .active-contents .btnDemo span {
		display: inline-block;
		font-weight: 300;
		color: #e1e1e1;
	}

	.jsoftlab .demo .card .active-contents .btnDemo a {
		margin-top: 0;
		margin-left: .8rem;
	}

	.jsoftlab .demo .left-bg {
		width: calc((100% - 128rem) / 2);
	}

	.jsoftlab .demo .card .active-contents .title {
		top: 8rem;
		left: -2rem;
		right: inherit;
		line-height: 8rem;
		font-size: 8rem;
	}

	.jsoftlab .demo .card.build-wrap .active-contents .title,
	.jsoftlab .demo .card.network-wrap .active-contents .title {
		font-size: 6.4rem;
	}

	.jsoftlab .demo .card img {
		top: inherit;
		left: -4rem;
		bottom: 2.4rem;
		width: auto;
		height: 72rem;
		transform: rotate(0deg);
	}
}


/* Use case */
.jsoftlab .usecase {
	position: relative;
	width: 100%;
	background-color: #DEDEDE;
}

.jsoftlab .usecase .container {
	padding-top: 12vh;
}

.jsoftlab .usecase .sec-title {
	position: absolute;
	top: 0;
	left: -1.6rem;
	font-family: 'Poppins';
	font-weight: 600;
	font-size: 8rem;
	color: rgba(0, 0, 0, .05);
	text-transform: uppercase;
	letter-spacing: -.5rem;
}

.jsoftlab .usecase .case-wrap {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 6;
}

.jsoftlab .usecase .case-item {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	position: relative;
	width: 50%;
	height: 9.6rem;
	border: 0rem solid #DCDBDB;
	border-bottom: .05rem solid rgba(0, 0, 0, .15);
	border-right: .05rem solid rgba(0, 0, 0, .15);
	transition: all .1s;
	cursor: pointer;
}

.jsoftlab .usecase .case-item:first-of-type,
.jsoftlab .usecase .case-item:nth-of-type(2) {
	border-top: .05rem solid rgba(0, 0, 0, .15);
}

.jsoftlab .usecase .case-item:nth-of-type(2n) {
	border-right: 0;
}

.jsoftlab .usecase .case-item .title {
	display: block;
	font-size: 1.6rem;
	font-weight: 300;
	color: #616161;
	transition: all .2s;
}

.jsoftlab .usecase .case-item .btnLink {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 6;
	width: 2.8rem;
	height: 2.8rem;
	line-height: 2.8rem;
	border-radius: 2rem;
	font-size: 1rem;
	font-weight: 800;
	color: #FFF;
	text-align: center;
	transform: translateX(-50%);
	opacity: 0;
	transition: all .2s;
}

.jsoftlab .usecase .case-item::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 18rem;
	height: 9.6rem;
	background-image: url(/src/pages/company/jsoftlab/images/case_active_bg.svg);
	background-size: 100%;
	background-position: 1.6rem -1.2rem;
	opacity: 0;
	transition: all .4s ease-in-out;
}

.jsoftlab .usecase .case-item .cate {
	display: inline-block;
	position: absolute;
	top: -1.6rem;
	right: 0;
	font-family: 'Poppins', sans-serif;
	font-size: 1.8rem;
	font-weight: 800;
	color: rgba(0, 0, 0, .7);
	opacity: 0;
	transition: all .4s ease-in-out;
}

.jsoftlab .usecase .case-item:hover,
.jsoftlab .usecase .case-item.active {
	border: .1rem solid #DCDBDB;
	background-color: #E6E6E6;
	box-shadow: .8rem .8rem 2.4rem rgba(0, 0, 0, .3);
}

.jsoftlab .usecase .case-item:hover .title,
.jsoftlab .usecase .case-item.active .title {
	font-weight: 600;
	color: #212121;
	transform: translateY(-100%);
}

.jsoftlab .usecase .case-item:hover .btnLink,
.jsoftlab .usecase .case-item.active .btnLink {
	opacity: 1;
}

.jsoftlab .usecase .case-item.wnms .btnLink {
	background-color: #176EC4;
}

.jsoftlab .usecase .case-item.inms .btnLink {
	background-color: #56B78F;
}

.jsoftlab .usecase .case-item.snms .btnLink {
	background-color: #6757A7;
}

.jsoftlab .usecase .case-item:hover::after,
.jsoftlab .usecase .case-item.active::after {
	background-position: -1.6rem 1.2rem;
	opacity: 1;
}

.jsoftlab .usecase .case-item:hover .cate,
.jsoftlab .usecase .case-item.active .cate {
	right: .4rem;
	opacity: 1;
}

@media all and (min-width:48em) {
	.jsoftlab .usecase .container {
		padding: 12rem 0;
	}

	.jsoftlab .usecase .sec-title {
		top: 2rem;
		left: 3.2rem;
	}

	.jsoftlab .usecase .case-item {
		width: 25%;
		border: .05rem solid rgba(0, 0, 0, .15);
	}
}

@media all and (min-width:96em) {
	.jsoftlab .usecase .container {
		padding: 9.6rem 0;
	}

	.jsoftlab .usecase .sec-title {
		display: none;
	}

	.jsoftlab .usecase .case-item {
		height: 19.2rem;
		border: 0;
	}

	.jsoftlab .usecase .case-item:first-of-type,
	.jsoftlab .usecase .case-item:nth-of-type(2) {
		border-top: 0;
	}

	.jsoftlab .usecase .case-item .title {
		line-height: 2.4rem;
		font-size: 2.4rem;
		color: #818181;
	}

	.jsoftlab .usecase .case-item .cate {
		top: -2rem;
		line-height: 2.4rem;
		font-size: 2.4rem;
	}

	.jsoftlab .usecase .case-item::after {
		top: -.8rem;
		left: 3.2rem;
		width: 32rem;
		height: 19.2rem;
	}

	.jsoftlab .usecase .case-item .btnLink {
		width: 4rem;
		height: 4rem;
		line-height: 4rem;
		font-size: 1.2rem;
	}
}

/* Usecase Modal */
.caseModal-wrap {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;
}

.caseModal-wrap .modalBg {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(33, 33, 33, .7);
}

.caseModal {
	position: relative;
	z-index: 9;
	width: 32rem;
	height: 32rem;
	padding: 0;
	background-color: #212121;
	box-shadow: .4rem .4rem .8rem rgba(0, 0, 0, .15);
}

.caseModal .modalHeader {
	display: flex;
	justify-content: space-between;
	position: absolute;
	top: 0;
	z-index: 3;
	width: 100%;
	line-height: 4rem;
	padding: 0 2rem 2.4rem;
	background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.0) 100%
  );
}

.caseModal .modalHeader .caseTitle {
	font-size: 1.4rem;
	font-weight: 500;
	color: #FFF;
}

.caseModal .modalHeader a {
	display: inline-block;
	height: fit-content;
	transform: translateY(.4rem);
}

.caseModal .modalHeader a svg {
	display: inline-block;
	width: 1.6rem;
	height: 1.6rem;
	transform: translateY(.4rem);
	fill: #FFF;
}

.caseModal .image-box {
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32rem;
	height: 32rem;
}

.caseModal .image-box img {
	width: 100%; 
	height: auto;
	transform: translateY(-2.4rem);
}

.caseModal .caseDesc {
	position: absolute;
	bottom: 0;
	z-index: 3;
	width: 100%;
	padding: 4.8rem 2.4rem 2.4rem;
	background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.caseModal .caseDesc li:first-child {
	line-height: 2.4rem;
	font-size: 1.4rem;
	font-weight: 600;
	text-transform: uppercase;
	color: #FFF;
}

.caseModal .caseDesc li:last-child {
	margin-top: .4rem;
	line-height: 1.8rem;
	font-size: 1.2rem;
	font-weight: 400;
	color: #FFF;
}

@media all and (min-width:48em) {
	.caseModal {
		width: 48rem;
		height: 36rem;
	}

	.caseModal .image-box {
		width: 48rem;
		height: 32rem;
	}

	.caseModal .image-box img {
		width: 100%; 
		height: auto;
		transform: translateY(0);
	}
}

@media all and (min-width:96em) {
	.caseModal {
		width: 64rem;
		height: 48rem;
	}

	.caseModal .image-box {
		width: 64rem;
		height: 40rem;
	}

	.caseModal .image-box img {
		width: 100%; 
		height: auto;
		transform: translateY(2.4rem);
	}
}


/* Contact us */
.jsoftlab .contactus {
	position: relative;
	width: 100%;
	background-color: #E6E6E6;
}

.jsoftlab .contactus .container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	overflow: hidden;
	padding: 8vh 0 0;
}

.jsoftlab .contactus .mail {
	margin: .4rem .8rem;
	text-align: center;
}

.jsoftlab .contactus .mail > span {
	margin-right: .8rem;
	font-family: 'Poppins', sans-serif;
	font-size: 2rem;
	color: #101010;
	text-transform: uppercase;
}

.jsoftlab .contactus .mail span strong {
	font-size: 2rem;
	font-weight: 700;
}

.jsoftlab .contactus .mail .btnMail {
	display: inline-block;
}

.jsoftlab .contactus .mail .btnMail svg {
	height: 1.6rem;
	transform: translateY(.2rem);
}

.jsoftlab .contactus .mail .btnCopy {
	cursor: pointer;
}

.jsoftlab .contactus .mail .btnCopy svg {
	height: 1.6rem;
	transform: translateY(.2rem);
}

.jsoftlab .contactus img {
	width: auto;
	height: 28rem;
}

@media all and (min-width:48em) {
	.jsoftlab .contactus .container {
		overflow: inherit;
		padding: 8rem 0 32rem;
	}

	.jsoftlab .contactus .mail {
		margin: .8rem;
	}

	.jsoftlab .contactus .mail > span {
		margin-right: 1.6rem;
		font-size: 3.2rem;
	}
	
	.jsoftlab .contactus .mail span strong {
		font-size: 3.2rem;
	}

	.jsoftlab .contactus .mail .btnMail {
		margin-right: .8rem;
	}

	.jsoftlab .contactus .mail .btnMail svg {
		height: 2.4rem;
		transform: translateY(.2rem);
	}
	
	.jsoftlab .contactus .mail .btnCopy svg {
		height: 2.4rem;
		transform: translateY(.2rem);
	}
	
	.jsoftlab .contactus img {
		position: absolute;
		bottom: -4rem;
		height: 40rem;
		transform: translateY(4.8rem);
	}
}

@media all and (min-width:96em) {
	.jsoftlab .contactus .container {
		z-index: 6;
		padding: 16rem 0 62.4rem;
	}

	.jsoftlab .contactus .mail {
		margin: 1.2rem;
	}

	.jsoftlab .contactus .mail span strong,
	.jsoftlab .contactus .mail > span {
		line-height: 6.4rem;
		font-size: 6.4rem;
	}

	.jsoftlab .contactus .mail .btnMail {
		margin-left: .8rem;
	}

	.jsoftlab .contactus .mail .btnMail svg,
	.jsoftlab .contactus .mail .btnCopy svg {
		width: 4.8rem;
		height: 4.8rem;
	}

	.jsoftlab .contactus img {
		height: 72rem;
		transform: translateY(6.4rem);
	}
}