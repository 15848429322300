/* ==========================================================================
   JHOMEPAGE STYLE
   ========================================================================== */

body {
	line-height: 1;
	font-family: 'Noto Sans KR', 'Roboto', sans-serif;
	font-size: 1.4rem;
	font-weight: normal;
	color: #212121;
}

:root {
	--bk-main-color: #3E80CD;
	--bk-sub-color: #0AA3CA;
	--wh-main-color: #2766B0;
	--wh-sub-color: #008BAE;
	--wh-main-title: #292929;
	--wh-main-text: #4D4D4D;
	--wh-sub-text: #838383;
	--bk-main-title: #EBEBEB;
	--bk-main-text: #B5B5B5;
	--bk-sub-text: #4D4D4D;
	--kr: 'Noto Sans KR';
	--en: 'Poppins';
	--vh : 7.68px;
	--gradient : linear-gradient(305deg,#008BAE 0% #2766B0 100%);
}