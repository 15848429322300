/* 헤더 */
.header {
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

.header-wrap {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  padding: 2.4rem 1.6rem;
  backdrop-filter: blur(0.2rem);
}

.header.default.hide{
  top:-10rem;
}

.header-wrap.header-bg{
  background: rgba(255, 255, 255, 0.8); 
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);
}

.Network .header-wrap.header-bg,
.Ipt .header-wrap.header-bg,
.Wlan .header-wrap.header-bg,
.Security .header-wrap.header-bg,
.SoftWare .header-wrap.header-bg {
  box-shadow: none;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}


.header-wrap.open.header-bg{
  background: #061322;
}

.header.default .header-wrap.open.header-bg h1 img,
.header.active .header-bg h1 img {
  filter: brightness(1);
}

.header-bg h1 img {
  filter: brightness(0);
}

.header-wrap.open.header-bg .header-list a,
.header.active .header-bg .header-list a {
  color: #eeeff1;
}

.header-bg .header-list a {
  color: #222;
}

.header-bg .nav-icon span {
  background: var(--wh-main-color);
}

.header-wrap.open.header-bg .nav-icon span {
  background: #fff;
}

.header.active .header-wrap {
  height: fit-content;
  background: #061322;
}

.header a {
  font-style: none;
  color: #eeeff1;
}

h1 {
  position: relative;
  z-index: 6;
}

.gnb {
  display: none;
}

.gnb li:hover > .gnb-wrap {
  z-index: 4;
}

/* 사이트맵 버튼 */
.snb {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 7.2rem;
}

.nav-icon{
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 3.2rem;
  height: 2.4rem;
}
.nav-icon span{
  display: block;
  background: #eeeff1;
  position: absolute;
  border-radius: 1rem;
  transition: .3s cubic-bezier(.8, .5, .2, 1.4);
  width: 100%;
  height: 0.2rem;
  transition-duration: 500ms
}
#header.active .nav-icon span{
  background: #eeeff1;
}
.nav-icon span:nth-child(1){
  top:0px;
  left: 0px;
}
.nav-icon span:nth-child(2){
  top:11px;
  left: 0px;
  opacity:1;
}
.nav-icon span:nth-child(3){
  bottom:0px;
  left: 0px;
}

.nav-icon.active span:nth-child(1){
  transform: rotate(45deg);
  top: 13px;
}
.nav-icon.active span:nth-child(2){
  opacity:0;
}
.nav-icon.active span:nth-child(3){
  transform: rotate(-45deg);
  top: 13px;
}

/* 사이트맵 */
.sitemap {
  display: none;
}

.sitemap-list{
  height: auto;
  transition: 0.5s ease;
}
.sitemap-list.close{
  height: 0;
  overflow: hidden;
  transition: 0.5s ease;
}

.navbar-title .arrow {
  transform: rotate(0);
  transition: 0.5s ease;
}

.navbar-title .arrow.active {
  transform: rotate(180deg);
  transition: 0.5s ease;
}

.sitemap.show {
  display: block;
  width: 100%;
  height: 100vh;
  padding: 8.8rem 2.4rem 2.4rem 2.4rem;
  background: #061322;
}

.sitemap-wrap {
  width: 100%;
  max-width: 124rem;
  margin: 0 auto;
}

.sitemap-gnb > li {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.2);
}

.sitemap .navbar-title {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 2rem 0;
  cursor: pointer;
}

.sitemap .navbar-title .arrow.rotated {
  transform: rotate(180deg);
  transition: 0.5s ease;
}

.sitemap .navbar-title span{
  display: inline-block;
  font-size: 3.2rem;
  font-weight: 500;
  color: #eeeff1;
}

.sitemap .navbar-title span:last-child {
  display: flex;
  align-content: center;
}

.sitemap-list {
  overflow: visible;
  height: auto;
  padding: 2rem 0;
}

.sitemap-list li {
  display: block;
}

.sitemap-list.close {
  overflow: hidden;
  height: 0;
  padding: 0;
}

.sitemap-list.close li {
  display: none;
}

.sitemap-list a {
  font-size: 1.6rem;
  color: #eeeff1;
  opacity: 0.5;
}

.sitemap-list a:hover, .sitemap-list a:active {
  opacity: 1;
}

.sitemap-group1 li:not(:nth-child(4)),
.sitemap-group2 li,.sitemap-group3 li,
.sitemap-group4 li:not(:nth-child(1)~li) {
  margin: 2rem 0;
}


.sitemap-group1 li:last-child {
  display: none;
}

.sitemap-group1 .sitemap-list {
  background: url(../../public/images/main/gnb_bg1.png)no-repeat left 0 center;
}
.sitemap-group2 .sitemap-list {
  background: url(../../public/images/main/gnb_bg2.png)no-repeat left 0 center;
}
.sitemap-group3 .sitemap-list {
  background: url(../../public/images/main/gnb_bg3.png)no-repeat left 0 center;
}
.sitemap-group4 .sitemap-list {
  background: url(../../public/images/main/gnb_bg4.png)no-repeat left 0 center;
}

@media all and (min-width:48em) {
  h1 img {
    height: 3.2rem;
  }

  .snb {
    height: 8rem;
  }

  .sitemap .navbar-title {
    padding: 4rem 0;
  }

  .sitemap.show {
    padding-top: 16rem;
  }

  .sitemap-list a {
    font-size: 1.8rem;
  }

  .sitemap-group1 .sitemap-list {
    background: url(../../public/images/main/gnb_bg1.png)no-repeat right -40rem center/cover;
  }
  .sitemap-group2 .sitemap-list {
    background: url(../../public/images/main/gnb_bg2.png)no-repeat right -40rem center/cover;
  }
  .sitemap-group3 .sitemap-list {
    background: url(../../public/images/main/gnb_bg3.png)no-repeat right -40rem center/cover;
  }
  .sitemap-group4 .sitemap-list {
    background: url(../../public/images/main/gnb_bg4.png)no-repeat right -10rem center/cover;
  }
}

@media all and (min-width:64em) {
  .sitemap.show {
    display: flex;
    align-items: center;
  }
}

@media all and (min-width:80em) {
  .header-wrap {
    padding: 2.4rem 4rem;
  }

  .gnb {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .gnb ul {
    display: flex;
  }

  .header-list a {
    display: block;
    width: 18rem;
    text-align: center;
    line-height: 8rem;
    font-size: 1.8rem;
    font-weight: 500;
    color: #eeeff1;
  }

  .gnb li:first-child .gnb-wrap div{
    background: url(../../public/images/main/gnb_bg1.png)no-repeat right 0 center/auto 100%;
  }
  .gnb li:nth-child(2) .gnb-wrap div{
    background: url(../../public/images/main/gnb_bg2.png)no-repeat right 0 center/auto 100%;
  }
  .gnb li:nth-child(3) .gnb-wrap div{
    background: url(../../public/images/main/gnb_bg3.png)no-repeat right 0 center/auto 100%;
  }
  .gnb li:nth-child(4) .gnb-wrap div{
    background: url(../../public/images/main/gnb_bg4.png)no-repeat right 0 center/auto 100%;
  }

  .gnb-wrap {
    position: absolute;
    left: 0;
    width: 100%;
    height: 28rem;
    background-color: #061322;
  }

  .gnb-wrap div {
    width: 76.8%;
    height: 100%;
    margin: 0 auto;
    padding-top: 16rem;
  }

  .gnb-title {
    margin-bottom: 2rem;
    font-size: 4rem;
    font-weight: 700; 
    color: #eeeff1;
  }

  .gnb-wrap li {
    margin-right: 2rem;
    padding-right: 2rem;
  }

  .gnb-wrap li:not(:last-child) {
    border-right: 0.1rem solid rgba(255, 255, 255, 0.2);
  }

  .gnb-wrap a {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .gnb-wrap li a {
    color: rgba(255, 255, 255, 0.5);
  }

  .gnb-wrap li a:hover {
    color: #eeeff1;
  }

  .header.default .header-wrap {
    height: 8rem;
    overflow: hidden;
  }
  
  .header.active .header-wrap {
    height: 38rem;
    overflow: visible;
  }
  
  .header.default .gnb-wrap {
    display: none;
  }
  
  .header.active .gnb-wrap {
    display: block;
    height: 28rem;
    overflow: visible;
  }

  .snb {
    right: 4rem;
  }
}

@media all and (min-width:96em) {

  .header-list a {
    font-size: 1.6rem;
  }

  .gnb-wrap > div {
    width: 100%;
    max-width: 76.8%;
  }

  .sitemap .navbar-title {
    padding: 4rem 0;
  }

  .gnb-wrap a {
    font-size: 1.8rem;
  }
}

@media all and (min-width:120em) {
  h1 {
    height: fit-content;
    margin: 1rem 0;
  }

  .header.default .header-wrap {
    height: 10rem;
    align-items: center;
  }

  .header-wrap {
    padding: 2.4rem calc((100% - 76.8%) / 2);
  }

  .header-list a {
    font-size: 2rem;
    line-height: 10rem;
  }

  .header-wrap .snb {
    height: 10rem;
    padding-right: calc((100% - 76.8%) / 2);
  }

  .snb {
    right: 0;
  }

  .nav-icon {
    scale: 1.2;
  }

  .sitemap .navbar-title {
    padding: 6rem 0;
  }

  .gnb-wrap a {
    font-size: 2rem;
  }
}

@media all and (min-width:120em) {
  
}